// @helpers
const apiURLBuilder = require('core/apiURLBuilder');
const { APIGet } = require('utilities/APIRequestHelper');

// @constants
const actionTypes = require('constants/actionTypes');
const { WEB_FEATURE_FLAGGING } = require('constants/index');

const requestFlagBasedFeaturesSuccess = (flags) => {
    return {
        type: actionTypes.FLAG_BASED_FEATURES_SUCCESS,
        payload: {
            flags: flags || {}
        }
    }
}
const requestFlagBasedFeaturesError = (error) => {
    return {
        type: actionTypes.FLAG_BASED_FEATURES_ERROR,
        payload: {
            error
        }
    }
}
const requestFlagBasedFeaturesFetch = () => {
    return {
        type: actionTypes.FLAG_BASED_FEATURES_REQUEST,
    }
}
const requestFlagBasedFeatures = () => (dispatch, getState) => {
    const immFlagBasedFeatures = getState().flagBasedFeatures;
    const hasBeenFetched = immFlagBasedFeatures.get('hasBeenFetched');
    const isFetching = immFlagBasedFeatures.get('isFetching');

    // If you need to call this service again, add usage of flag isOutdated
    // like if ((hasBeenFetched || isFetching) && !isOutdated){
    // but dont remove the if statement below
    if ((hasBeenFetched || isFetching)){
        return Promise.resolve();
    }

    dispatch(requestFlagBasedFeaturesFetch());
    return APIGet(dispatch, apiURLBuilder.getURL('flagBasedFeatures', {flag: WEB_FEATURE_FLAGGING}))
        .then((response) => {
            dispatch(requestFlagBasedFeaturesSuccess(response))
        })
        .catch((error) => {
            dispatch(requestFlagBasedFeaturesError(error))
        });
}

const setOriginCountry = (originCountry) => {
    return {
        type: actionTypes.FLAG_BASED_FEATURES_SET_ORIGIN_COUNTRY,
        originCountry
    }
}

module.exports = {
    requestFlagBasedFeatures,
    requestFlagBasedFeaturesSuccess,
    setOriginCountry
};
