// @ constants
const {
    PRINTER_OPEN_PREVIEW,
    DOCUMENTS_PRINTED_AND_SIGNED_RADIO_CHANGED,
    PRINTED_INDICATORS_RESET,
    SHOW_PRINT_REQUIRED,
    PRINTER_CLEAN_ERROR_MESSAGE,
    SHOW_PRINTED_AND_SIGNED_STATUS_REQUIRED,
    PRINTED_AND_SIGNED_SUCCESS,
} = require('constants/contactCenter/actionTypes');

const openPrinterPreview = () => ({
    type: PRINTER_OPEN_PREVIEW
})

const printedAndSignedRadioChanged = (value) => ({
    type: DOCUMENTS_PRINTED_AND_SIGNED_RADIO_CHANGED,
    payload: {
        value
    }
})

const showPrintRequired = () => ({
    type: SHOW_PRINT_REQUIRED
})

const showPrintedAndSignedStatusRequired = () => ({
    type: SHOW_PRINTED_AND_SIGNED_STATUS_REQUIRED
})

const cleanErrorMessage = () => ({
    type: PRINTER_CLEAN_ERROR_MESSAGE
})

const printedAndSignedSuccess = () => ({
    type: PRINTED_AND_SIGNED_SUCCESS
})

const resetPrintedIndicators = () => ({
    type: PRINTED_INDICATORS_RESET,
})

module.exports = {
    openPrinterPreview,
    printedAndSignedRadioChanged,
    resetPrintedIndicators,
    showPrintRequired,
    showPrintedAndSignedStatusRequired,
    cleanErrorMessage,
    printedAndSignedSuccess,
}
