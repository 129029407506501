const actionTypes = require('constants/actionTypes');
const apiURLBuilder = require('core/apiURLBuilder');
const { APIGet } = require('utilities/APIRequestHelper');
const { getLanguage } = require('core/i18n').i18n;

const requestManagerInfoInProgress = () => ({type: actionTypes.MORTGAGE_REQUEST_MANAGER_INFO})

function requestManagerInfoFailure(error) {
    return {
        type: actionTypes.MORTGAGE_REQUEST_MANAGER_INFO_FAILURE,
        data: {
            error
        }
    };
}

function requestManagerInfoSuccess(data) {
    return {
        type: actionTypes.MORTGAGE_REQUEST_MANAGER_INFO_SUCCESS,
        data: data
    };
}

const getManagerInformation = () => (dispatch) => {
    dispatch(requestManagerInfoInProgress());
    
    const url = apiURLBuilder.getURL('mortgageRequestManagerInfo');

    const requestData = {
        query: {
            language: getLanguage()
        }
    };

    return APIGet(dispatch, url, requestData)
        .then(response => dispatch(requestManagerInfoSuccess(response)))
        .catch(response => {
            if (!!response.error) {
                dispatch(requestManagerInfoFailure(response.error))
            } else {
                throw response;
            }
        })
};

module.exports = {
    getManagerInformation
};
