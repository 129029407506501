const get = require('lodash/object/get');
const { cookies, localStorage } = require('utilities/storage');
const { getPersonTypeAndCodeHashed } = require('utilities/hash');

const productsHiringURLs = [
    'myprofile/purchase/checking-account',
    'myprofile/purchase/payroll-account',
    'myprofile/purchase/e-account-loan',
    'myprofile/purchase/junior-account',
    'myprofile/purchase/open-debit',
    'myprofile/purchase/visa-open',
    'myprofile/purchase/premium-pack',
    'myprofile/purchase/diamond-pac',
    'myprofile/purchase/e-card',
    'myprofile/purchase/mini-card',
    'myprofile/purchase/via-t',
    'myprofile/purchase/deposit-thirteen-months',
    'myprofile/purchase/deposit-eighteen-months',
    'myprofile/purchase/deposit-eighteen-months-retention',
    'myprofile/purchase/combined-deposit',
    'myprofile/purchase/deposit-twentyfour-months-retention',
    'myprofile/purchase/deposit',
    'myprofile/purchase/depositnm',
    'myprofile/purchase/deposittransfer',
    'myprofile/purchase/depositrenewal',
    'myprofile/broker',
    'myprofile/loans-mortgages/request-payroll-loan',
    'myprofile/loans-mortgages/pre-granted-credit'
]

const updateNoClientCookie = (clientHasAccounts = true) => {
    if (clientHasAccounts) {
        cookies.remove('rnc');
        localStorage.remove('rncT');
    } else {
        cookies.set('rnc', "true");
        const token = cookies.get('tokenCredential');
        if (!!token) {
            const hashedF = getPersonTypeAndCodeHashed(cookies.get('tokenCredential'));
            localStorage.set('rncT', hashedF);
        }
    }
}

const isClientWithoutAccountsBeforePG = () => {
    const token = cookies.get('tokenCredential');
    if (!token) {
        return false;
    }

    const hashedF = getPersonTypeAndCodeHashed(cookies.get('tokenCredential'));
    return cookies.get('rnc') && hashedF === localStorage.get('rncT');
}

const redirectToRNCPages = (nextUrl, justReturnURL = false, history) => {
    let url;
    if (nextUrl.includes('/myprofile/hire/rnc')) {
        return '';
    } else if (productsHiringURLs.some(url => nextUrl.includes(url))) {
        // If a client without accounts try to hire a product, we redirect him to the hiring fallback page
        url = '/myprofile/hire/rnc';
    } else if (!nextUrl.includes('/myprofile/mortgage')) {
        // If a client without accounts try to access another section, we redirect him to the mortgages landing page
        url = '/myprofile/mortgage';
    }

    if (url) {
        if (justReturnURL) {
            return url
        } else {
            history.push(url);
        }
    }


}

const clientsWithoutAccountsPGCheck = (response, history) => {
    const accounts = get(response, 'datosSalidaCuentas.cuentas', []);
    const cards = get(response, 'datosSalidaTarjetas.tarjetas', []);
    const loans = get(response, 'datosSalidaPrestamos.prestamos', []);
    const clientHasAccounts = Array.isArray(accounts) && accounts.length > 0;
    const clientHasCards = Array.isArray(cards) && cards.length > 0;
    const  clientHasLoans = Array.isArray(loans) && loans.length > 0;
    const isPartialClient = clientHasAccounts || clientHasCards || clientHasLoans;

    updateNoClientCookie(isPartialClient);
    if (!isPartialClient) {
        redirectToRNCPages(location.href, false, history);
    }

    return response;
}

module.exports = {
    clientsWithoutAccountsPGCheck,
    isClientWithoutAccountsBeforePG,
    redirectToRNCPages
};
