// @utilities
const { APIPost } = require('utilities/APIRequestHelper');
const apiURLBuilder = require('core/apiURLBuilder');

// @ constants
const actionTypes = require('constants/actionTypes');
const { BLOCKED_ACCOUNTS } = require('constants/flagBasedFeatures');

const resetBlockedAccounts = () => ({
    type: actionTypes.BLOCKED_ACCOUNTS_RESET,
});

const blockedAccountsRequest = () => ({
    type: actionTypes.BLOCKED_ACCOUNTS_REQUEST,
});

const blockedAccountsSuccess = data => ({
    type: actionTypes.BLOCKED_ACCOUNTS_SUCCESS,
    payload: {
        data
    }
});

const blockedAccountsFailure = error => ({
    type: actionTypes.BLOCKED_ACCOUNTS_FAILURE,
    payload: {
        error
    }
});

const getBlockedAccounts = (accounts, operationId, isSharedAccounts = false) => (dispatch, getState) => {
    const isInactiveAccountsEnabled = getState().flagBasedFeatures.get(BLOCKED_ACCOUNTS);

    if (!isInactiveAccountsEnabled) {
        return;
    }

    const contractNumber = isSharedAccounts ? 'contractNumber' : 'newContractNumber';
    const product = isSharedAccounts ? 'product' : 'newProduct';

    const cuentas = accounts.map(account => ({
        numeroDeContrato: account.get(contractNumber),
        producto: account.get(product)
    })).toArray();

    const requestData = {
        query: {
            cuentas
        }
    }

    dispatch(blockedAccountsRequest());

    return APIPost(dispatch, apiURLBuilder.getURL('blockedAccounts', { operationId }), requestData)
        .then(response => {
            dispatch(blockedAccountsSuccess(response));
        })
        .catch(error => {
            dispatch(blockedAccountsFailure(error));
        });
}


module.exports = {
    getBlockedAccounts,
    resetBlockedAccounts
};

