const {
    AUTHORIZATIONS_FILTER,
    EXPAND_ROW,
    SET_AUTHORIZATIONS
} = require('constants/actionTypes');

const expandRow = (accountId, rowId) => ({
    type: EXPAND_ROW,
    accountId,
    rowId
})

const authorizationsFilter = (accountId, immFilters) => ({
    type: AUTHORIZATIONS_FILTER,
    accountId,
    immFilters
})

const setAuthorizations = (accountId, authorizations) => ({
    type: SET_AUTHORIZATIONS,
    accountId,
    authorizations
})

module.exports = {
    authorizationsFilter,
    expandRow,
    setAuthorizations
}