// @ vendors
const { Promise } = require('es6-promise');
const { formatText } = require('core/i18n').i18n;
// @ commons
const apiURLBuilder = require('core/apiURLBuilder');
const { APIGet, APIPost } = require('utilities/APIRequestHelper');
const signaturePatternActions = require('actions/signaturePattern');
const { updateConsents } = require('actions/gdprConsents');
// @ constants
const actionTypes = require('constants/actionTypes');
const { CIRBE_SIGNATURE_FAILED, PARAMS_VALUES } = require('constants/mortgageRequestHolderConfirmation');
//@actions
const { requestState } = require('actions/mortgageRequest/mortgageRequestState');
const { requestConditions } = require('actions/mortgageRequest/mortgageRequestConditions');

const requestHolderConfirmDocumentsInProgress = () => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_REQUEST
});

const requestHolderConfirmDocumentsSuccess = (data) => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_SUCCESS,
    data
});

const requestHolderConfirmDocumentsFailure = (error) => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_FAILURE,
    data: {
        error
    }
});

const editHolderConfirmInProgress = () => ({
    type: actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_CONFIRM_REQUEST
});

const editHolderConfirmSuccess = () => ({
    type: actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_CONFIRM_SUCCESS
});

const editHolderConfirmFailure = (error) => ({
    type: actionTypes.MORTGAGE_REQUEST_EDIT_HOLDER_CONFIRM_FAILURE,
    data: {
        error
    }
});

const cirbeSignatureInProgress = () => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_CIRBE_REQUEST
});

const cirbeSignatureSuccess = () => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_CIRBE_SUCCESS
});

const cirbeSignatureFailure = (error) => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_CIRBE_FAILURE,
    data: {
        error
    }
});

const holderCheckPersonalInfoRequest = () => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CHECK_CONFIRM_DATA_REQUEST
});

const holderCheckPersonalInfoSuccess = data => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CHECK_CONFIRM_DATA_SUCCESS,
    data
});

const holderCheckPersonalInfoFailure = error => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CHECK_CONFIRM_DATA_FAILURE,
    data: {
        error
    }
});

const resetDocumentsState = () => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_RESET
});

const holderConfirmationInit = () => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_INIT
});

const holderConfirmationInitSuccess = (response) => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_INIT_SUCCESS,
    payload: response
});

const holderConfirmationInitFailure = (error) => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_INIT_FAILURE,
    payload: error
});
const holderConfirmationDocumentsCirbe = () => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_CIRBE
});

const holderConfirmationDocumentsCirbeSuccess = (response) => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_CIRBE_SUCCESS,
    payload: response
});

const holderConfirmationDocumentsCirbeFailure = (error) => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_DOCUMENTS_CIRBE_FAILURE,
    payload: error
});

const holderConfimationScrollDown = () => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_SCROLL_DOWN,
});

const holderConfirmationOfficeButtonDisabled = () => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_BUTTON_OFFICE_ENABLE,
});


const requestDocuments = () => (dispatch, getState) => {
    const currentState = getState().mortgageRequestHolder;
    const isAlreadyFetching = currentState.get('isFetching');

    if (!isAlreadyFetching) {

        dispatch(requestHolderConfirmDocumentsInProgress());
        return APIGet({
            dispatch,
            endpoint: 'mortgageRequestHolderConfirmDocuments'
        })
            .then(response => dispatch(requestHolderConfirmDocumentsSuccess(response)))
            .catch(response => {
                if (!!response.error) {
                    dispatch(requestHolderConfirmDocumentsFailure(response.error));
                } else {
                    throw response;
                }
            });
    } else {
        return Promise.resolve();
    }
};

const cleanDocumentsState = () => dispatch => dispatch(resetDocumentsState());

const confimationScrollDown = () => dispatch => dispatch(holderConfimationScrollDown());

const gdprAction = (dispatch, requestData, history) => {

    dispatch(cirbeSignatureInProgress());
    return APIPost({
        dispatch,
        endpoint: 'mortgageRequestLegalAccept',
        params: requestData
    })
        .then(response => {
            if (response.data.returnFl) {
                dispatch(cirbeSignatureSuccess());
                dispatch(requestState('/myprofile/mortgage/panel', history));
            } else {
                dispatch(cirbeSignatureFailure(CIRBE_SIGNATURE_FAILED))
            }
        })
        .catch(response => {
            if (!!response.error) {
                dispatch(cirbeSignatureFailure(response.error));
            } else {
                throw response;
            }
        });
};

const cirbeSignature = (acceptCaser, dataToken, isClient, history) => (dispatch, getState) => {
    const currentState = getState().mortgageRequestHolder;
    const conditions = getState().mortgageRequestConditions.get('conditions');
    const isAlreadyFetching = currentState.get('isFetching');
    if (!isAlreadyFetching) {
        const operationProcesses = getState().mortgageRequestHolderConfirmEdit.get('operationProcesses');
        const personalData = getState().mortgageRequestDataProprietor.get('personalData');
        const currentUser = getState().okLogin.get('username');
        let operationProcessId;
        if (operationProcesses) {
            operationProcessId = operationProcesses.map(process => ({
                operationProcessId: process.get('operationProcessId'),
                documentNumber: process.get('documentNumber'),
                documentType: process.get('documentType'),
                signature: process.get('documentNumber') === currentUser ? "S" : "N",
                checkCirbe: "N",
            }));
        } else if (personalData) {
            operationProcessId = personalData.map(person => ({
                operationProcessId: person.operationProcessId,
                documentNumber: person.documentoDeIdentidad.identificador,
                documentType: person.documentoDeIdentidad.tipoDocumentoIdentidad,
                signature: person.documentoDeIdentidad.identificador === currentUser ? "S" : "N",
                checkCirbe: person.checkCirbe === 1 ? "S" : "N",
            }));
        }
        let acceptanceText;
        if (isClient) {
            if (personalData && personalData.length > 1) {
                acceptanceText = formatText(`mortgageRequestHolderConfirmation-mortgageRequestHolderConfirmation_confirmationCheckText2`);
            } else {
                acceptanceText = formatText(`mortgageRequestHolderConfirmation-mortgageRequestHolderConfirmation_confirmationCheckText`);
            }
        } else {
            acceptanceText = formatText(`mortgageRequestHolderConfirmation-mortgageRequestHolderConfirmation_confirmationCheckTextNoClient`);
        }
        const requestData = {
            query: {
                idExpedient: conditions.get('idExpediente'),
                confirmaData: +acceptCaser,
                clientType: isClient ? PARAMS_VALUES.CLIENT : PARAMS_VALUES.RNC,
                acceptanceText: acceptanceText, 
                operationProcesses: operationProcessId,
                scrollDown: getState().mortgageRequestHolderConfirmEdit.get('scrollDown')
            }
        };
        dispatch(updateConsents(dataToken))
            .then(() => gdprAction(dispatch, requestData, history))
            .catch(() => gdprAction(dispatch, requestData, history));
    }
};

const editHolderConfirm = (acceptCaser, noSignature, history) => (dispatch, getState) => {
    const currentState = getState().mortgageRequestHolder;
    const isAlreadyFetching = currentState.get('isFetching');

    if (!isAlreadyFetching) {
        const callback = (dispatch, getState, dataToken) => {
            dispatch(editHolderConfirmInProgress());
            return APIPost({
                dispatch,
                endpoint: 'mortgageRequestHolderConfirmation'
            })
                .then(() => {
                    dispatch(editHolderConfirmSuccess());
                })
                .catch(response => {
                    if (!!response.error) {
                        dispatch(editHolderConfirmFailure(response.error));
                    } else {
                        throw response;
                    }
                })
                .finally(() =>
                    dispatch(cirbeSignature(acceptCaser, dataToken, !noSignature, history))
                );
        }
        if (noSignature) {
            callback(dispatch);
        } else {
            new Promise(() => {
                signaturePatternActions.validateAndExecute(dispatch, getState, callback)
            });
        }
    } else {
        return Promise.resolve();
    }
};

const setField = (inputField, data) => ({
    type: actionTypes.MORTGAGE_REQUEST_HOLDER_CONFIRM_EDIT_SET_FIELD,
    payload: {
        inputField,
        data
    }
});

const holderCheckPersonalInfo = () => (dispatch, getState) => {
    const currentState = getState().mortgageRequestHolderConfirmEdit;
    const isAlreadyFetching = currentState.get('isFetchingCheckPersonalInfo');
    if (!isAlreadyFetching) {
        dispatch(holderCheckPersonalInfoRequest());
        return APIGet({
            dispatch,
            endpoint: 'mortgageRequestHolderCheckPersonalInfo'
        })
            .then(response => {
                dispatch(holderCheckPersonalInfoSuccess(response));
            })
            .catch(response => {
                if (!!response.error) {
                    dispatch(holderCheckPersonalInfoFailure(response.error));
                } else {
                    throw response;
                }
            });
    } else {
        return Promise.resolve();
    }
}

const requestMortgageHolderConfirmationInit = () => (dispatch, getState) => {
    const conditions = getState().mortgageRequestConditions.get('conditions');
    const isAlreadyFetching = conditions.get('isFetching');
    const currentUser = getState().okLogin.get('username');
    if (!isAlreadyFetching) {
        dispatch(holderConfirmationInit());
        let params = {
            query: {
                processType: "contract",
                idExpedient: conditions.get('idExpediente')
            }
        };
        return APIPost({
            dispatch,
            endpoint: 'mortgageRequestHolderConfimationInit',
            params: params
        })
        .then(response =>{ 
            dispatch(holderConfirmationInitSuccess(response));
                    if (response.operationProcesses) {
                        const currentUserProcess = response.operationProcesses.find(process => process.documentNumber === currentUser);
                        if (currentUserProcess) {
                            dispatch(requestMortgageRequestHolderConfirmDocumentsCirbe(currentUserProcess.operationProcessId));
                        }
                    }
                    return response.operationProcesses;
        })
        .catch(response => {
            if (!!response.error) {
                dispatch(holderConfirmationInitFailure(response.error));
            } else {
                throw response;
            }
        });
    } else {
        return Promise.resolve();
    }
};
const requestMortgageRequestHolderConfirmDocumentsCirbe = (operationProcessId) => (dispatch, getState) => {
    const conditions = getState().mortgageRequestConditions.get('conditions');
    const isAlreadyFetching = conditions.get('isFetching');
    if (!isAlreadyFetching) { 
        return APIGet(
            dispatch,
            apiURLBuilder.getURL('mortgageRequestHolderConfirmDocumentsCirbe', { operationProcessId })
        )
        .then(response => dispatch(holderConfirmationDocumentsCirbeSuccess(response)))
        .catch(response => {
            if (!!response.error) {
                dispatch(holderConfirmationDocumentsCirbeFailure(response.error));
            } else {
                throw response;
            }
        });
    } else {
        return Promise.resolve();
    }
};

const enableButtonOffice = () => (dispatch) => {
    dispatch(holderConfirmationOfficeButtonDisabled());
};

module.exports = {
    requestDocuments,
    editHolderConfirm,
    setField,
    cleanDocumentsState,
    holderCheckPersonalInfo,
    requestMortgageHolderConfirmationInit,
    requestMortgageRequestHolderConfirmDocumentsCirbe,
    confimationScrollDown,
    enableButtonOffice
};
