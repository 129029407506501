const actionTypes = require('constants/actionTypes');

const bannerHide = () => ({
    type: actionTypes.BROKER_BANNER_UPDATE_HIDE,
});

const bannerShow = () => ({
    type: actionTypes.BROKER_BANNER_UPDATE_SHOW,
});

const bannerUpdateData = (id, typeId, data) => ({
    type: actionTypes.BROKER_BANNER_UPDATE_DATA,
    payload: {
        data,
        id, 
        typeId
    }
});

const bannerClose = () => ({
    type: actionTypes.BROKER_BANNER_UPDATE_CLOSE_OR_AGREED,
});

module.exports = {
    bannerClose,
    bannerUpdateData,
    bannerHide,
    bannerShow
}
