// @ vendors
const get = require('lodash/object/get');
// @ commons
const { APIGet } = require('utilities/APIRequestHelper');
// @ constants
const actionTypes = require('constants/actionTypes');

const statusRequest = () => ({
    type: actionTypes.MORTGAGES_STATUS_REQUEST
});

const statusSuccess = (response) => ({
    type: actionTypes.MORTGAGES_STATUS_SUCCESS,
    payload: {
        status: get(response, 'data.estado')
    }
});

const statusFailure = (error) => ({
    type: actionTypes.MORTGAGES_STATUS_FAILURE,
    payload: {
        error
    }
});

const requestStatus = () => dispatch => {
    dispatch(statusRequest());
    return APIGet({
            dispatch,
            endpoint: 'mortgageRequestStateRequest'
        })
        .then( response => dispatch(statusSuccess(response)))
        .catch( (response) => {
            if (!!response.error) {
                dispatch(statusFailure(response.error.description))
            } else {
                throw response;
            }
        });
}

module.exports = {
    requestStatus
}
