// @ verndors
const merge = require('lodash/object/merge');
const lodash = require('lodash');
const moment = require('moment');
// @ utilities
const { APIGet, APIPost } = require('utilities/APIRequestHelper');
const { getLanguage } = require('core/i18n').i18n;
const storage = require('utilities/storage');
const { formatText } = require('core/i18n').i18n;
// @ constants
const actionTypes = require('constants/actionTypes');
const {
    CHAT_CONVERSATION_STATUS: {
        ACTIVE,
        URL_VALIDATOR_REGEX,
        HISTORY_TRANSCRIPT_POSITION,
        ERROR_MIN_RANGE,
        ERROR_MAX_RANGE,
        CONTENT_TYPE
    }
} = require('constants/chat');
const {
    CHAT_SENDER_TYPE : {
        AGENT,
        MESSAGE_TYPE,
        TYPE
    }
} = require('constants/chat');
const { API_WEB_CHANNEL } = require('constants/index');
// @ commons
const apiURLBuilder = require('core/apiURLBuilder');

const chatAvailabilityStatusRequest = () => ({
    type: actionTypes.CHAT_AVAILABILITY_STATUS_REQUEST
});

const chatAvailabilityStatusSuccess = (response) => ({
    type: actionTypes.CHAT_AVAILABILITY_STATUS_SUCCESS,
    payload: {
        response
    }
});

const chatAvailabilityStatusfailure = (error) => ({
    type: actionTypes.CHAT_AVAILABILITY_STATUS_FAILURE,
    payload: {
        error
    }
});

const addWelcomeMessage = (message) => ({
    type: actionTypes.CHAT_ADD_WELCOME_MESSAGE,
    payload: {
        message
    }
});

const startConversationSuccess = response => ({
    type: actionTypes.CHAT_START_CONVERSATION_SUCCESS,
    payload: {
        response
    }
});

const startConversationFailure = error => ({
    type: actionTypes.CHAT_START_CONVERSATION_FAILURE,
    payload: {
        error
    }
});

const startConversationFetching = (localId, content) => ({
    type: actionTypes.CHAT_START_CONVERSATION_FETCHING,
    payload: {
        localId,
        content
    }
});

const sendMessageSending = (messageId, content) => ({
    type: actionTypes.CHAT_MESSAGE_SENDING,
    payload: {
        content,
        messageId
    }
});

const sendMessageSent = messageId => ({
    type: actionTypes.CHAT_MESSAGE_SENT,
    payload: {
        messageId
    }
});

const sendMessageError = (messageId, error) => ({
    type: actionTypes.CHAT_MESSAGE_ERROR,
    payload: {
        error,
        messageId
    }
});

const getHeaderData = (chatData) => {
    const { alias, userId, secureKey } = chatData;
    return {
        query: {
            OpenBankChatSecureKey: secureKey,
            OpenBankChatUserId: userId,
            OpenBankChatAlias: alias
        }
    };
};

const endedConversationRemove = (localId) => ({
    type: actionTypes.CHAT_ENDED_CONVERSATION,
    payload: {
        localId
    }
});

const updateChatTokens = (chatId , chatData) => ({
    type: actionTypes.UPDATE_CHAT_TOKENS,
    payload: {
        chatId,
        chatData
    }
});

// This is to disconnect a conversation in the background without the user noticing
const endCancelledConversation = (response, localId) => (dispatch) => {
    const { alias, chatId, userId, secureKey } = response;
    // Unfortunately we can't use the previously defined function getHeaderData because of differences in letter caps
    const data = {
        query: {
            openBankChatAlias: alias,
            openBankChatSecureKey: secureKey,
            openBankChatUserId: userId
        }
    };

    return APIPost(function(){}, apiURLBuilder.getURL('chatEndConversation', {chatId}), data)
        .then(() => dispatch(endedConversationRemove(localId)));
};

const chatAvailabilityStatus = () => (dispatch) => {
    dispatch(chatAvailabilityStatusRequest());
    return APIGet({
        dispatch,
        endpoint: 'chatStatus'
    })
    .then(response => {
        dispatch(chatAvailabilityStatusSuccess(response));
    })
    .catch(response => {
        if (response.error) {
            dispatch(chatAvailabilityStatusfailure(response.error.description));
        } else {
            dispatch(chatAvailabilityStatusfailure(''));
            throw response;
        }
    });
}

const sendMessage = content => (dispatch, getState) => {
    const { alias, chatId, userId, secureKey } = getState().okChat.toJS();
    const cId = chatId;
    const duration = moment.duration(300000, 'milliseconds');
    const chatCookiesExpireTime = duration.asDays();
    const data = {
        query: {
            chatId,
            chatInformation: {
                openBankChatAlias: alias,
                openBankChatSecureKey: secureKey,
                openBankChatUserId: userId
            },
            message: content
        }
    };

    const communicationData = {
        query: {
            OpenBankChatAlias: alias,
            OpenBankChatSecureKey: btoa(secureKey),
            OpenBankChatUserId: btoa(userId),
            transcriptPosition : HISTORY_TRANSCRIPT_POSITION
        }
    };
    storage.localStorage.set('chatData' , communicationData, { expires: chatCookiesExpireTime });
    const uuid = Date.now();

    dispatch(sendMessageSending(uuid, content));
    return APIPost(dispatch, apiURLBuilder.getURL('chatMessages', { cId }), data)
        .then(() => dispatch(sendMessageSent(uuid)))
        .catch( response => {
            if (response.errorDescription) {
                dispatch(sendMessageError(uuid, response.errorDescription));
            } else {
                dispatch(sendMessageError(uuid, ''));
                throw response;
            }
        })
};

const startConversation = (content) => (dispatch, getState) => {
    let userFirstName = getState().profile.get('nombreCliente') || '';
    userFirstName = userFirstName && userFirstName.trim();
    const message = {
        from: {type: AGENT},
        index: 0,
        messageType: MESSAGE_TYPE,
        text: formatText('chat-welcomeMessage', [userFirstName]),
        type: TYPE
    };
    const localId = Date.now();
    const currentPath = window.location.href.replace(URL_VALIDATOR_REGEX, '');
    let tags = "";
    const pageTitle = document.getElementsByClassName('ok-header-landing__title');
    const language = getLanguage() || 'es';
    if(pageTitle.length !== 0) {
        tags = pageTitle[0].innerText;
    } else {
        const breadcrumb = document.getElementsByClassName('ok-header-sub-page__breadcrumb-section')[0].childNodes[0].childNodes;
        let str = "";
        lodash.each(breadcrumb,function(item){
            str = `${str}/${item.innerText}`;
        });
        tags = str.substring(1);
    }
    const duration = moment.duration(300000, 'milliseconds');
    const chatCookiesExpireTime = duration.asDays();
    dispatch(startConversationFetching(localId));
    const data = {
        query: {
            channel: API_WEB_CHANNEL,
            language: language,
            subject: '',
            topic: '',
            tags: tags,
            url: currentPath
        },
        header: {
            version: 2
        }
    }
    return APIPost(dispatch, apiURLBuilder.getURL('chatStartConversation'), data)
        .then(response => {
            // If the conversation was closed by the user during connection, we end the conversation here after we have its id
            if (getState().okChat.get('conversationsToEnd').includes(localId)) {
                dispatch(endCancelledConversation(response, localId));
            } else {
                dispatch(startConversationSuccess(response));
                dispatch(addWelcomeMessage(message));
                storage.localStorage.set('chatId',  btoa(response.chatId), { expires: chatCookiesExpireTime });
                if(typeof(content) == CONTENT_TYPE || content instanceof String)  {
                    dispatch(sendMessage(content, response.chatId));
                }
            }
        })
        .catch(response => {
            if (response.errorDescription) {
                dispatch(startConversationFailure(response.errorDescription));
            } else {
                dispatch(startConversationFailure());
                throw response;
            }
        });
};

const toggleWindow = () => ({
    type: actionTypes.CHAT_WINDOW_TOGGLE
});

const closeWindowAsk = () => ({
    type: actionTypes.CHAT_CLOSE_ASK
});

const closeWindowCancel = () => ({
    type: actionTypes.CHAT_CLOSE_CANCEL
});

const closeWindowConfirm = () => ({
    type: actionTypes.CHAT_CLOSE_CONFIRM
});

const getRequestData = (getState, queryParams) => {
    let data = getHeaderData(getState().okChat.toJS());

    queryParams && merge(data, queryParams);

    return data;
}

const setTimer = () => ({
    type: actionTypes.CHAT_SET_TIMER
});

const resetTimer = () => ({
    type: actionTypes.CHAT_RESET_TIMER
});

const disconnectNotice = () => ({
    type: actionTypes.CHAT_DISCONNECTED_NOTICE
});
// We store the local conversation id to disconnect it inmediately after connection
const endWhenConnected = (localId) => ({
   type: actionTypes.CHAT_END_WHEN_CONNECTED,
   payload: {
       localId
   }
});

const endConversation = () => (dispatch, getState) => {
    const { alias, userId, secureKey, chatId, localId } = getState().okChat.toJS();
    // Unfortunately we can't use the previously defined function getHeaderData because of differences in letter caps
    const data = {
        query: {
            openBankChatAlias: alias,
            openBankChatSecureKey: secureKey,
            openBankChatUserId: userId
        }
    };

    dispatch(closeWindowConfirm());
    storage.localStorage.remove('chatData');
    storage.localStorage.remove('chatId');
    if (!chatId) {//for when we're trying to disconnect a conversation that hasn't been created in the server yet
        dispatch(endWhenConnected(localId));
    } else {
        return APIPost(dispatch, apiURLBuilder.getURL('chatEndConversation', {chatId}), data)
            .catch( response => {
                if (response.errorDescription) {
                    dispatch(startConversationFailure(response.errorDescription));
                } else {
                    dispatch(startConversationFailure(''));
                    throw response;
                }
            });
    }
};

const setUserNameInWelcomeMessage = (name) => ({
    type: actionTypes.SET_NAME_IN_WELCOME_MESSAGE,
    payload: {
        name
    }
});

const getNextMessageIndex = (getState) => {
    const messages = getState().okChat.get('messages');
    const lastMessage = messages.findLast(msg => msg.get('index'));
    return lastMessage && lastMessage.get('index') + 1 || 1;
};

const retrieveMessagesIsFetching = () => ({
    type: actionTypes.CHAT_RETRIEVE_MESSAGES_IS_FETCHING
});

const retrieveMessagesSuccess = response => ({
    type: actionTypes.CHAT_RETRIEVE_MESSAGES_SUCCESS,
    payload: {
        messages: response.messages,
        chatEnded: response.chatEnded
    }
});

const retrieveMessagesFailure = (error, gotDisconnected) => ({
    type: actionTypes.CHAT_RETRIEVE_MESSAGES_FAILURE,
    payload: {
        error,
        gotDisconnected
    }
});

const retrieveMessagesHistory = (content, chatData) => (dispatch, getState) => {
    dispatch(updateChatTokens(content, chatData));
    const {
        chatId,
        messagesFetching
    } = getState().okChat.toJS();
    let userFirstName = getState().profile.get('nombreCliente') || '';
    userFirstName = userFirstName && userFirstName.trim();
    const message = {
        from: {type: AGENT},
        index: 0,
        messageType: MESSAGE_TYPE,
        text: formatText('chat-welcomeMessage', [userFirstName]),
        type: TYPE
    };
    const cId = content || chatId;
    const communicationData = {
        query: {
            OpenBankChatAlias: chatData.query.OpenBankChatAlias,
            OpenBankChatSecureKey: atob(chatData.query.OpenBankChatSecureKey),
            OpenBankChatUserId: atob(chatData.query.OpenBankChatUserId),
            transcriptPosition : HISTORY_TRANSCRIPT_POSITION
        }
    };

    if (!messagesFetching) {
        dispatch(retrieveMessagesIsFetching());
        return APIGet(dispatch, apiURLBuilder.getURL('chatMessages', {cId}), communicationData)
            .then(response => {
                dispatch(addWelcomeMessage(message));
                dispatch(retrieveMessagesSuccess(response));
            })
            .catch(response => {
                if (response.error) {
                    const gotDisconnected = response.error.status && response.error.status >= ERROR_MIN_RANGE && response.error.status < ERROR_MAX_RANGE;
                    dispatch(retrieveMessagesFailure(response.error.description, gotDisconnected));
                } else {
                    dispatch(retrieveMessagesFailure(''));
                    throw response;
                }
            });
    }
};

const retrieveMessages = (content, chatData) => (dispatch, getState) => {
    const {
        chatId,
        conversationStatus,
        messagesFetching,
        userId
    } = getState().okChat.toJS();
    const cId = content || chatId;
    if (!messagesFetching && conversationStatus === ACTIVE && userId) {
        dispatch(retrieveMessagesIsFetching());

        const data = getRequestData(getState, {query: {transcriptPosition: getNextMessageIndex(getState)}});
        const cData = chatData || data;
        return APIGet(dispatch, apiURLBuilder.getURL('chatMessages', {cId}), cData)
            .then(response => {
                dispatch(retrieveMessagesSuccess(response));
            })
            .catch(response => {
                if (response.error) {
                    const gotDisconnected = response.error.status && response.error.status >= 400 && response.error.status < 500;
                    dispatch(retrieveMessagesFailure(response.error.description, gotDisconnected));
                } else {
                    dispatch(retrieveMessagesFailure(''));
                    throw response;
                }
            });
    }
};

module.exports = {
    addWelcomeMessage,
    chatAvailabilityStatus,
    closeWindowAsk,
    closeWindowCancel,
    closeWindowConfirm,
    disconnectNotice,
    endConversation,
    retrieveMessages,
    retrieveMessagesHistory,
    resetTimer,
    sendMessage,
    startConversation,
    setTimer,
    setUserNameInWelcomeMessage,
    toggleWindow
};
