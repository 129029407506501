// @constants
const actionTypes = require('constants/actionTypes');

const setInitBiocatch = () => ({
    type: actionTypes.BIOCATCH_SET_INITIALIZED,
});

const biocatchReset = () => ({
    type: actionTypes.BIOCATCH_RESET,
});

module.exports = {
    biocatchReset,
    setInitBiocatch,
};
