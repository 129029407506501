const apiURLBuilder = require('../core/apiURLBuilder');
const { APIGet } = require('utilities/APIRequestHelper');
// @Vendors
const get = require('lodash/object/get');
//@Actions
const actionTypes = require('constants/actionTypes');
//@Constants
const {
    BROKER_CUSTOM_WALLET_GENERAL_VIEW,
    BROKER_CUSTOM_CONFIG_WALLET_GENERAL_NAME
}= require('customWallets/constants/index');

const resetSelectedView = () => ({
    type: actionTypes.BROKER_GET_DEFAULT_VIEW_RESET,
});

const toggleModal = () => ({
    type: actionTypes.BROKER_MY_WALLET_TOGGLE_MODAL,
});

const toggleEvolutionModal = () => ({
    type: actionTypes.BROKER_MY_WALLET_TOGGLE_EVOLUTION_MODAL,
});

function getEvolutionDataSuccess(response) {
    return {
        type: actionTypes.BROKER_EVOLUTION_DATA_SUCCESS,
        payload: {
            data: response
        }
    }
}

function setGeneralViewData(response) {
    return {
        type: actionTypes.BROKER_SET_GENERAL_VIEW_TOTAL,
        payload: {
            generalConsolidatedAmount: get(response, 'valorCarteraTotal.importe'),
            generalTotalAmount: get(response, 'sumarizacionTotal.total.valor.importeEur.importe'),
        }
    }
}

function setSummarizationData(response) {
    return {
        type: actionTypes.BROKER_SET_SUMMARIZATION_DATA,
        payload: {
            summarization: get(response, 'sumarizacion'),
        }
    }
}

function getEvolutionDataFailure(response) {
    return {
        type: actionTypes.BROKER_EVOLUTION_DATA_FAILURE,
        payload: {
            data: response
        }
    }
}

function getEvolutionDataFetching() {
    return {
        type: actionTypes.BROKER_EVOLUTION_DATA_FETCHING
    }
}

const getEvolutionData = (selectedWalletId) => (dispatch) => {

    dispatch(getEvolutionDataFetching());

    let requestData
    let url = apiURLBuilder.getURL('brokerEvolution');

    if (selectedWalletId) {
        url = apiURLBuilder.getURL('brokerEvolutionByWallet');
        requestData = {
            query:{
               portfolioAccountId: selectedWalletId
            }
        };
    }
    return APIGet(dispatch, url, requestData)
        .then(function(response) {
                dispatch(getEvolutionDataSuccess(response));
            }, function(response) {
                dispatch(getEvolutionDataFailure(response));
            }
        );
};

const changeSelectedView = (selectedView) => ({
    type: actionTypes.BROKER_CHANGE_SELECTED_VIEW,
    payload: {
        data: selectedView
    }
});



const getValidDefaultView = (response, customWalletsList) => {
    const defaultViewID = Number(response.value);
    const defaultView = customWalletsList.find( item => item.get('idPortfolioAccount') === defaultViewID);
    const viewName = defaultView ? defaultView.get('descriptionPortfolioAccount') : BROKER_CUSTOM_CONFIG_WALLET_GENERAL_NAME;
    const viewId = defaultView ? defaultView.get('idPortfolioAccount') : BROKER_CUSTOM_WALLET_GENERAL_VIEW;

    return {
        viewName,
        viewId
    }
};

const getDefaultViewSuccessID = (defaultView) => ({
    type: actionTypes.BROKER_GET_DEFAULT_VIEW_ID_SUCCESS,//BROKER_SET_DEFAULT_VIEW,
    payload: {
        viewName: defaultView.viewName,
        viewId: defaultView.viewId
    }
});

function getDefaultViewFailureID(error) {
    return {
        type: actionTypes.BROKER_GET_DEFAULT_VIEW_ID_FAILURE,
        payload: {
            error: error,
        }
    }
}

function requestDefaultViewID() {
    return {
        type: actionTypes.BROKER_REQUEST_DEFAULT_VIEW_ID,
    }
}

const getDefaultViewID = () => (dispatch, getState) => {
    const viewName = "WEALTH_CUSTOM_PG_VIEW";
    const url = apiURLBuilder.getURL('brokerDefaultViewName', {name: viewName});

    dispatch(requestDefaultViewID());

    return APIGet(dispatch, url)
        .then( response => {
            const customWalletsList = getState().brokerCustomWalletPortfolios.get('customWallets');
            const defaultView = getValidDefaultView(response, customWalletsList);

            dispatch(getDefaultViewSuccessID(defaultView));
        })
        .catch((error) =>  {
            dispatch(getDefaultViewFailureID(error));
        });
};

const setActiveTab = (tabIndex) => ({
    type: actionTypes.BROKER_MYWALLET_CHANGE_TAB,
    payload: {
        tab : tabIndex
    }
});

module.exports = {
    getEvolutionData,
    changeSelectedView,
    setGeneralViewData,
    getDefaultViewID,
    //getDefaultViewName,
    getDefaultViewFailureID,
    resetSelectedView,
    toggleModal,
    setSummarizationData,
    setActiveTab,
    toggleEvolutionModal
};
