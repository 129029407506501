const get = require('lodash/object/get');
const nerudaUserConstants = require('constants/nerudaUser');

const redirectToNerudaUserPages = (nextUrl, justReturnURL = false, history) => {
    const cardsUrl = '/myprofile/cards';
    const nerudaLandingUrl = '/myprofile/hire/neruda-landing';
    const globalPositionUrl = '/myprofile/global';
    const isForbiddenURL = nerudaUserConstants.forbiddenURLs.some(urlParam => nextUrl.endsWith(urlParam));
    let isAllowedURL;
    let url;

    if (nextUrl !== cardsUrl && nextUrl.startsWith(cardsUrl)) {
        isAllowedURL = nerudaUserConstants.cardsURLs.some(urlParam => nextUrl.includes(urlParam));
    } else {
        isAllowedURL = nerudaUserConstants.allowedURLs.some(urlParam => nextUrl.includes(urlParam));
    }

    if (nextUrl === nerudaLandingUrl) {
        return '';
    } else if (nextUrl.endsWith(globalPositionUrl)) {
        url = cardsUrl;
    } else if (!isAllowedURL || isForbiddenURL) {
        url = nerudaLandingUrl;
    }

    if (url) {
        if (justReturnURL) {
            return url;
        } else {
            history.push(url);
        }
    }
}

const nerudaUserCheck = (response, history) => {
    const isNerudaUser = get(response, 'userProfile.clientProfile') === 2;

    if (isNerudaUser) {
        redirectToNerudaUserPages(location.pathname, false, history);
    }

    return response;
}

module.exports = {
    nerudaUserCheck,
    redirectToNerudaUserPages,
};
