// @vendors
const get = require('lodash/object/get');
// @helpers
const apiURLBuilder = require('core/apiURLBuilder');
const { APIGet } = require('utilities/APIRequestHelper');
const { getFormattedLanguage } = require('core/i18n').i18n;

// @constants
const actionTypes = require('constants/actionTypes');

// @ actions
const { populateContractDescriptions } = require('actions/brokerPositionsValuesContracts');
const { setGeneralViewData, setSummarizationData } = require('actions/myWallet');
const { bannerHide } = require('actions/bannerUpdate');

const fetchSuccess = (response) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.BROKER_GET_MY_INVESTMENTS_SUCCESS,
        payload: {
            data: get(response, 'productos'),
            accounts: getState().accounts.get('byId'),
            actives: get(response, 'tiposRenta'),
            contracts: get(response, 'contratos', {}),
            summarizedInvestedCap: get(response, 'sumarizacion.consolidado.capitalInvertido.importeEur.importe'),
            summarizedProfitability: get(response, 'sumarizacion.consolidado.rentabilidad.importeEur.importe'),
            summarizedValor: get(response, 'sumarizacion.consolidado.valor.importeEur.importe'),
            profitablePercentage: get(response, 'sumarizacion.consolidado.porcentajeRentabilidad.importeEur.importe'),
            distribucionPorRenta: get(response, 'distribucionPorRenta'),
            fundsList: [],
            plansList: [],
            futuresList: [],
            valuesList: [],
            cuentas: getState().brokerWallets.toJSON(),
            roboAdvisor: response.roboadvisor,
            patrimony: response.patrimony,
            roboAccount: response.cuentas,
            canHireRoboWithTransfer: response.canHireRoboWithTransfer,
            maxFundsPartialReimbursementPercentage: response.maxFundsPartialReimbursementPercentage,
        }
    });
};

const fetchFailure = (error) => {
    return {
        type: actionTypes.BROKER_GET_MY_INVESTMENTS_FAILURE,
        payload: { error }
    };
};

const fetchMyInvestments = () => (dispatch, getState) => {
    const immBrokerMyInvestments = getState().brokerMyInvestments;
    let fetchMyInvestmentsPromise;

    if ((!immBrokerMyInvestments.get('fetchSuccess') || immBrokerMyInvestments.get('isOutdated')) && !immBrokerMyInvestments.get('isFetching')) {
        const url = apiURLBuilder.getURL('myMoneyPgValores');
        let requestData = {
            header: {
                version: 3,
                'Accept-Language': getFormattedLanguage()
            },
            query: {
                prod: 'VALORES_FONDOS_PLANES_Y_FUTUROS',  //@ Agustin: We need this parameter for Robo Advisor.
                stockRights: true,
                transferableToRobo: true
            }
        };

        dispatch({
            type: actionTypes.BROKER_GET_MY_INVESTMENTS_REQUEST,
            payload: {}
        });
        dispatch(bannerHide());
        fetchMyInvestmentsPromise = APIGet(dispatch, url, requestData).then(
            response => {
                dispatch(fetchSuccess(response));
                dispatch(populateContractDescriptions());
                dispatch(setGeneralViewData(response));
                dispatch(setSummarizationData(response));
            },
            error => {
                dispatch(fetchFailure(error))
                dispatch({
                    type: actionTypes.CW_GET_MY_INVESTMENTS_SET_OUTDATED,
                });
            }
        );
    }

    return fetchMyInvestmentsPromise;
};

const outdateMyInvestments = () => (dispatch) => {
    return dispatch({
        type: actionTypes.BROKER_GET_MY_INVESTMENTS_SET_OUTDATED
    });
};

const toggleDerechosInfoMessage = value => ({
    type: actionTypes.BROKER_GET_DERECHOS_INFO_MESSAGE,
    payload: {
        value
    }
});

module.exports = {
    fetchSuccess,
    fetchMyInvestments,
    outdateMyInvestments,
    toggleDerechosInfoMessage
};
