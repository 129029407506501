// @ vendors
const { APIGet, APIPost } = require('utilities/APIRequestHelper');
// @actions
const signaturePatternActions = require('actions/signaturePattern');
const errorActions = require('actions/brokerError');
// @ commons
const apiURLBuilder = require('core/apiURLBuilder');
// @ constants
const actionTypes = require('constants/actionTypes');
const { EURO_TEXT, PLANS_ACTIVE } = require('constants/index');

const hasActivePeriodicalContributions = (periodicalContributionList, planId) => {
    const productId = planId.split('-')[0];
    const subproductId = planId.split('-')[1];

    return periodicalContributionList.find( item =>
        item.contrato.producto === productId &&
        item.producto === subproductId &&
        item.planAportacion.situacion === PLANS_ACTIVE
    ) ? true : false;
};

const getPeriodicalContributionDetailsSuccess = (producto, numeroContrato, timeStamp, data) => ({
    type: actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_SUCCESS,
    payload: {
        producto,
        numeroContrato,
        timeStamp,
        data
    }
});

const getPeriodicalContributionDetailsError = () => ({
    type: actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_ERROR,
});

const requestGetPeriodicalContributionDetails = () => ({
    type: actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_REQUEST,
});

const requestIsStoredPeriodicalContribution = (detailsStored) => ({
    type: actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_DETAILS_IS_STORED,
    payload: {
        detailsStored
    }
});

const contributonPeriodicalError = error => ({
    type: actionTypes.BROKER_PENSION_PLAN_PERIODICAL_CONTRIBUTION_ERROR,
    payload: {
        error
    }
});

const validationPeriodicalError = error => ({
    type: actionTypes.BROKER_PENSION_PLAN_PERIODICAL_VALIDATION_ERROR,
    payload: {
        error
    }
});

const resetPeriodicalContribution = () => ({
    type: actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_RESET,
});

function isStored(producto, numeroContrato, timeStamp, state) {
    const detalles = state.get('periodicalContributionList').find(item =>
        item.getIn(['contrato','producto']) === producto &&
        item.getIn(['contrato','numerodecontrato']) === numeroContrato &&
        item.getIn(['planAportacion','timestampAlta']) === timeStamp).get('detalles');
    return detalles;
}

const getPeriodicalContributionDetails = (producto, numeroContrato, timeStamp, tipoApor, revalAnual, ignoreStored) => (dispatch, getState) => {
    const immBrokerPeriodicalContributionDetails = getState().brokerPensionPlanPeriodicalContribution;
    const requestData = {
        query: {
            producto: producto,
            numeroContrato: numeroContrato,
            timeStamp: timeStamp,
            tipoApor: tipoApor,
            revalAnual: revalAnual
        }
    };

    const detailsStored = !ignoreStored && isStored(producto, numeroContrato, timeStamp, immBrokerPeriodicalContributionDetails);
    if(!detailsStored) {
        dispatch(requestGetPeriodicalContributionDetails());
        return APIGet(dispatch, apiURLBuilder.getURL('planPeriodicalContributionDetails'), requestData)
        .then(response => {
            dispatch(getPeriodicalContributionDetailsSuccess(producto, numeroContrato, timeStamp, response));
        })
        .catch(response => {
            dispatch(getPeriodicalContributionDetailsError(response.error))
        });
    }
    dispatch(requestIsStoredPeriodicalContribution(detailsStored));
};

const getPeriodicalContributionSuccess = (data, planId) => ({
    type: actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_SUCCESS,
    payload: {
        data,
        planId
    }
});

const getPeriodicalContributionError = () => ({
    type: actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_ERROR,
});

const requestGetPeriodicalContribution = () => ({
    type: actionTypes.BROKER_PLAN_PERIODICAL_CONTRIBUTION_REQUEST,
});

const getPeriodicalContribution = (planId) => (dispatch) => {
    dispatch(requestGetPeriodicalContribution());
    return APIGet(dispatch, apiURLBuilder.getURL('planPeriodicalContribution'))
    .then(response => {
        dispatch(getPeriodicalContributionSuccess(response, planId));
    })
    .catch(response => {
        dispatch(getPeriodicalContributionError(response.error))
    });
};

 const periodicContributionCancelSuccess = () => ({
    type: actionTypes.BROKER_PENSION_PLAN_CONTRIBUTION_CANCEL_SUCCESS,
});

const confirmCancelContribution = function(dispatch, getState, dataToken, plan, history) {
    const immBrokerPensionPlanPeriodicalContribution = getState().brokerPensionPlanPeriodicalContribution;
    const validationData = immBrokerPensionPlanPeriodicalContribution.get('validationData');
    const url = 'brokerPensionPlanCancelContributionConfirmation';
    const query = {
        codIdio: plan.get('idioma'),
        codMonProducto: (validationData.get('codmonProd') ? validationData.get('codmonProd'): EURO_TEXT),
        cuentaAsociada: {
            codbban: validationData.getIn(['cuentaAsociada','codbban']),
            digitodecontrol: validationData.getIn(['cuentaAsociada','digitodecontrol']),
            pais: validationData.getIn(['cuentaAsociada','pais']),
        },
        cuentaGrupo: validationData.get('cuentaGrupo'),
        dataToken: dataToken,
        datosConexion: {
            contratoSeleccionado: {
                numerodecontrato: plan.getIn(['contrato','numerodecontrato']),
                producto: plan.getIn(['contrato','producto'])
          }
        },
        estatusCodAport: plan.getIn(['planAportacion','status']),
        fechaAlta: plan.get('fechaAlta'),
        fechaSiguien: validationData.get('fecProxAportacion'),
        importeAportPlan: {
            divisa: plan.getIn(['planAportacion','divisa']),
            importe: plan.getIn(['planAportacion','importe'])
        },
        numCuentasPeriod: plan.getIn(['planAportacion','cuotasPeriodo']),
        revalAnual: plan.getIn(['planAportacion','revalorizacionAnual']),
        timestampAlta: plan.getIn(['planAportacion','timestampAlta']),
        tipoApor: plan.getIn(['planAportacion','tipo']),
        tipoPlan: validationData.get('tipoPlanBaja')
    };
    const requestData = {
        query: query
    };
    dispatch(requestGetPeriodicalContribution());
    return APIPost(dispatch, apiURLBuilder.getURL(url), requestData)
        .then(() => dispatch(periodicContributionCancelSuccess()))
        .catch((response) => {
            dispatch(errorActions.show('', true, history));
            dispatch(contributonPeriodicalError(response));
        });
};

const requestSignaturePatternValidationCancel = (plan, history) => (dispatch, getState) => {
    const callback = (dispatch, getState, dataToken) => {
        confirmCancelContribution(dispatch, getState, dataToken, plan, history);
    };
    signaturePatternActions.validateAndExecute(dispatch, getState, callback);
};

const periodicContributionCancelValidationSuccess = response => ({
    type: actionTypes.BROKER_PENSION_PLAN_CANCEL_VALIDATION_SUCCESS,
    payload: {
        response
    }
});

const validatePeriodicCancelContribution = (plan, history) => (dispatch) => {
    const requestData = {
        query: {
            datosConexion: {
                contratoSeleccionado: {
                    numerodecontrato: plan.getIn(['contrato','numerodecontrato']),
                    producto: plan.getIn(['contrato','producto'])
              }
            },
            fechaAlta: plan.get('fechaAlta'),
            importeAportPlan: {
                divisa: plan.getIn(['planAportacion','divisa']),
                importe: plan.getIn(['planAportacion','importe'])
            },
            revalAnual: plan.getIn(['planAportacion','revalorizacionAnual']),
            timestampAlta: plan.getIn(['planAportacion','timestampAlta']),
            tipoApor: plan.getIn(['planAportacion','tipo']),
        }
    };
    dispatch(requestGetPeriodicalContribution());
    return APIPost(
        dispatch,
        apiURLBuilder.getURL('brokerPensionPlanCancelContributionValidation'),
        requestData
    )
        .then(response => {
            dispatch(periodicContributionCancelValidationSuccess(response));
            dispatch(requestSignaturePatternValidationCancel(plan, history));
        })
        .catch(response => {
            dispatch(validationPeriodicalError(response));
            dispatch(errorActions.show('brokerAPIErrors-' + response.body.error, true, history));
        });
};

const setDocumentsRead = () => (dispatch) => dispatch({
    type: actionTypes.BROKER_PENSION_PLAN_EXTRAORDINARY_CONTRIBUTION_DOCUMENTS_READ
});

module.exports = {
    getPeriodicalContribution,
    getPeriodicalContributionDetails,
    resetPeriodicalContribution,
    validatePeriodicCancelContribution,
    hasActivePeriodicalContributions,
    setDocumentsRead
};
