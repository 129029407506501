// @ utilities
const apiURLBuilder = require('core/apiURLBuilder');
const { EMAILCONSTANTS } = require('constants/contactCenter/emailConstants');
const {
    APIPost,
    APIGet
} = require('utilities/APIRequestHelper');
const { formatText, hasTranslation } = require('core/i18n').i18n;
const IbanHelper = require('utilities/ibanHelper');
const moment = require('moment');

// @ constants
const actionTypes = require('constants/contactCenter/actionTypes');
const {
    CONNECTION_CHANNEL_CONTACT_CENTER,
    SEND_ORDER,
    SAVE_DOCUMENTATION_NO
} = require('constants/contactCenter/brokerFundsSendEmailConstants');
const {
    DATE_FORMAT_API,
    BROKER_HIRE_PENSION_PLAN_EMPTY_DATA,
    BROKER_HIRE_PENSION_PLAN_SUBCATEGORY
} = require('constants/index');


const resetPrecontractualMail = () => ({
    type: actionTypes.PRE_CONTRACTUAL_MAIL_RESET
});

const mailPreContractualToClientSuccess = () => ({
    type: actionTypes.PRE_CONTRACTUAL_MAIL_SENT_SUCCESS
});

const mailPreContractualToClientRequest = () => ({
    type: actionTypes.PRE_CONTRACTUAL_MAIL_SENT_REQUEST
});

const mailPreContractualToClientFailure = error => ({
    type: actionTypes.PRE_CONTRACTUAL_MAIL_SENT_FAILURE,
    payload: {
        error
    }
});

const setLegalTextRead = () => dispatch => {
    dispatch({
        type: actionTypes.PRE_CONTRACTUAL_SET_LEGAL_TEXT_READ,
    });
};

const getProductRequestData = (productName, templateCode, data, operationProcessId) => {
    const requestData = {
        header: {
            version: 2
        },
        query: {
            productName,
            templateCode,
            data,
            ...(operationProcessId && { operationProcessId }),
        }
    };

    return requestData;
}

const getTypeSubtypeDataRequestData = (subType, type, standar, productName, templateCode, operationProcessId, data = {}) => {
    const requestData = {
        query: {
            'estandarReferencia': standar,
            'producto': type,
            'subproducto': subType,
            productName,
            templateCode,
            data,
            ...(operationProcessId && { operationProcessId }),
        }
    };

    return requestData;
}

const sendPreContractualMail = (requestParameters, processOperationId = '') => dispatch => {
    const {
        subType,
        type,
        standar,
        productName,
        data,
        ref = '',
        product = null,
        name = null,
        emailInfo = {}
    } = requestParameters;
    let productNameParsed;
    if (name) {
        productNameParsed = formatText(name);
    } else {
        productNameParsed = hasTranslation(`emailConstants-${type}${subType}${ref}`) ?
            formatText(`emailConstants-${type}${subType}${ref}`) : (productName || emailInfo.productName || EMAILCONSTANTS[`${type}${subType}${ref}`].productName)
    }
    const templateCode = emailInfo.templateCode || EMAILCONSTANTS[`${type}${subType}${ref}`].templateCode
    const requestData = !!product
        ? getProductRequestData(productNameParsed, templateCode, data, processOperationId)
        : getTypeSubtypeDataRequestData(subType, type, standar, productNameParsed, templateCode, processOperationId, data)

    dispatch(mailPreContractualToClientRequest());
    return APIPost(dispatch, apiURLBuilder.getURL(!!product ? 'mailPreContractualInfoProduct' : 'mailPreContractualInfo', { product }), requestData)
        .then(() => dispatch(mailPreContractualToClientSuccess()))
        .catch(error => dispatch(mailPreContractualToClientFailure(error))
        );
};

const sendBulletinDocument = () => (dispatch, getState) => {
    const { brokerPensionPlans, brokerHirePensionPlan } = getState();
    const dgsCode = brokerPensionPlans.getIn(['pensionPlan', 'dgscode']);

    const planName = brokerPensionPlans.getIn(['pensionPlan', 'name']);

    const product = brokerHirePensionPlan.get('planProductNumber');
    const contractNumber = brokerHirePensionPlan.get('planContractNumber');
    const fullContractNumber = IbanHelper.calculateContract(`${product}${contractNumber}`);

    const requestData = {
        header: {
            version: 2,
        },
        query: {
            dgsCode: dgsCode,
            providerCompanyName: brokerPensionPlans.getIn(['pensionPlan', 'provider']),
            fechaOrden: moment().format(DATE_FORMAT_API),
            finalAccountNumber: BROKER_HIRE_PENSION_PLAN_EMPTY_DATA,
            isContratacionesFlow: true,
            nombrePlan: planName,
            saveRequest: {
                category: "contrato",
                subCategory: BROKER_HIRE_PENSION_PLAN_SUBCATEGORY,
                idContract: fullContractNumber
            },
            contratoPlan: {
                PRODUCTO: product,
                NUMERODECONTRATO: "0000000000"
            },
            importeInversion: {
                importe: "0",
                divisa: "0000000000"
            }
        }
    }
    return APIPost(
        dispatch,
        apiURLBuilder.getURL('brokerHirePensionPlanSendPreContractuals'),
        requestData
    ).then(() =>
        dispatch(mailPreContractualToClientSuccess())
    ).catch(() => {
        dispatch(mailPreContractualToClientSuccess())
    })
};


const sendPlansPreContractualMail = (requestParameters, processOperationId = '') => dispatch => {
    const { subType, type, standar, productName, data, ref = '', product = null } = requestParameters;
    const productNameParsed = hasTranslation(`emailConstants-${type}${subType}${ref}`) ?
        formatText(`emailConstants-${type}${subType}${ref}`) : (productName || EMAILCONSTANTS[`${type}${subType}${ref}`].productName)
    const templateCode = EMAILCONSTANTS[`${type}${subType}${ref}`].templateCode
    const requestData = !!product
        ? getProductRequestData(productNameParsed, templateCode, data, processOperationId)
        : getTypeSubtypeDataRequestData(subType, type, standar, productNameParsed, templateCode, processOperationId, data)
    dispatch(mailPreContractualToClientRequest());
    return APIPost(dispatch, apiURLBuilder.getURL(!!product ? 'mailPreContractualInfoProduct' : 'mailPreContractualInfo', { product }), requestData)
        .then(() => dispatch(sendBulletinDocument()))
        .catch(error => dispatch(mailPreContractualToClientFailure(error))
        );
};

const sendBrokerPreContractualMail = params => dispatch => {
    dispatch(mailPreContractualToClientRequest());

    const requestData = {
        query: Object.assign({}, {
            channel: CONNECTION_CHANNEL_CONTACT_CENTER,
            sendOrder: SEND_ORDER,
            saveDocumentation: SAVE_DOCUMENTATION_NO
        }, params)
    };

    return APIPost(dispatch, apiURLBuilder.getURL('communication'), requestData)
        .then(() => dispatch(mailPreContractualToClientSuccess()))
        .catch(response => {
            if (response.error) {
                dispatch(mailPreContractualToClientFailure(response.error));
            } else {
                throw response;
            }
        });
};

const sendBrokerPreContractualMailV2 = params => dispatch => {
    dispatch(mailPreContractualToClientRequest());

    const requestData = {
        query: Object.assign({}, {
            channel: CONNECTION_CHANNEL_CONTACT_CENTER,
            sendOrder: SEND_ORDER,
            saveDocumentation: SAVE_DOCUMENTATION_NO
        }, params)
    };

    return APIPost(dispatch, apiURLBuilder.getURL('communication'), requestData)
        .then(() => dispatch(mailPreContractualToClientSuccess()))
        .catch(response => {
            if (response.error) {
                dispatch(mailPreContractualToClientFailure(response.error));
            } else {
                throw response;
            }
        });
};

const sendLoanPreContractualMail = params => (dispatch) => {

    const requestData = {
        query: params
    };

    dispatch(mailPreContractualToClientRequest());

    return APIPost(dispatch, apiURLBuilder.getURL('loansMail'), requestData)
        .then(() => dispatch(mailPreContractualToClientSuccess()))
        .catch(error => dispatch(mailPreContractualToClientFailure(error)));
};

const sendRoboHirePrecontractualMail = (params={}) => (dispatch, getState) => {
    dispatch(mailPreContractualToClientRequest());
    const immGlobalPosition = getState().raGlobalPosition;
    const hasRoboHired = immGlobalPosition.get('hasRoboHired');
    const roboHiredURL = hasRoboHired ? 'roboHasHiredMail' : 'roboHireMail';
    const requestData = {
        query: {
            ...params,
        },
    };
    return APIGet(dispatch, apiURLBuilder.getURL(roboHiredURL), requestData)
        .then(() => dispatch(mailPreContractualToClientSuccess()))
        .catch(error => dispatch(mailPreContractualToClientFailure(error)));
};

const sendPromotionPrecontractualMail = (params) => (dispatch) => {

    const requestData = {
        query: params
    };

    dispatch(mailPreContractualToClientRequest());

    return APIPost(dispatch, apiURLBuilder.getURL('promotionsPrecontractualMail'), requestData)
        .then(() => dispatch(mailPreContractualToClientSuccess()))
        .catch(error => {
            dispatch(mailPreContractualToClientFailure(error))
        })
};

const sendInsurancePrecontractualMail = (params) => (dispatch) => {
    dispatch(mailPreContractualToClientRequest());
    return APIGet(dispatch, apiURLBuilder.getURL('sendInsuranceMail', params))
        .then(() => dispatch(mailPreContractualToClientSuccess()))
        .catch((error) => dispatch(mailPreContractualToClientFailure(error)))
};

module.exports = {
    sendPlansPreContractualMail,
    sendPreContractualMail,
    sendLoanPreContractualMail,
    sendBrokerPreContractualMail,
    sendBrokerPreContractualMailV2,
    sendRoboHirePrecontractualMail,
    resetPrecontractualMail,
    sendPromotionPrecontractualMail,
    sendInsurancePrecontractualMail,
    setLegalTextRead,
};
