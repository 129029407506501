// @constants
const actionTypes = require('constants/actionTypes');

const locationChange = (location) => dispatch =>
    dispatch({
        type:
        actionTypes.LOCATION_CHANGE,
        payload: location,
    });

const enableSessionErrorLevelRedirect = () => ({
    type: actionTypes.ENABLE_SESSION_ERROR_LEVEL_REDIRECT
});

const disableSessionErrorLevelRedirect = () => dispatch => dispatch({
    type: actionTypes.DISABLE_SESSION_ERROR_LEVEL_REDIRECT
});

module.exports = {
    locationChange,
    enableSessionErrorLevelRedirect,
    disableSessionErrorLevelRedirect,
};
