// @ constants
const {
    AUTHORIZATION_MODIFY_ACCOUNT_CHANGE_SELECTED,
    AUTHORIZATION_MODIFY_ACCOUNT_FAILURE,
    AUTHORIZATION_MODIFY_ACCOUNT_REQUEST,
    AUTHORIZATION_MODIFY_ACCOUNT_RESET,
    AUTHORIZATION_MODIFY_ACCOUNT_SET_VIVIBLE_STEP,
    AUTHORIZATION_MODIFY_ACCOUNT_SUCCESS,
    AUTHORIZATION_MODIFY_ACCOUNT_VALIDATE_STEP
} = require('constants/actionTypes');

const changeSelectedAccount = (accountId) => ({
    type: AUTHORIZATION_MODIFY_ACCOUNT_CHANGE_SELECTED,
    accountId
})

const modifyAcount = (newProduct, newContractNumber, authCode, authType, dataToken, sourceAccountId, destinationAccountId) => ({
    type: AUTHORIZATION_MODIFY_ACCOUNT_REQUEST,
    newProduct,
    newContractNumber,
    authCode,
    authType,
    dataToken,
    sourceAccountId,
    destinationAccountId,
})

const modifyAccountSuccess = (sourceAccountId, destinationAccountId) => ({
    type: AUTHORIZATION_MODIFY_ACCOUNT_SUCCESS,
    sourceAccountId,
    destinationAccountId,
})

const modifyAccountFailure = (error) => ({
    type: AUTHORIZATION_MODIFY_ACCOUNT_FAILURE,
    error
})

const modifyAccountReset = () => ({
    type: AUTHORIZATION_MODIFY_ACCOUNT_RESET
})

const setVisibleStep = (step) => ({
    type: AUTHORIZATION_MODIFY_ACCOUNT_SET_VIVIBLE_STEP,
    step
});

const validateStep = (step) => ({
    type: AUTHORIZATION_MODIFY_ACCOUNT_VALIDATE_STEP,
    step
});

export {
    modifyAccountSuccess,
    modifyAccountFailure,
    modifyAccountReset,
    changeSelectedAccount,
    modifyAcount,
    setVisibleStep,
    validateStep
}