
// @constants
const actionTypes = require('constants/actionTypes');

// NOTE:
// To avoid cyclic dependencies the action is dispatched from here,
// Instead of calling directly to the methods,
const outdateAndResetReducers = () => dispatch => {
    dispatch({ type: actionTypes.CARDS_TRANSACTIONS_OUTDATED });
    dispatch({ type: actionTypes.ACCOUNT_RETENTIONS_OUTDATED });
    dispatch({ type: actionTypes.ACCOUNT_TRANSACTIONS_OUTDATED });
    dispatch({ type: actionTypes.DEPOSITS_RESET });
    dispatch({ type: actionTypes.GLOBAL_POSITION_SET_OUTDATED, payload: {} });
};


module.exports = {
    outdateAndResetReducers
};
