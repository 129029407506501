// @vendors
const { Promise } = require('es6-promise');
const isString = require('lodash/lang/isString');
const usd = require('utilities/usd');
// @helpers
const { APIPost } = require('utilities/APIRequestHelper');
const { 
    getOnlyChangedConsents,
    parseConsentsForUpdate,
} = require('utilities/consentsHelper');
// @constants
const { GDPR_ID } = require('constants/operationTypes');

// @actions
const {
    updateConsentsFailure,
    updateConsentsRequest,
    updateConsentsSuccess,
} = require('gdpr-consents/actions/OKConsents');

// @contact-center
const DEFAULT_USD_ERROR_MESSAGE = 'Error updating GDPR consents';

const updateConsents = (dataToken = '', isConsentsProfileView = false) => (dispatch, getState) => {
    const immGdprConsents = getState().gdprConsents;
    // If there's nothing to update, the endpoint is not called.
    if (immGdprConsents.get('consents').size === 0) {
        return Promise.resolve();
    }

    const initialConsents = parseConsentsForUpdate(immGdprConsents.get('consentsInitialStatus'));
    const updatedConsents = parseConsentsForUpdate(immGdprConsents.get('consents'));
    const filteredConsents = getOnlyChangedConsents(initialConsents, updatedConsents);
    const consents = !!filteredConsents && filteredConsents.size ? filteredConsents : updatedConsents;
    const newCustomer = immGdprConsents.get('newCustomer');
    const placementId = immGdprConsents.get('placementId');
    let requestData = {
        query: {
            consents,
            newCustomer,
            placementId,
        }
    };

    if (immGdprConsents.get('otp')) {
        requestData.query.dataToken = dataToken;
        requestData.query.serviceCode = GDPR_ID;
    }

    dispatch(updateConsentsRequest());
    return APIPost({
        dispatch,
        endpoint: 'consents',
        params: requestData
    })
    .then(() => {
        if (__CONTACT_CENTER__ && isConsentsProfileView) {
            const immConsentsList = immGdprConsents.get('consents');
            const immOKConsent = immConsentsList.find(consent => consent.getIn([ 'consentCell', 'id' ]) === '01');
            const result1 = immOKConsent ? immOKConsent.get('status') : '';
            const immThirdPartyConsent = immConsentsList.find(consent => consent.getIn([ 'consentCell', 'id' ]) === '02');
            const result2 = immThirdPartyConsent ? immThirdPartyConsent.get('status') : '';
            usd.gdprUpdated(result1, result2);
        }
        return dispatch(updateConsentsSuccess());
    })
    .catch(response => {
        if (!!response.error) {
            if (__CONTACT_CENTER__ && isConsentsProfileView) {
                let errorMessage = DEFAULT_USD_ERROR_MESSAGE;

                if (!!response.error.description && isString(response.error.description)) {
                    errorMessage = response.error.description;
                }

                usd.gdprUpdated(undefined, undefined, errorMessage);
            }
            dispatch(updateConsentsFailure(response.error.description));
            if (isConsentsProfileView) {
                throw response; // The throw is needed to cascade error action and show error feedback in MyProfileConsentView
            }
        } else {
            throw response;
        }
    });
};

module.exports = {
    updateConsents
};
