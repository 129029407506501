// @ commons
const { APIPost } = require('utilities/APIRequestHelper');
// @constants
const {
    SET_COLLECTIVES_DATA,
    SET_COLLECTIVE_INFO,
    GET_COLLECTIVE_INFO_FAILURE,
    GET_COLLECTIVE_INFO_IN_PROGRESS,
    GET_COLLECTIVE_INFO_SUCCESS
} = require('constants/actionTypes');

const setCollectivesData = collectives => ({
    type: SET_COLLECTIVES_DATA,
    payload: {
        response: {
            collectives
        }
    }
});

const setCollectiveInfo = (params, collectiveInfo) => ({
    type: SET_COLLECTIVE_INFO,
    payload: {
        response: {
            params,
            collectiveInfo
        }
    }
});

const getCollectiveInfoInProgress = () => ({
    type: GET_COLLECTIVE_INFO_IN_PROGRESS
});

const getCollectiveInfoSucess = collectiveInfo => ({
    type: GET_COLLECTIVE_INFO_SUCCESS,
    payload: {
        response: {
            collectiveInfo
        }
    }
});

const getCollectiveInfoFailure = () => ({
    type: GET_COLLECTIVE_INFO_FAILURE
});

const getCollectiveInfo = (products, uiComponentId, useOmnichannel) => (dispatch) => {

    const requestedProducts = products.map(product => {
        if (!product.alias && !product.standard) {
            product.standard = ''
        }

        return product;
    });
    const channel = !useOmnichannel ? 'private' : undefined;
    const requestData = {
        query: {
            requestedProducts,
            uiComponentId,
            channel
        }
    }

    let endpoint = useOmnichannel ? 'hiringProductInformation' : 'collectiveInformation';

    dispatch(getCollectiveInfoInProgress());

    return APIPost({
        dispatch,
        endpoint,
        params: requestData
    })
        .then((response) => dispatch(getCollectiveInfoSucess(response)))
        .catch(response => {
            if (!!response.error) {
                dispatch(getCollectiveInfoFailure());
            } else {
                throw response;
            }
        });
}

module.exports = {
    setCollectiveInfo,
    setCollectivesData,
    getCollectiveInfo
};
