import {
    AUTHORIZATION_ACTIVATION,
    AUTHORIZATION_ACTIVATION_FAILURE,
    AUTHORIZATION_ACTIVATION_RESET,
    AUTHORIZATION_ACTIVATION_SUCCESS
} from 'constants/actionTypes';

const authorizationActivation = (newProduct, newContractNumber, authCode, authType, notice, noticeActivation, dataToken) => ({
    type: AUTHORIZATION_ACTIVATION,
    newProduct,
    newContractNumber,
    authCode,
    authType,
    notice,
    noticeActivation,
    dataToken
})

const authorizationActivationFailure = (error) => ({
    type: AUTHORIZATION_ACTIVATION_FAILURE,
    error
})

const authorizationActivationReset = () => ({
    type: AUTHORIZATION_ACTIVATION_RESET
})

const authorizationActivationSuccess = () => ({
    type: AUTHORIZATION_ACTIVATION_SUCCESS
})

export {
    authorizationActivation,
    authorizationActivationFailure,
    authorizationActivationReset,
    authorizationActivationSuccess
}