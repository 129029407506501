// @api
const { APIGet, APIPost } = require('utilities/APIRequestHelper');

// @constants
const actionTypes = require('constants/actionTypes');
const { GRAPHIC_TUTOR_TYPE_GP } = require('constants/index');

// @utilities
const storage = require('utilities/storage');

const tutorialGetListRequest = () => ({
    type: actionTypes.TUTORIAL_GET_LIST_REQUEST,
});

const tutorialGetListFailure = error => ({
    type: actionTypes.TUTORIAL_GET_LIST_FAILURE,
    payload: {
        error,
    }
});

const tutorialGetListSuccess = tutorials => ({
    type: actionTypes.TUTORIAL_GET_LIST_SUCCESS,
    payload: {
        tutorials,
    }
});

const tutorialSetSeenRequest = tutorials => ({
    type: actionTypes.TUTORIAL_SET_SEEN_REQUEST,
    payload: {
        tutorials,
    }
});

const tutorialSetSeenFailure = error => ({
    type: actionTypes.TUTORIAL_SET_SEEN_FAILURE,
    payload: {
        error,
    }
});

const tutorialSetSeenSuccess = () => ({
    type: actionTypes.TUTORIAL_SET_SEEN_SUCCESS,
});

const tutorialShouldOpen = tutorial => ({
    type: actionTypes.TUTORIAL_SHOULD_OPEN,
    payload: {
        tutorial,
    }
});

const tutorialToggleOpen = isOpen => ({
    type: actionTypes.TUTORIAL_TOGGLE_OPEN,
    payload: {
        isOpen,
    }
});

const requestSetTutorialSeen = tutorialsId => dispatch => {
    const requestData = {
        query: {
            channel: 'web',
            seenTutorials: tutorialsId,
        }
    };
    
    dispatch(tutorialSetSeenRequest(tutorialsId));

    return APIPost({
        dispatch,
        endpoint: 'userProfileTutorial',
        params: requestData,
    })
    .then(() => dispatch(tutorialSetSeenSuccess()))
    .catch(response => {
        if (!!response.error) {
            dispatch(tutorialSetSeenFailure(response.error.description));
            return response;
        } else {
            throw response;
        }
    });
}

// This method can get every tutorial Id from localstorage and returns an array with database Id's
const getTutorialsIdFromLocalstorage = tutorialsList => {
    const tutorialsIdFromLocalStorage = Object.keys(localStorage).filter(key =>
        key.startsWith('tg-tutorial-') ||
        key === GRAPHIC_TUTOR_TYPE_GP
    );
    let tutorialsIds = [];
    tutorialsList.forEach(tutorial => {
        if (tutorialsIdFromLocalStorage.includes(tutorial.tutorial)) {
            tutorialsIds.push(tutorial.tutorialId);
        }
    });

    return tutorialsIds;
}

// Remove every old localstorage tutorialId
const removeTutorialsFromLocalstorage = (tutorialsIdFromLocalStorage, tutorialsList) => {
    tutorialsList.forEach(tutorial => {
        if (tutorialsIdFromLocalStorage.includes(tutorial.tutorialId)) {
            storage.localStorage.remove(tutorial.tutorial);
        }
    });
}

// Set each tutorial from localstorage and will send it if is necessary
const setSeenTutorialsFromLocalstorage = (tutorialsIdFromLocalStorage, tutorialsList) => dispatch => {
    let tutorialListSend = [];
    tutorialsList.forEach((tutorial, index) => {
        if (tutorialsIdFromLocalStorage.includes(tutorial.tutorialId)) {
            tutorialsList[index].seen = true;
            tutorialListSend.push(tutorial.tutorialId);
        }
    });
    
    dispatch(tutorialGetListSuccess(tutorialsList));
    if (!!tutorialListSend.length) {
        dispatch(requestSetTutorialSeen(tutorialListSend));
    }
}

const requestGetTutorialList = () => dispatch => {
    const requestData = {
        query: {
            channel: 'web',
        }
    };

    dispatch(tutorialGetListRequest());

    return APIGet({
        dispatch,
        endpoint: 'userProfileTutorial',
        params: requestData,
    })
    .then(response => {
        const localStorageTutorialsId = getTutorialsIdFromLocalstorage(response);
        removeTutorialsFromLocalstorage(localStorageTutorialsId, response);
        dispatch(setSeenTutorialsFromLocalstorage(localStorageTutorialsId, response));
    })
    .catch(response => {
        if (!!response.error) {
            dispatch(tutorialGetListFailure(response.error.description));
            return response;
        } else {
            throw response;
        }
    });
};

module.exports = {
    requestGetTutorialList,
    requestSetTutorialSeen,
    tutorialShouldOpen,
    tutorialToggleOpen,
};
