// @core
const apiURLBuilder = require('core/apiURLBuilder');

// @utilities
const { APIPost } = require('utilities/APIRequestHelper');

// @constants
const actionTypes = require('constants/actionTypes');

// @utilities
const { logoutRedirection, manageLogoutTimer } = require('utilities/LogoutHelper');
const { getOriginCountry } = require('utilities/countryHelper');
const Storage = require('utilities/storage');

// @constants
const { TOKEN_CREDENTIAL } = require('constants/session');


export const showLogoutPopup = () => ({
    type: actionTypes.SHOW_LOGOUT_POPUP
});

const hideLogoutPopup = () => ({
    type: actionTypes.HIDE_LOGOUT_POPUP
});

const keepAliveSuccess = (expiration) => ({
    type: actionTypes.KEEP_ALIVE_SUCCESS,
    expiration
});

const keepAliveError = (error) => ({
    type: actionTypes.KEEP_ALIVE_ERROR,
    error
});    

export const requestInitialKeepAlive = () => ({
    type: actionTypes.REQUEST_INITIAL_KEEP_ALIVE
});

export const showKeepAlivePopup = () => ({
    type: actionTypes.SHOW_KEEPALIVE_POPUP
});

export const hideKeepAlivePopup = () => ({
    type: actionTypes.HIDE_KEEPALIVE_POPUP
});

const handleLogout = () => (dispatch, getState) => {
    dispatch(hideLogoutPopup());
    const country = getOriginCountry(getState().profile.get('originCountry'));
    logoutRedirection(country);
};

const keepAlive = (initialCall) => (dispatch, getState) => {
    const isFetchingInitialKeepAlive = getState().logout.get('isFetchingInitialKeepAlive');
    const hasBeenFetchedInitialKeepAlive = getState().logout.get('hasBeenFetchedInitialKeepAlive');
    if (!initialCall || (initialCall && !isFetchingInitialKeepAlive && !hasBeenFetchedInitialKeepAlive)) {
        dispatch(requestInitialKeepAlive());
        return APIPost(dispatch, apiURLBuilder.getURL('keepAlive'))
            .then(({ expiration }) => {
                if (expiration) {
                    dispatch(keepAliveSuccess(expiration));
                    manageLogoutTimer(dispatch, expiration, Storage.cookies.get(TOKEN_CREDENTIAL));
                }
            })
            .catch(error => {
                dispatch(keepAliveError(error));
                throw error
            });
    }
};


module.exports = {
    handleLogout,
    hideKeepAlivePopup,
    hideLogoutPopup,
    keepAlive,
};
