// @ vendors
const { Promise } = require('es6-promise');
// @ commons
const apiURLBuilder = require('core/apiURLBuilder');
const { APIGet, APIPost } = require('utilities/APIRequestHelper');

//@constants
const {
    MORTGAGE_REQUEST_FETCH_CONDITIONS_REQUEST,
    MORTGAGE_REQUEST_FETCH_CONDITIONS_SUCCESS,
    MORTGAGE_REQUEST_FETCH_CONDITIONS_FAILURE,
    MORTGAGE_REQUEST_ACCEPT_CONDITIONS_REQUEST,
    MORTGAGE_REQUEST_ACCEPT_CONDITIONS_SUCCESS,
    MORTGAGE_REQUEST_ACCEPT_CONDITIONS_FAILURE,
    MORTGAGE_REQUEST_FETCH_CONDITIONS_REQUEST_SET_ACCOUNT,
    MORTGAGE_REQUEST_ACCEPT_BINDING_OFFER_INPROGRESS,
    MORTGAGE_REQUEST_ACCEPT_BINDING_OFFER_SUCCESS,
    MORTGAGE_REQUEST_RESET_IS_BINDING_OFFER,
    MORTGAGE_REQUEST_FAILURE_OFFER
} = require('constants/actionTypes');
const mortgageRequestStates = require('constants/mortgageRequestStates');

// @actions
const stateActions = require('actions/mortgageRequest/mortgageRequestState');

const setAccountAction = (accountId, accountIBAN) => ({
    type: MORTGAGE_REQUEST_FETCH_CONDITIONS_REQUEST_SET_ACCOUNT,
    data: {
        accountId,
        accountIBAN
    }
});

const requestConditionsInProgress = () => ({
    type: MORTGAGE_REQUEST_FETCH_CONDITIONS_REQUEST
});

const requestConditionsSuccess = data => ({
    type: MORTGAGE_REQUEST_FETCH_CONDITIONS_SUCCESS,
    data
});

const requestConditionsError = error => ({
    type: MORTGAGE_REQUEST_FETCH_CONDITIONS_FAILURE,
    data: {
        error
    }
});

const requestAcceptConditionsInProgress = () => ({
    type: MORTGAGE_REQUEST_ACCEPT_CONDITIONS_REQUEST
});

const requestAcceptConditionsSuccess = data => ({
    type: MORTGAGE_REQUEST_ACCEPT_CONDITIONS_SUCCESS,
    data
});

const requestAcceptConditionsError = error => ({
    type: MORTGAGE_REQUEST_ACCEPT_CONDITIONS_FAILURE,
    data: {
        error
    }
});

const requestAcceptBindingOffer = () => ({
    type: MORTGAGE_REQUEST_ACCEPT_BINDING_OFFER_INPROGRESS
});

const requestAcceptBindingOfferSuccess = () => ({
    type: MORTGAGE_REQUEST_ACCEPT_BINDING_OFFER_SUCCESS
});

const requestAcceptOfferFailure = error => ({
    type: MORTGAGE_REQUEST_FAILURE_OFFER,
    data: {
        error
    }
});

const resetBinding = () => ({
    type: MORTGAGE_REQUEST_RESET_IS_BINDING_OFFER,
})

const isBindingOfferReset = () => (dispatch) => {
    dispatch(resetBinding());
}

const requestConditions = () => (dispatch, getState) => {
    const currentState = getState().mortgageRequestConditions;
    const isAlreadyFetching = currentState.get('isFetching');

    if (!isAlreadyFetching) {
        dispatch(requestConditionsInProgress());
        return APIGet({
            dispatch,
            endpoint: 'mortgageRequestConditions'
        })
            .then(response => dispatch(requestConditionsSuccess(response)))
            .catch(response => {
                if (!!response.error) {
                    dispatch(requestConditionsError(response.error));
                } else {
                    throw response;
                }
            });
    } else {
        return Promise.resolve();
    }
};

const aceptBindingOffer = (history) => (dispatch, getState) => {
    const currentState = getState().mortgageRequestConditions;
    const accountState = getState().accounts;
    const accountId = currentState.get('accountId');
    const fullContractNumber = accountState.getIn(['byId', accountId, 'fullContractNumber'], '');

    dispatch(requestAcceptBindingOffer());

    const requestData = {
        query: {
            account: fullContractNumber
        }
    };

    APIPost({
        dispatch,
        endpoint: 'mortgageRequestAcceptBindingOffer',
        params: requestData
    })
        .then(() => {
            const mortgageRequestState = getState().mortgageRequestState;
            const state = mortgageRequestState.get('estado');
            dispatch(requestAcceptBindingOfferSuccess());

            if (state === mortgageRequestStates.APROBADO_RIESGOS) {
                dispatch(stateActions.requestState(undefined, history));
            } else {
                history.push('/myprofile/mortgage/panel');
            }
        })
        .catch(response => {
            if (!!response.error) {
                dispatch(requestAcceptOfferFailure(response.error))
                history.push('/myprofile/mortgage-error');
            } else {
                throw response;
            }
        })

}

const requestAcceptConditions = () => (dispatch, getState) => {
    const currentState = getState().mortgageRequestConditionsAccept;
    const isAlreadyFetching = currentState.get('isFetching');
    const idExpediente = getState().mortgageRequestState.get('idExpediente');
    const url = apiURLBuilder.getURL(
        'mortgageRequestAcceptConditions',
        { idExpediente }
    );

    if (!isAlreadyFetching) {
        dispatch(requestAcceptConditionsInProgress());
        return APIPost({
            dispatch,
            endpoint: 'mortgageRequestAcceptConditions',
            url
        })
            .then(response => {
                dispatch(requestAcceptConditionsSuccess(response));
                dispatch(stateActions.requestState('/myprofile/mortgage/panel'));
            })
            .catch(response => {
                if (!!response.error) {
                    dispatch(requestAcceptConditionsError(response.error));
                } else {
                    throw response;
                }
            });
    } else {
        return Promise.resolve();
    }
};

const setAccount = (accountId, accountIBAN) => dispatch => {
    dispatch(setAccountAction(accountId, accountIBAN))
};

module.exports = {
    requestConditions,
    requestAcceptConditions,
    setAccount,
    aceptBindingOffer,
    isBindingOfferReset
};
