// @vendor
const get = require('lodash/object/get');
const filter = require('lodash/collection/filter');

// @core
const apiURLBuilder = require('core/apiURLBuilder');

// @utilities
const { APIGet } = require('utilities/APIRequestHelper');
const { isFutureAccount } = require('utilities/APIParsingHelper');

// @components
const { setIbans } = require('./setIbans');

// @actions
const { clientsWithoutAccountsPGCheck } = require('actions/noClient');

// @constants
const { GP_REQUEST_HEADER_VERSION } = require('constants/index');
const actionTypes = require('constants/actionTypes');
const {
    WEB_INACTIVE_ACCOUNT_ENABLED,
    NOT_ALLOWED_NEW_PG_WEALTH_COUNTRIES
} = require('constants/flagBasedFeatures');

const publicInformationRequest = () => ({
    type: actionTypes.GLOBAL_POSITION_REQUEST
});

const globalWealthRequest = () => ({
    type: actionTypes.GLOBAL_WEALTH_REQUEST
});

//API life cicle actions
const requestPublicInformationSuccess = (data, originCountry, isInactiveAccountsEnabled) => ({
    type: actionTypes.GLOBAL_POSITION_REQUEST_SUCCESS,
    payload: data,
    originCountry,
    isInactiveAccountsEnabled
});

const setWaferId = (id) => ({
    type: actionTypes.SET_WAFER_ID,
    payload: id,
});

const setWaferIdRequest = (id) => (dispatch) => {
    dispatch(setWaferId(id))
  };


function requestWalletListSuccess(data) {
    // (Andres): We're intentionally sending twice 'payload.datosSalidaValores.valores'
    // The original use of it has to be discussed, and i wanted to keep the new
    // stuff separated.
    // This must be unified.
    return {
        type: actionTypes.BROKER_GET_WALLET_LIST_SUCCESS,
        walletList: get(data, 'payload.datosSalidaValores.valores', []),
        walletPlans: get(data, 'payload.datosSalidaPlanes.planes', []),
        walletFunds: get(data, 'payload.datosSalidaFondos.fondos', []),
        walletFutures: filter(get(data, 'payload.datosSalidaCuentas.cuentas', []), x => isFutureAccount(x)),
        walletAllFutures: get(data, 'payload.datosSalidaCuentas.cuentas', []),
        walletValues: get(data, 'payload.datosSalidaValores.valores', [])
    }
}

const requestPublicInformationFailure = (error) => {
    if (error instanceof Error) {
        //TODO: emit production logger.error(error);
        throw error;
    }

    return {
        type: actionTypes.GLOBAL_POSITION_REQUEST_FAILURE,
        payload: { error }
    };
};

const requestGlobalPosition = () => dispatch => {
    const requestData = {
        header: {
            version: GP_REQUEST_HEADER_VERSION
        },
        query: {
            listaSolicitada: 'TODOS',
            indicadorSaldoPreTarj: 'false'
        }
    };

    dispatch(publicInformationRequest());
    return APIGet(dispatch, apiURLBuilder.getURL('globalPosition'), requestData);
};

function allPublicInformationLoadedSuccess() {
    return {
        type: actionTypes.ALL_PUBLIC_INFORMATION_LOADED_SUCCCESS
    }
}


// Main entry point
const requestPublicInformation = (originCountryDefault, history) => (dispatch, getState) => {
    const originCountry = originCountryDefault || getState().profile.get('originCountry');
    const globalPosition = getState().globalPosition;
    const isInactiveAccountsEnabled = getState().flagBasedFeatures.get(WEB_INACTIVE_ACCOUNT_ENABLED);
    const hasBeenFetched = globalPosition.get('hasBeenFetched');
    const isFetching = globalPosition.get('isFetching');
    const hasError = !!globalPosition.get('error');
    const isOutdated = !!globalPosition.get('isOutdated');

    if ((hasBeenFetched || isFetching || hasError) && !isOutdated) {
        return Promise.resolve();
    }

    return dispatch(requestGlobalPosition())
        .then(response => setIbans(response))
        .then(response => clientsWithoutAccountsPGCheck(response, history))
        .then(response => dispatch(requestPublicInformationSuccess(response, originCountry, isInactiveAccountsEnabled)))
        .then(response => dispatch(requestWalletListSuccess(response)))
        .then(() => dispatch(allPublicInformationLoadedSuccess()))
        .catch(error => {
            dispatch(requestPublicInformationFailure(error))
            throw error;
        });
};

const outdateGlobalPosition = () => ({
    type: actionTypes.GLOBAL_POSITION_SET_OUTDATED,
    payload: {}
});

const validateWithAPISuccess = (response) => ({
    type: actionTypes.PG_WEALTH_API_SUCCESS,
    payload: response
});

const validateWithAPIFailure = () => ({
    type: actionTypes.PG_WEALTH_API_FAILURE
});

const getWealthDataFF = () => async (dispatch,getState) => {
    const pgWealthFF = getState().flagBasedFeatures.get(NOT_ALLOWED_NEW_PG_WEALTH_COUNTRIES);
    dispatch(globalWealthRequest());

        if(pgWealthFF){
                  const requestData = {
                      header : {
                          version: 3,
                      }
                  };
              
                  return await APIGet({
                      dispatch,
                      endpoint: 'myMoneyPgValoresTotal',
                      params: requestData
                  })
                  .then(response => {
                    dispatch(validateWithAPISuccess(response));
                  })
                .catch(() => dispatch(validateWithAPIFailure()));
  
  
          }
       }
  

module.exports = {
    outdateGlobalPosition,
    requestPublicInformation,
    setWaferIdRequest,
    getWealthDataFF
};
