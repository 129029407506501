// @ constants
const {
    FETCH_SECURITY_KIT_CHANGE_FAILURE,
    FETCH_SECURITY_KIT_CHANGE_SUCCESS,
    GET_KITS,
    SECURITY_KIT_CHANGE_SET_KIT,
    SECURITY_KIT_CHANGE_SET_VISIBLE_STEP,
    SECURITY_KIT_CHANGE_VALIDATE_STEP,
    SECURITY_KIT_MODIFY_FAILURE,
    SECURITY_KIT_MODIFY_SUCCESS,
    SECURITY_KIT_RESET,
    SECURITY_KIT_GET_KIT_IS_FETCHING
} = require('constants/actionTypes');

const {
    SECURITY_KIT_CHANGE_START,
} = require('constants/contactCenter/actionTypes');

const changeKitFailure = error => ({
    type: SECURITY_KIT_MODIFY_FAILURE,
    error
});

const changeKitStart = () => ({
    type: SECURITY_KIT_CHANGE_START
});

const changeKitSuccess = (defaultKit, sets) => ({
    type: SECURITY_KIT_MODIFY_SUCCESS,
    defaultKit,
    sets
});

const changeKit = (defaultKit, sets, dataToken) => ({
    type: SECURITY_KIT_CHANGE_SET_KIT,
    dataToken,
    defaultKit,
    sets
})

const getKitsFailure = (error) => ({
    type: FETCH_SECURITY_KIT_CHANGE_FAILURE,
    error
});

const getKitsSuccess = ({kitCatalog, setCatalog, sets}) => ({
    type: FETCH_SECURITY_KIT_CHANGE_SUCCESS,
    kitCatalog,
    setCatalog,
    sets
})

const getKits = () => ({
    type: GET_KITS
});

const reset = () => ({
    type: SECURITY_KIT_RESET
})

const setVisibleStep = (step) => ({
    type: SECURITY_KIT_CHANGE_SET_VISIBLE_STEP,
    step
});

const validateStep = (step) => ({
    type: SECURITY_KIT_CHANGE_VALIDATE_STEP,
    step
});

const setSecurityKitGetKitIsFetching = (value) => ({
    type: SECURITY_KIT_GET_KIT_IS_FETCHING,
    value
});

module.exports = {
    changeKit,
    changeKitFailure,
    changeKitStart,
    changeKitSuccess,
    getKits,
    getKitsFailure,
    getKitsSuccess,
    reset,
    setVisibleStep,
    validateStep,
    setSecurityKitGetKitIsFetching
};
