const LOAD_SCRIPT_ERROR = 'Load Script Error';
const RESPONSE_EMPTY_REQUEST_ERROR = 'Response empty from response.text()';
const WINDOW_GLOBAL_VERTICAL_ERROR = "vertical doesn't exist in window.global";
const REDUCERS_DUPLICATED = "This reducers are duplicated:";

class BadRequestVerticalError extends Error {
    constructor(message) {
        super(message);
        this.name = 'BadRequestVerticalError';
    }
}

class EmptyRequestVerticalError extends Error {
    constructor(message) {
        super(`${RESPONSE_EMPTY_REQUEST_ERROR} - ${message}`);
        this.name = 'EmptyRequestVerticalError';
    }
}

class LoadScriptVerticalError extends Error {
    constructor(message) {
        super(`${LOAD_SCRIPT_ERROR} - ${message}`);
        this.name = 'LoadScriptVerticalError';
    }
}

class WindowGlobalVerticalError extends Error {
    constructor(message) {
        super(`${WINDOW_GLOBAL_VERTICAL_ERROR} - ${message}`);
        this.name = 'WindowGlobalVerticalError';
    }
}

class ReducersDuplicated extends Error {
    constructor(reducers) {
        super(`${REDUCERS_DUPLICATED} ${reducers.join(', ')}`);
        this.name = 'ReducersDuplicated';
    }
}

export {
    BadRequestVerticalError,
    EmptyRequestVerticalError,
    LoadScriptVerticalError,
    WindowGlobalVerticalError,
    ReducersDuplicated
};
