const actionTypes = require('constants/actionTypes');
const apiURLBuilder = require('core/apiURLBuilder');
const { APIGet } = require('utilities/APIRequestHelper');

function requestIsUsPerson() {
    return {
        type: actionTypes.BROKER_IS_US_PERSON_FETCHING
    };
}

function requestIsUsPersonSuccess(response) {
    return {
        type: actionTypes.BROKER_IS_US_PERSON_SUCCESS,
        payload:  response,
    };
}

function requestIsUsPersonFailure() {
    return {
        type: actionTypes.BROKER_IS_US_PERSON_ERROR,
    };
}

const getIsUsPersonInfo = () => (dispatch) => {
    dispatch(requestIsUsPerson());
    APIGet(dispatch, apiURLBuilder.getURL('brokerIsUsPerson'))
            .then((response) => dispatch(requestIsUsPersonSuccess(response)) )
            .catch(() => dispatch(requestIsUsPersonFailure()));
}

module.exports = {
    getIsUsPersonInfo,
};
