// @helpers
const { APIGet } = require('utilities/APIRequestHelper');
const { getLanguage } = require('core/i18n').i18n;
const { COUNTRY_CODE_ES } = require('commonsConstants/countryCodes')

// @constants
const actionTypes = require('../constants/actionTypes');

// @commons
const apiURLBuilder = require('core/apiURLBuilder');

const globalVariablesRequest = lang => ({
    type: actionTypes.GLOBAL_VARIABLES_REQUEST,
    payload: {
        lang
    }
});

const globalVariablesSuccess = (data = {}) => ({
    type: actionTypes.GLOBAL_VARIABLES_SUCCESS,
    payload: {
        data
    }
});

const globalVariablesFailure = error => ({
    type: actionTypes.GLOBAL_VARIABLES_FAILURE,
    payload: {
        error
    }
});

const getGlobalVariables = originCountry => dispatch => {
    const language = originCountry === COUNTRY_CODE_ES ? getLanguage() : originCountry.toLowerCase()

    const requestData = {
        header: {
            version: 2,
        },
        query: {
            language,
            originCountry: originCountry.toLowerCase()
        }
    };

    dispatch(globalVariablesRequest());

    return APIGet(dispatch, apiURLBuilder.getURL('global-parameters'), requestData)
        .then(response => dispatch(globalVariablesSuccess(response)))
        .catch(error => dispatch(globalVariablesFailure(error)));
};

module.exports = getGlobalVariables;
