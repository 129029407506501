// @vendors
const difference = require('lodash/array/difference');
const get = require('lodash/object/get');

// @utils
const { getLanguage } = require('core/i18n').i18n;
const { APIPost } = require('utilities/APIRequestHelper');
const apiURLBuilder = require('core/apiURLBuilder');
const {
    recordCommercialSpace,
    recordCommercialSpaceV2
} = require('utilities/tagging');
const { parseAdFromCMS } = require('utilities/cmsDataHelpers');

// @constants
const actionTypes = require('constants/actionTypes');
const { COMMERCIAL_ADS_CHANNEL } = require('constants/index');
const {
    ADOBE_WEB_ENV,
    ADOBE_OFFER_CODE_GLOBAL_POSITION_TAB,
    ADOBE_OFFER_CODE_ACCOUNTS_TAB,
    ADOBE_OFFER_CODE_CARDS_TAB,
} = require('constants/adobe');
const DONT_SHOW_INTERSTITIAL_AGAIN_DESCRIPTION = 'interstitial';
const { ADOBE } = require('constants/flagBasedFeatures');
const {
    ACTION_EVENT_VIEW,
    COMMERCIAL_ADS_EVENT_CATEGORY,
    COMMERCIAL_ADS_TEALIUM_EVENT,
 } = require('constants/tagging');

const requestAdsInProgress = () => ({
    type: actionTypes.FETCH_ADS_REQUEST
});

const requestAdsReset = () => ({
    type: actionTypes.FETCH_ADS_REQUEST_RESET
});

const cardsAdsSetLoading = value => ({
    type: actionTypes.CARDS_ADS_SET_LOADING,
    payload: {
        value
    }
});

const requestAdsSuccess = (response, adsIdsToFetch) => ({
    type: actionTypes.FETCH_ADS_SUCCESS,
    payload: {
        adsIdsToFetch,
        adList: response.nodes ? get(response, 'nodes', []) : response,
    }
});

const requestAdsFailure = (error, adsIdsToFetch) => ({
    type: actionTypes.FETCH_ADS_FAILURE,
    payload: {
        adsIdsToFetch,
        error
    }
});

const storeIDList = list =>({
    type: actionTypes.STORE_ID_LIST,
    payload: {
        list
    }
})

const clearAdLocation = adLocation => ({
    type: actionTypes.CLEAR_AD_LOCATION,
    payload: {
        adLocation
    }
})

const requestAds = (idsRequiredByView, type, isAdobeV2Enabled) => (dispatch, getState) => {
    const immCommercialAds = getState().commercialAds;


    const isAlreadyFetching = getState().commercialAds.get('isFetching');

    const alreadyStoredAds = immCommercialAds.get('byOrder').toJS();
    let adsIdsToFetch = difference(idsRequiredByView, alreadyStoredAds);

    if (type !== 'allCards') {
        if (isAdobeV2Enabled) {
            adsIdsToFetch = idsRequiredByView
        } else {
            const lastViewIdsList = immCommercialAds.get('idList').toArray();
            // If this condition is met, we are changing the current rendered view, then we need to call analytics again
            if (difference(idsRequiredByView, lastViewIdsList).length){
                // We update the list of previously requested Ads list
                dispatch(storeIDList(idsRequiredByView));
                // For the already stored ads, we just call analytics to indicate they are being shown again
                // For the rest, analytics will be called in success
                if (!isAlreadyFetching){
                    difference(idsRequiredByView, adsIdsToFetch).forEach((id) => {
                        const immAd = immCommercialAds.getIn(['byId', id]);
                        recordCommercialSpace({
                            title: immAd.get('title'),
                            body: `<div>${immAd.getIn(['body', 'value'])}</div>`,
                            spaceId:immAd.get('adId')
                        });
                    });
                }
            }
        }
    }

    // Because many requests are being made from ads of the same view, we need to check if the request has already been done
    // - If adsIdsToFetch is empty, then all ads were previously requested, that means the view was rendered before
    if (adsIdsToFetch.length && (!isAlreadyFetching || type === 'allCards')) {
        let requestOffersSpaceAll;
        if(adsIdsToFetch.indexOf('B.2.1') > -1){
            requestOffersSpaceAll = {
                offerSpace : [
                    {
                        cardinality : 1,
                        environment: ADOBE_WEB_ENV,
                        locationId: "B.2.1",
                        name : ADOBE_OFFER_CODE_GLOBAL_POSITION_TAB,
                    }
                ]
            }
        } else if(adsIdsToFetch.indexOf('B.3.1') > -1){
            requestOffersSpaceAll = {
                offerSpace : [
                    {
                        cardinality : 3,
                        environment: ADOBE_WEB_ENV,
                        locationId: "B.3.1",
                        name : ADOBE_OFFER_CODE_ACCOUNTS_TAB,
                    }
                ]
            }
        } else if(adsIdsToFetch.indexOf('B.5.1') > -1){
            requestOffersSpaceAll = {
                offerSpace : [
                    {
                        cardinality : 1,
                        environment: ADOBE_WEB_ENV,
                        locationId: "B.5.1",
                        name : ADOBE_OFFER_CODE_CARDS_TAB,
                    }
                ]
            }
        }
        const requestDataV1 = {
            query: {
                channel: COMMERCIAL_ADS_CHANNEL,
                lang: getLanguage().toLowerCase(),
                locations: adsIdsToFetch.toString()
            }
        };
        if(requestOffersSpaceAll){
            requestDataV1.query.requestOffersSpaceAll = requestOffersSpaceAll;
        }

        const requestDataV2 = {
            header:{
                version:2
            },
            query: {
                channel: COMMERCIAL_ADS_CHANNEL,
                lang: getLanguage().toLowerCase(),
                locations: adsIdsToFetch,
                includeDuplicates: true
            }
        };

        const requestData = isAdobeV2Enabled ? requestDataV2 : requestDataV1;
        dispatch(requestAdsInProgress());
        if (type === 'cards') {
            dispatch(cardsAdsSetLoading(true));
        }

        return APIPost(dispatch, apiURLBuilder.getURL('requestAds'), requestData)
            .then(response => {
                if (type === 'cards'){
                    dispatch(cardsAdsSetLoading(false));
                } else if (type !== 'allCards') {
                    let responseData = response.nodes ? response.nodes : response;
                    if (responseData && isAdobeV2Enabled) {
                        const taggingParams = {
                            categoriaEvento: '',
                            accionEvento: ACTION_EVENT_VIEW,
                            tealiumEvent: COMMERCIAL_ADS_TEALIUM_EVENT
                        }
                        const taggingCustomData = {
                            promotion_id: [],
                            promotion_name: [],
                            promotion_creative: [],
                            promotion_position: [],
                        }
                        const offerCodes = [];
                        responseData.forEach((comercialAd) => {
                            const parsedAd = parseAdFromCMS(comercialAd);
                            taggingCustomData.promotion_id.push(parsedAd.fieldCmsId);
                            taggingCustomData.promotion_name.push(parsedAd.title);
                            taggingCustomData.promotion_creative.push(`<div>${get(parsedAd, 'body.value', '')}</div>`);
                            taggingCustomData.promotion_position.push(parsedAd.adId);
                            offerCodes.push(parsedAd.offerCode);
                        });
                        taggingParams.categoriaEvento = COMMERCIAL_ADS_EVENT_CATEGORY + offerCodes.join(', ');
                        recordCommercialSpaceV2(taggingParams, taggingCustomData);
                    }
                }
                return dispatch(requestAdsSuccess(response, adsIdsToFetch));
            })
            .catch(response => {
                if (!!response.error) {
                    if (type === 'cards') {
                        dispatch(cardsAdsSetLoading(false));
                    }
                    dispatch(requestAdsFailure(response.error, adsIdsToFetch));
                } else {
                    throw response;
                }
            });
    }
};

const fetchDontShowInterstitialAgainSuccess = () => ({
    type: actionTypes.FETCH_DONT_SHOW_INTERSTITIAL_AGAIN_SUCCESS
});

const fetchDontShowInterstitialAgainFailure = error => ({
    type: actionTypes.FETCH_DONT_SHOW_INTERSTITIAL_AGAIN_FAILURE,
    payload: {
        error
    }
});

const fetchIncreaseInterstitialShownSuccess = () => ({
    type: actionTypes.FETCH_INCREASE_INTERSTITIAL_SHOWN_SUCCESS
});

const fetchIncreaseInterstitialShownFailure = error => ({
    type: actionTypes.FETCH_INCREASE_INTERSTITIAL_SHOWN_FAILURE,
    payload: {
        error
    }
});

const confirmClauseSuccess = contentId => ({
    type: actionTypes.CONFIRM_CLAUSE_SUCCESS,
    payload: { contentId }
});

const confirmClauseFailure = error => ({
    type: actionTypes.CONFIRM_CLAUSE_FAILURE,
    payload: { error }
});

const dontShowInterstitialAgain = clauseId => (dispatch, getState) => {
    const isAlreadyFetching = getState().commercialAds.get('isFetching');
    if (!isAlreadyFetching) {
        const requestData = {
            clauseId,
            nameClause: DONT_SHOW_INTERSTITIAL_AGAIN_DESCRIPTION
        };

        APIPost(dispatch, apiURLBuilder.getURL('userClausesPersistence', requestData))
            .then(dispatch(fetchDontShowInterstitialAgainSuccess()))
            .catch(error => dispatch(fetchDontShowInterstitialAgainFailure(error)));
    }
};

const increaseInterstitialShown = clauseId => (dispatch, getState) => {
    const isAlreadyFetching = getState().commercialAds.get('isFetching');
    if (!isAlreadyFetching) {
        const requestData = {
            clauseId,
            nameClause: DONT_SHOW_INTERSTITIAL_AGAIN_DESCRIPTION
        };

        APIPost(dispatch, apiURLBuilder.getURL('userClausesIncrease', requestData))
            .then(dispatch(fetchIncreaseInterstitialShownSuccess()))
            .catch(error => dispatch(fetchIncreaseInterstitialShownFailure(error)));
    }
};

const confirmClause = (clauseId, contentId) => (dispatch, getState) => {
    const isAlreadyFetching = getState().commercialAds.get('isFetching');
    if (!isAlreadyFetching) {
        const requestData = {
            clauseId,
            nameClause: DONT_SHOW_INTERSTITIAL_AGAIN_DESCRIPTION
        };

        return APIPost(dispatch, apiURLBuilder.getURL('userClausesPersistence', requestData))
            .then(dispatch(confirmClauseSuccess(contentId)))
            .catch(error => dispatch(confirmClauseFailure(error)));
    } else {
        return Promise.resolve();
    }
};



const updateAds = (proposition) => (dispatch, getState) => {
    const isAdobeV2Enabled = getState().flagBasedFeatures.get(ADOBE);
    const requestData = {
        query: {
            requestProposition: {
                propositions: {
                    proposition: proposition
                }
            },
            system: COMMERCIAL_ADS_CHANNEL
        }
    };
    if (isAdobeV2Enabled) {
        requestData.header = {
            version: 2
        }
    }
    return APIPost(dispatch,  apiURLBuilder.getURL('updateAds'), requestData)
};

const updateAdobeStatusLocally = (data) => ({
    type: actionTypes.UPDATE_ADOBE_STATUS,
    payload: data
});

const updateAdsStatus = (proposition) => (dispatch) => {
    dispatch(updateAdobeStatusLocally(proposition));
    return dispatch(updateAds(proposition))
}

const setRemainingAdsAsClear = (locationId = []) => (dispatch, getState) => {
    const isAdobeV2Enabled = getState().flagBasedFeatures.get(ADOBE);
    const immCommercialAds = getState().commercialAds;

    if (!isAdobeV2Enabled){
        return;
    }

    if (!Array.isArray(locationId)){
        return;
    }

    locationId.forEach(adId => {
        const immAd = immCommercialAds.getIn(['byId', adId]);
        if (!immAd) {
            return;
        }

        const propId = immAd.get('propositionId');
        if (!propId) {
            return;
        }

        /**  We only update status if:
         *      - There's no adobeStatus (it's the first time it's being rendered)
         *      - There's a status, but it's not a final one (interes/not interested)
         *      - There's a status and it's different from the current one
         * */

        // Removed update call for not interested (status 9) and clearing ads location 
        // so every time there will be a fresh update call for presented status 1 when ad is presented
        dispatch(clearAdLocation(adId))

    });
}

const updateAdsStatusAndCallback = (proposition, callback) => (dispatch) => {
    return dispatch(updateAdsStatus(proposition))
        .then(callback)
}

module.exports = {
    requestAds,
    updateAdsStatus,
    setRemainingAdsAsClear,
    updateAdsStatusAndCallback,
    requestAdsSuccess,
    dontShowInterstitialAgain,
    increaseInterstitialShown,
    confirmClause,
    updateAds,
    requestAdsReset
};
