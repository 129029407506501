//@commons
const { APIGet } = require('utilities/APIRequestHelper');
//@constants
const actionTypes = require('constants/actionTypes');

const taxesInformationRequestInProgress = () => ({
    type: actionTypes.MORTGAGE_REQUEST_TAXES_INFORMATION_REQUEST
});
const taxesInformationRequestSuccess = data => ({
    type: actionTypes.MORTGAGE_REQUEST_TAXES_INFORMATION_SUCCESS,
    data
});
const taxesInformationRequestError = error => ({
    type: actionTypes.MORTGAGE_REQUEST_TAXES_INFORMATION_ERROR,
    error
});
const taxesInformationRequest = mortgageData => (dispatch, getState) => {
    const currentState = getState().mortgageRequestTaxesInformation;
    const isAlreadyFetching = currentState.get('isFetching');
    const requestData = {
        query: {
            type: mortgageData.type,
            duration: mortgageData.duration,
            code3: mortgageData.code3,
            code4: mortgageData.code4,
            groupCode: mortgageData.groupCode
        }
    };

    if (!isAlreadyFetching) {
        dispatch(taxesInformationRequestInProgress());
        return APIGet({
            dispatch,
            endpoint: 'mortgageTaxesInformation',
            params: requestData
        })
            .then(response => dispatch(taxesInformationRequestSuccess(response.data)))
            .catch(response => {
                if (!!response.error) {
                    dispatch(taxesInformationRequestError(response.error));
                } else {
                    throw response;
                }
            });
    } else {
        return Promise.resolve();
    }
}

module.exports = {
    taxesInformationRequest
};
