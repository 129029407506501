// @utilities
const { APIGet } = require("utilities/APIRequestHelper");
const storage = require('utilities/storage');

// @constants
const actionTypes = require("constants/actionTypes");
const {
    TRANSLATION_VERSION_STORAGE_LABEL,
    TRANSLATIONS_STORAGE_LABEL
} = require('constants/translations');

const requestTranslationsStart = (platform, language) => ({
    type: actionTypes.TRANSLATIONS_FETCH_REQUEST,
    payload: {
      platform,
      language
    }
});

const requestTranslationsSuccess = (platform, translations, language) => ({
    type: actionTypes.TRANSLATIONS_REQUEST_SUCCESS,
    payload: {
        platform,
        translations,
        language
    }
});

const requestTranslationsFailure = (platform, language, error) => ({
    type: actionTypes.TRANSLATIONS_REQUEST_FAILURE,
    platform,
    language,
    error
});


// Request of webapp texts
const fetchTranslationsContent = (platform, language, url) => (dispatch) => {
    // localStorage labels
    const translationVersionlabel = platform + TRANSLATION_VERSION_STORAGE_LABEL;
    const translationsLabel = platform + TRANSLATIONS_STORAGE_LABEL;
    // Remove old and update the translation version
    storage.indexedDatabaseStorage.remove(translationVersionlabel)
    storage.indexedDatabaseStorage.set(translationVersionlabel, url)

     // Request of translations with received url
    APIGet(dispatch, url, {header: {responseType: 'json'}})
        .then(
            response => {
                // Parse translations for different response
                const jsonParsed = (response && response.body)
                    || (response && response.text && JSON.parse(response.text))
                    || response
                    || {}

                // Received translations
                const translations = { [language]: jsonParsed };

                // Remove old translations and store new ones in localStorage
           
                storage.indexedDatabaseStorage.remove(translationsLabel)
                storage.indexedDatabaseStorage.set(translationsLabel, JSON.stringify(translations))

                // Update translations reducer with reponse
                dispatch(requestTranslationsSuccess(platform, translations, language));
            },
            response => {
                const error = !! response.error ? response.error : response;
                // NOTE: Disabled to avoid acces to private section without translations
                dispatch(requestTranslationsFailure(platform, language, error));
            }
        )
        .catch(error => dispatch(requestTranslationsFailure(platform, language, error)));
}

// Request of the version of webapp texts
const requestTranslations = (platform, language) => (dispatch, getState) => {
    // Continue if the translations have not yet been requested
    const isFetchingFlag = `isFetching${platform}${language}`;
    const isAlreadyFetching = getState().translations.get(isFetchingFlag);
    const hasBeenFetchedFlag = `hasBeenFetched${platform}${language}`;
    const hasBeenFetched = getState().translations.get(hasBeenFetchedFlag);

    if (!isAlreadyFetching && !hasBeenFetched) {
        // We start searching for the language strings
        dispatch(requestTranslationsStart(platform, language));
        // localStorage labels
        const translationVersionlabel = platform + TRANSLATION_VERSION_STORAGE_LABEL;
        const translationsLabel = platform + TRANSLATIONS_STORAGE_LABEL;
        // Request of the source URL for translations
        APIGet({
            dispatch,
            endpoint: 'getTranslationsURL',
            params: {
                platform,
                language
            }
        })
            .then(async(response) => {
                if (response.url) {
                    // If the translationVersion stored is the same we stop the fetch
                    const translationVersion = await storage.indexedDatabaseStorage.get(translationVersionlabel);
                    // Get stored translations
                    const translations = await storage.indexedDatabaseStorage.get(translationsLabel);

                    if (translationVersion === response.url && translations) {
                        storage.indexedDatabaseStorage.set(translationVersionlabel, response.url)
                        // Update translations reducer with reponse
                        dispatch(requestTranslationsSuccess(platform, JSON.parse(translations), language));
                    } else {
                        dispatch(fetchTranslationsContent(platform, language, response.url));
                    }
                } else {
                    dispatch(requestTranslationsFailure(platform, language, response));
                }
            })
            .catch(error => dispatch(requestTranslationsFailure(platform, language, error)));
    }
};


module.exports = {
    requestTranslations,
    requestTranslationsSuccess,
    requestTranslationsFailure
};
