const actionTypes = require('constants/actionTypes');
const uniqueId = require('lodash/utility/uniqueId');

function voice(voice) {
    return {
        type: actionTypes.VOICE_PERFORM_READ,
        voiceReader: voice,
        id: uniqueId('voice-reder-')
    };
}
function cleanReader() {
    return {
        type: actionTypes.VOICE_PERFORM_CLEAN,
        id: uniqueId('voice-reder-clean-')
    };
}

module.exports = {
    voice,
    cleanReader
};

