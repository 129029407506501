const { APIGet } = require('utilities/APIRequestHelper');
import {
    BROKER_INIT_LEGAL_DOCUMENTATION,
    BROKER_REMOVE_LEGAL_DOCUMENTATION,
    BROKER_SET_LEGAL_DOCUMENTATION_CHECKED,
    BROKER_SET_LEGAL_DOCUMENTATION_CHECKED_SUCCESS,
    BROKER_SET_LEGAL_DOCUMENTATION_CHECKED_FAILURE,
    BROKER_SET_LEGAL_DOCUMENTATION_VALIDATE_FETCHING
} from 'constants/actionTypes';
const {
    BROKER_LEGAL_ALT_DOCUMENTATION_ECONOMIC_REPORT,
    BROKER_LEGAL_ALT_DOCUMENTATION_PROSPECUTS,
    BROKER_LEGAL_ALT_DOCUMENTATION_RECOMMENDATION_REPORT,
    BROKER_LEGAL_ALT_DOCUMENTATION_RISK_ASSUMPTION,
    BROKER_LEGAL_ALT_DOCUMENTATION_DFI,
    BROKER_LEGAL_DOCUMENTATION_DFI,
    BROKER_LEGAL_DOCUMENTATION_ECONOMIC_REPORT,
    BROKER_LEGAL_DOCUMENTATION_MARKETING_REPORT,
    BROKER_LEGAL_DOCUMENTATION_TRANSACTION_COST,
    BROKER_PLAN_LEGAL_DOCUMENT_DDFP,
    BROKER_PLAN_LEGAL_DOCUMENT_SPECIFICATIONS,
    BROKER_PLAN_LEGAL_DOCUMENT_BULLETIN,
    BROKER_PLAN_LEGAL_DOCUMENT_SERVICE_PROVISION,
    BROKER_OPA_LEGAL_DOCUMENTATION_OFFICIAL_COMMUNICATION,
    BROKER_OPA_LEGAL_DOCUMENTATION_LEAFLET_SUSBCRIPTION,
    BROKER_CAPITAL_INCREASE_LEGAL_DOCUMENTATION_LEAFLET_SUSBCRIPTION,
    BROKER_CAPITAL_INCREASE_LEGAL_DOCUMENTATION_OFFICIAL_COMMUNICATION,
    BROKER_OPV_LEGAL_DOCUMENTATION_OFFICIAL_COMMUNICATION,
    BROKER_OPV_LEGAL_DOCUMENTATION_LEAFLET_SUSBCRIPTION,
    BROKER_EXCHANGE_LEGAL_DOCUMENTATION_OFFICIAL_COMMUNICATION,
    BROKER_EXCHANGE_LEGAL_DOCUMENTATION_LEAFLET_SUSBCRIPTION,
    BROKER_LEGAL_DOCUMENTATION_ANNUAL_REPORT,
    BROKER_LEGAL_DOCUMENTATION_PROSPECTUS_COST,
    LEGAL_DOCUMENTS_TYPES
} = require('constants/index');
const {
    getFundsDocuments,
    getPlansDocuments,
    getWarrantsDocuments
} = require('utilities/brokerLegalDocumentation/legalDocumentation')
import { showMemories } from 'commonsUtilities/showMemoriesHelper';
const errorActions = require('actions/brokerError');

// Utilities
import { getUrlDocument } from "utilities/filesHelper";

const initLegalDocumentation = (uuid, product, validDocuments) => ({
    type: BROKER_INIT_LEGAL_DOCUMENTATION,
    product,
    uuid,
    validDocuments
})

const getInitFundsDocumentation = (uuid, isin, originCountry, enableTransactionCost, isBuildYourPortfolio, enableEconomicReport, isAlternativeFund) => (dispatch, getState) => {
  const { flagBasedFeatures } = getState();
  const showPurchaseAnnualReport = flagBasedFeatures.get(
    'wealth_funds_show_transfer_annual_report_document'
  );
  const showPurchaseProspectusReport = flagBasedFeatures.get(
    'wealth_funds_show_transfer_prospectus_document'
  );

  const showRiskAssumptionDocument = flagBasedFeatures.get(
    'wealth_funds_show_risk_assumption_document'
  );
  const showRecomendationReport = flagBasedFeatures.get(
    'wealth_funds_show_recommendation_report_document'
  );

  let INITIAL_LEGAL_DOCUMENT_STATE = {};

  if (isAlternativeFund) {
    if (showRecomendationReport) {
      INITIAL_LEGAL_DOCUMENT_STATE[BROKER_LEGAL_ALT_DOCUMENTATION_RECOMMENDATION_REPORT] = false;
    }
    if (showRiskAssumptionDocument) {
      INITIAL_LEGAL_DOCUMENT_STATE[BROKER_LEGAL_ALT_DOCUMENTATION_RISK_ASSUMPTION] = false;
    }

    INITIAL_LEGAL_DOCUMENT_STATE[BROKER_LEGAL_ALT_DOCUMENTATION_DFI] = false;

    INITIAL_LEGAL_DOCUMENT_STATE[BROKER_LEGAL_ALT_DOCUMENTATION_PROSPECUTS] = false;

    if (showPurchaseAnnualReport) {
      INITIAL_LEGAL_DOCUMENT_STATE[BROKER_LEGAL_ALT_DOCUMENTATION_ECONOMIC_REPORT] = false;
    }
  } else {
    INITIAL_LEGAL_DOCUMENT_STATE = {
      ...INITIAL_LEGAL_DOCUMENT_STATE,
      [BROKER_LEGAL_DOCUMENTATION_DFI]: false,
    };

    INITIAL_LEGAL_DOCUMENT_STATE = {
      ...INITIAL_LEGAL_DOCUMENT_STATE,
      [BROKER_LEGAL_DOCUMENTATION_MARKETING_REPORT]: false,
    };

    if (enableEconomicReport) {
      INITIAL_LEGAL_DOCUMENT_STATE = {
        ...INITIAL_LEGAL_DOCUMENT_STATE,
        [BROKER_LEGAL_DOCUMENTATION_ECONOMIC_REPORT]: false,
      };
    }

    if (enableTransactionCost || isBuildYourPortfolio) {
      INITIAL_LEGAL_DOCUMENT_STATE = {
        ...INITIAL_LEGAL_DOCUMENT_STATE,
        [BROKER_LEGAL_DOCUMENTATION_TRANSACTION_COST]: false,
      };
    }

    if (showPurchaseAnnualReport) {
      INITIAL_LEGAL_DOCUMENT_STATE = {
        ...INITIAL_LEGAL_DOCUMENT_STATE,
        [BROKER_LEGAL_DOCUMENTATION_ANNUAL_REPORT]: false,
      };
    }

    if (showPurchaseProspectusReport) {
      INITIAL_LEGAL_DOCUMENT_STATE = {
        ...INITIAL_LEGAL_DOCUMENT_STATE,
        [BROKER_LEGAL_DOCUMENTATION_PROSPECTUS_COST]: false,
      };
    }
  }

  const validDocuments = {};
  const { globalVariables, brokerFundLegalDocument } = getState();
  const fundsDocuments = getFundsDocuments(
    isin,
    globalVariables,
    brokerFundLegalDocument,
    originCountry,
    isBuildYourPortfolio
  );
  const alternativeFundsDocs = brokerFundLegalDocument.get('alternativeFundsDocs');
  const { PROSPECTUS, INFORME_SEMESTRAL, DFI, RISKS, RECOMMENDATIONS } = LEGAL_DOCUMENTS_TYPES;

  Object.keys(INITIAL_LEGAL_DOCUMENT_STATE).map(type => {
    if (alternativeFundsDocs.size) {
      switch (type) {
        case BROKER_LEGAL_ALT_DOCUMENTATION_RECOMMENDATION_REPORT:
          validDocuments[type] = {};
          validDocuments[type]['read'] = false;
          validDocuments[type]['base64Data'] = {
            base64String: alternativeFundsDocs.getIn([RECOMMENDATIONS, 'urlToOpen']),
          };
          validDocuments[type]['isFetching'] = false;
          break;
        case BROKER_LEGAL_ALT_DOCUMENTATION_RISK_ASSUMPTION:
          validDocuments[type] = {};
          validDocuments[type]['read'] = false;
          validDocuments[type]['base64Data'] = {
            base64String: alternativeFundsDocs.getIn([RISKS, 'urlToOpen']),
          };
          validDocuments[type]['isFetching'] = false;
          break;

        case BROKER_LEGAL_ALT_DOCUMENTATION_DFI:
          validDocuments[type] = {};
          validDocuments[type]['read'] = false;
          validDocuments[type]['url'] = alternativeFundsDocs.getIn([DFI, 'urlToOpen']);
          validDocuments[type]['isFetching'] = false;
          validDocuments[type]['fromBucket'] = true;
          break;

        case BROKER_LEGAL_ALT_DOCUMENTATION_PROSPECUTS:
          validDocuments[type] = {};
          validDocuments[type]['read'] = false;
          validDocuments[type]['url'] = alternativeFundsDocs.getIn([PROSPECTUS, 'urlToOpen']);
          validDocuments[type]['isFetching'] = false;
          validDocuments[type]['fromBucket'] = true;
          break;

        case BROKER_LEGAL_ALT_DOCUMENTATION_ECONOMIC_REPORT:
          validDocuments[type] = {};
          validDocuments[type]['read'] = false;
          validDocuments[type]['url'] = alternativeFundsDocs.getIn([
            INFORME_SEMESTRAL,
            'urlToOpen',
          ]);
          validDocuments[type]['isFetching'] = false;
          validDocuments[type]['fromBucket'] = true;
          break;
        default:
          validDocuments[type] = {};
          validDocuments[type]['read'] = false;
          validDocuments[type]['url'] = fundsDocuments[type]['url'];
          validDocuments[type]['isFetching'] = false;
          break;
      }
    } else {
      switch (type) {
        case BROKER_LEGAL_DOCUMENTATION_MARKETING_REPORT:
          // Specials conditionals by countries PT & ES
          if (showMemories(originCountry, isin)) {
            validDocuments[type] = {};
            validDocuments[type]['read'] = false;
            validDocuments[type]['url'] = fundsDocuments[type]['url'];
            validDocuments[type]['isFetching'] = false;
          }
          break;
        case BROKER_LEGAL_DOCUMENTATION_TRANSACTION_COST:
          validDocuments[type] = {};
          validDocuments[type]['read'] = false;
          validDocuments[type]['pdf'] = fundsDocuments[type]['pdf'];
          validDocuments[type]['isFetching'] = false;
          break;
        case BROKER_LEGAL_DOCUMENTATION_ANNUAL_REPORT:
          validDocuments[type] = {};
          validDocuments[type]['read'] = false;
          validDocuments[type]['url'] = getUrlDocument(isin, 'Anual', originCountry);
          validDocuments[type]['isFetching'] = false;
          break;
        case BROKER_LEGAL_DOCUMENTATION_PROSPECTUS_COST:
          validDocuments[type] = {};
          validDocuments[type]['read'] = false;
          validDocuments[type]['url'] = getUrlDocument(isin, 'Prospectus', originCountry);
          validDocuments[type]['isFetching'] = false;
          break;
        default:
          validDocuments[type] = {};
          validDocuments[type]['read'] = false;
          validDocuments[type]['url'] = fundsDocuments[type]['url'];
          validDocuments[type]['isFetching'] = false;
          break;
      }
    }
  });
  dispatch(initLegalDocumentation(uuid, isin, validDocuments));
}

const getInitPlansDocumentation = (uuid, planDgsCode, isAportation) => (dispatch, getState) => {
    const INITIAL_LEGAL_DOCUMENT_STATE = {
        [BROKER_PLAN_LEGAL_DOCUMENT_DDFP]: false,
        [BROKER_PLAN_LEGAL_DOCUMENT_SPECIFICATIONS]: false,
        ...(!isAportation ? {
            [BROKER_PLAN_LEGAL_DOCUMENT_BULLETIN]: false,
            [BROKER_PLAN_LEGAL_DOCUMENT_SERVICE_PROVISION]: false
        } : {})
    };

    const validDocuments = {}
    const plansDocuments = getPlansDocuments(planDgsCode, getState().profile.get('originCountry'))
    Object.keys(INITIAL_LEGAL_DOCUMENT_STATE).map((type) => {
        validDocuments[type] = {}
        validDocuments[type]['read'] = false
        validDocuments[type]['url'] = plansDocuments[type]['url']
        validDocuments[type]['isFetching'] = false;
    })

    dispatch(initLegalDocumentation(uuid, planDgsCode, validDocuments))
}

const getInitOPADocumentation = (uuid, isin) => (dispatch, getState) => {
    const { brokerStocksSaleOrder } = getState()

    const validDocuments = {
        [BROKER_OPA_LEGAL_DOCUMENTATION_OFFICIAL_COMMUNICATION]: {
            read: false,
            url: brokerStocksSaleOrder.getIn(['operation', 'linkCNMV'])
        },
        [BROKER_OPA_LEGAL_DOCUMENTATION_LEAFLET_SUSBCRIPTION]: {
            read: false,
            url: brokerStocksSaleOrder.getIn(['operation', 'linkTriptico'])
        }
    };

    dispatch(initLegalDocumentation(uuid, isin, validDocuments))
}

const getInitCapitalIncreaseDocumentation = (uuid, isin) => (dispatch, getState) => {
    const {  brokerCorporatesOperations } = getState();
    const immOperations = brokerCorporatesOperations.getIn(['selectedContract', 'operations']);
    const operation = immOperations.find(immOperation => {
        return immOperation.get('isin') === isin;
    });
    const linkTriptico = operation.get('linkTriptico');
    const linkCNMV = operation.get('linkCNMV');

    const validDocuments = {
        [BROKER_CAPITAL_INCREASE_LEGAL_DOCUMENTATION_LEAFLET_SUSBCRIPTION]: {
            read: false,
            url: linkTriptico
        },
        [BROKER_CAPITAL_INCREASE_LEGAL_DOCUMENTATION_OFFICIAL_COMMUNICATION]: {
            read: false,
            url: linkCNMV
        }
    };

    dispatch(initLegalDocumentation(uuid, isin, validDocuments))
}

const getInitOPVDocumentation = (uuid, isin) => (dispatch, getState) => {
    const { brokerStocksPurchaseOrder } = getState()
    const financialOperation = brokerStocksPurchaseOrder.getIn(['operation', 'linkCNMV']);
    const triptico = brokerStocksPurchaseOrder.getIn(['operation', 'linkTriptico']);

    const validDocuments = {
        [BROKER_OPV_LEGAL_DOCUMENTATION_OFFICIAL_COMMUNICATION]: {
            read: false,
            url: financialOperation
        },
        [BROKER_OPV_LEGAL_DOCUMENTATION_LEAFLET_SUSBCRIPTION]: {
            read: false,
            url: triptico
        }
    };

    dispatch(initLegalDocumentation(uuid, isin, validDocuments))
}

const getInitExchangeDocumentation = (uuid, isin) => (dispatch, getState) => {
    const { brokerRequestFinancialOperationChange } = getState()
    const financialOperation = brokerRequestFinancialOperationChange.getIn(['valor', 'linkCNMV']);
    const triptico = brokerRequestFinancialOperationChange.getIn(['valor', 'linkTriptico']);

    const validDocuments = {
        [BROKER_EXCHANGE_LEGAL_DOCUMENTATION_OFFICIAL_COMMUNICATION]: {
            read: false,
            url: financialOperation
        },
        [BROKER_EXCHANGE_LEGAL_DOCUMENTATION_LEAFLET_SUSBCRIPTION]: {
            read: false,
            url: triptico
        }
    };

    dispatch(initLegalDocumentation(uuid, isin, validDocuments))
}

const getInitWarrantsDocumentation = (uuid, isin, isSell, enableTransactionCost=true) => (dispatch, getState) => {
    const immBrokerWarrantsBuy = getState().brokerWarrantsBuy
    const immBrokerWarrantsSell = getState().brokerWarrantsSell
    const market = getState().brokerProductFile.getIn(['warrant', 'micCode']);
    const isMedysifProvider = getState().flagBasedFeatures.get('wealth_medysif_warrant_provider');
    const validDocuments = getWarrantsDocuments(
      isin,
      immBrokerWarrantsBuy,
      immBrokerWarrantsSell,
      isSell,
      enableTransactionCost,
      market,
      isMedysifProvider
    );
    dispatch(initLegalDocumentation(uuid, isin, validDocuments))
}

const getInitETFDFI = (uuid, isin) => (dispatch, getState) => {
    const dfiURL = getState().brokerETFBuy.get('dfiUrl');
    const validDocuments = {
        ['DFI']: {
            read: false,
            url: dfiURL
        },
    }
    dispatch(initLegalDocumentation(uuid, isin, validDocuments))
}

const removeLegalDocumentation = () => ({
    type: BROKER_REMOVE_LEGAL_DOCUMENTATION,
});

const setLegalDocumentationCheckedFailure = (data) => dispatch => {
    const { documentType, product, productDefinition, productType, uuid, marker } = data;
    dispatch({
        type: BROKER_SET_LEGAL_DOCUMENTATION_CHECKED_FAILURE,
        documentType,
        product,
        productDefinition,
        productType,
        uuid,
        marker,
    })
}

const setFetchingValidateDocument = (data) => ({
    type: BROKER_SET_LEGAL_DOCUMENTATION_VALIDATE_FETCHING,
    ...data
});

const confirmDocumentCheck = data => dispatch => {
    const { documentType, product, productDefinition, productType, uuid, marker } = data;
    dispatch({
        type: BROKER_SET_LEGAL_DOCUMENTATION_CHECKED,
        documentType,
        product,
        productDefinition,
        productType,
        uuid,
        marker,
    })
}

const setLegalDocumentationChecked = (data, history, openFromBucket) => (dispatch) => {
    const { documentType, product, link } = data;
    dispatch(setFetchingValidateDocument({documentType, product}))
    if (!link.base64String && !openFromBucket){
        APIGet(dispatch, link, {})
            .then(() => {
                dispatch(confirmDocumentCheck(data));
                window.open(link, '_blank');
            }).catch(() => {
                dispatch(setLegalDocumentationCheckedFailure(data))
                dispatch(errorActions.show("", true, history));
            })
    } else if (openFromBucket) {
        window.open(link, '_blank');
        dispatch(confirmDocumentCheck(data));

    } else {
        dispatch(confirmDocumentCheck(data));
    }
};

const setLegalDocumentationCheckedSuccess = (documentType, product) => (dispatch, getState) => {
    const documents = getState().brokerLegalDocumentation.getIn(['boxes', product, 'documents'])
    if(documents){
        const incompleted = documents.some((value, type) => {
            if(type == documentType) return false
            return value.get('read') === false
        })
        dispatch({
            type: BROKER_SET_LEGAL_DOCUMENTATION_CHECKED_SUCCESS,
            documentType,
            product,
            incompleted
        })
    }

}

export {
    getInitFundsDocumentation,
    removeLegalDocumentation,
    setLegalDocumentationChecked,
    setLegalDocumentationCheckedSuccess,
    setLegalDocumentationCheckedFailure,
    getInitPlansDocumentation,
    getInitOPADocumentation,
    getInitCapitalIncreaseDocumentation,
    getInitOPVDocumentation,
    getInitExchangeDocumentation,
    getInitWarrantsDocumentation,
    getInitETFDFI
}
