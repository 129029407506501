const actionTypes = require('constants/actionTypes');
const apiURLBuilder = require('core/apiURLBuilder');
const { APIGet } = require('utilities/APIRequestHelper');
const get = require('lodash/object/get');

const pregrantedCreditsRequest = () => ({
    type: actionTypes.CREDITS_PREGRANTED_REQUEST
});

const pregrantedCreditsSuccess = (data) => ({
    type: actionTypes.CREDITS_PREGRANTED_SUCCESS,
    payload: {
        list: get(data, 'data', [])
    }
});

const pregrantedCreditsFailure = (error) => ({
    type: actionTypes.CREDITS_PREGRANTED_REQUEST,
    payload: {
        error
    }
});

const pregrantedCreditDetailsRequest = (accountId) => ({
    type: actionTypes.CREDIT_PREGRANTED_DETAILS_REQUEST,
    payload: {
        accountId
    }
});

const pregrantedCreditDetailsSuccess = (data, accountId) => ({
    type: actionTypes.CREDIT_PREGRANTED_DETAILS_SUCCESS,
    payload: {
        accountId,
        details: get(data, 'listaCreditos.credito', []),
        hour: data.hora,
        originalDate: data.fecha,
        effectiveDate: get(data, 'repos.timeStamRepos')
    }
});

const pregrantedCreditDetailsFailure = (error, accountId) => ({
    type: actionTypes.CREDIT_PREGRANTED_DETAILS_REQUEST,
    payload: {
        accountId,
        error
    }
});

function getPregrantedCredits(dispatch, immAccount){
    const requestData = {
        query: {
            cuentaSiONoPreconcedido: 'S',
            producto: immAccount.get('product'),
            numeroContrato: immAccount.get('contractNumber')
        }
    };

    dispatch(pregrantedCreditsRequest());
    return APIGet(
        dispatch,
        apiURLBuilder.getURL('pregrantedCredits'),
        requestData)
    .then( response => dispatch(pregrantedCreditsSuccess(response)))
    .catch( response => dispatch(pregrantedCreditsFailure(response.error.description)))
}

function getPregrantedCreditDetails(dispatch, immAccount, accountId){
    const requestData = {
        query: {
            producto: immAccount.get('product'),
            numeroContrato: immAccount.get('contractNumber')
        }
    };

    dispatch(pregrantedCreditDetailsRequest(accountId));
    return APIGet(
        dispatch,
        apiURLBuilder.getURL('pregrantedCreditDetails'),
        requestData)
    .then( response => dispatch(pregrantedCreditDetailsSuccess(response, accountId)))
    .catch( response => dispatch(pregrantedCreditDetailsFailure(response.error.description)))
}

const fetchPregantedCredits = () => (dispatch, getState) => {
    const pregrantedCreditsCount = getState().creditsPregranted.get('byOrder').size;
    if (pregrantedCreditsCount > 0) {
        //call with the first account we found to get the list
        const accountId = getState().creditsPregranted.getIn(['byOrder', 0]);
        const immAccount = getState().creditsPregranted.getIn(['byId', accountId]);
        getPregrantedCredits(dispatch, immAccount);
    }
}

const fetchPregantedCreditDetails = (accountId) => (dispatch, getState) => {
    const immAccount = getState().creditsPregranted.getIn(['byId', accountId]);
    return getPregrantedCreditDetails(dispatch, immAccount, accountId);
}

module.exports = {
    fetchPregantedCredits,
    fetchPregantedCreditDetails
}
