const actionTypes = require('../constants/actionTypes');

function boxToggle(expanded, id, type){
    return {
        type: actionTypes.LANDING_BOX_TOGGLE,
        payload: {
            expanded,
            id,
            type
        }
    };
}

module.exports = {
    boxToggle
}
