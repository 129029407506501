// @vendors
import lodash from 'lodash';
import trim from 'lodash/string/trim';

// @api
import { APIGet, APIPost } from 'utilities/APIRequestHelper';
import apiURLBuilder from 'core/apiURLBuilder';

// @actions
import signaturePatternActions from 'actions/signaturePattern';

// @utils
import { getFamily } from 'utilities/rightsProfileHelper';
import PersonalDataHelper from 'utilities/personalDataHelper';

// @actions
import notification from 'actions/notifications';

// @constants
import actionTypes from 'constants/actionTypes';
import {
    CARD_ECARD,
    CARD_CREDIT,
    CARD_DEBIT,
    MY_PROFILE_ACCOUNT,
    WALLETS,
    FUNDS,
    PLANS,
    MORTGAGES,
    LOANS,
    EMAIL_REGEX,
    ACCESS
} from 'constants/index';

const showNotificationEmailKeyInProgress = () => ({
    type: actionTypes.SHOW_NOTIFICATIONS_EMAIL_IN_PROGRESS
});

const showNotificationEmailKeySuccess = () => ({
    type: actionTypes.SHOW_NOTIFICATIONS_EMAIL_SUCCESS
});

// const showNotificationEmailKeyError = () => ({
//     type: actionTypes.SHOW_NOTIFICATIONS_EMAIL_ERROR
// });

const nextStepAccess = () => ({
    type: actionTypes.SHOW_NEXT_STEP_RIGHTS_ACCESS
});

const lastStepAccess = () => ({
    type: actionTypes.SHOW_LAST_STEP_RIGHTS_ACCESS
});

const nextStepPortable = () => ({
    type: actionTypes.SHOW_NEXT_STEP_RIGHTS_PORTABLE
});

const lastStepPortable = () => ({
    type: actionTypes.SHOW_LAST_STEP_RIGHTS_PORTABLE
});

const resetRightsProfile = () => ({
    type: actionTypes.RESET_RIGHTS_PROFILE
});

const postConsentsInProgress = () =>({
    type: actionTypes.POST_REPORTS_IN_PROGRESS
});

const postConsentsSuccess = () =>({
    type: actionTypes.POST_REPORTS_SUCCESS
});

const postConsentsFailure = error =>({
    type: actionTypes.POST_REPORTS_FAILURE,
    payload: { error }
});

const postConsentsAccessSuccess = () =>({
    type: actionTypes.POST_ACCESS_REPORTS_SUCCESS
});

const postConsentsAccessFailure = error =>({
    type: actionTypes.POST_ACCESS_REPORTS_FAILURE,
    payload: { error }
});

const getConsentsInProgress = () =>({
    type: actionTypes.GET_REPORTS_IN_PROGRESS
});

const getConsentsSuccess = response =>({
    type: actionTypes.GET_REPORTS_SUCCESS,
    payload: { response }
});

const getConsentsFailure = error =>({
    type: actionTypes.GET_REPORTS_FAILURE,
    payload: { error }
});

const getMovements = response =>({
    type: actionTypes.CHANGE_GET_MOVEMENTS_PRODUCTS,
    payload: { response }
});

const updateList = (type, list) =>({
    type: actionTypes.UPDATE_TYPE_RIGHTS_LIST,
    payload: {
        list,
        type
    }
});

const updateRightsProfile = response =>({
    type: actionTypes.UPDATE_RIGHTS_PROFILE,
    payload: { response }
});

const base64ToArrayBuffer = base64 => {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};

const saveByteArray = (reportName, byte) =>{
    var blob = new Blob([byte]);
    var link = document.createElement('a');
    document.body.appendChild(link);
    link.setAttribute("type", "hidden");
    link.href = window.URL.createObjectURL(blob);
    link.download = reportName;
    link.click();
};

const getKeyDownloadRights = (requestId, csv, json, report, type) => dispatch => {
    const requestData = {
        query: {
            requestId: requestId,
            fileGdprZipOriginalCsvPath: csv,
            fileGdprZipOriginalJsonPath: json,
            fileGdprReport: report,
            reportType: type
        }
    };

    APIPost(dispatch, apiURLBuilder.getURL('recoveryGdprRights'), requestData)
        .then(() => dispatch(notification.success('Descargado', {
            voice: 'Error'
        })))
        .catch(() => dispatch(notification.error('Error', {
            voice: 'Error'
        })));
};

const downloadFile = (fileRights) => (dispatch) => {
    const idFile = fileRights;
    const requestData = {
        query: {
            objectId: idFile
        }
    };

    APIGet(dispatch, apiURLBuilder.getURL('content'), requestData)
        .then(response => {
            const sampleArr = base64ToArrayBuffer(response.content);
            saveByteArray(idFile, sampleArr);
        })
        .catch(() => {});
};

const getRights = (type) => (dispatch, getState) => {
    const isFetching = getState().rightsProfile.get('isFetching')

    if (!isFetching) {
        const requestData = {
            query: {
                types: type
            }
        };

        dispatch(getConsentsInProgress());

        APIGet(dispatch, apiURLBuilder.getURL('reportsAll'), requestData)
            .then( response => dispatch(getConsentsSuccess(response)))
            .catch(error => dispatch(getConsentsFailure(error)));
    }
};

const buildGenericNumContact = product =>
    `${product.getIn(['contrato', 'producto'])}${product.getIn(['contrato', 'numerodecontrato'])}`

const buildCardNumContact = product =>
    `${product.getIn(['contract', 'product'])}${product.getIn(['contract', 'number'])}`

const buildAccountLoanMortgageNumContact = product =>
    `${product.get('product')}${product.get('contractNumber')}`

const getNumContact = (type, product) => {
    switch (type) {
        case WALLETS:
        case FUNDS:
        case PLANS:
            return buildGenericNumContact(product);
        case CARD_ECARD:
        case CARD_DEBIT:
        case CARD_CREDIT:
            return buildCardNumContact(product);
        case MY_PROFILE_ACCOUNT:
        case LOANS:
        case MORTGAGES:
            return buildAccountLoanMortgageNumContact(product);
    }
};

const getProductList = (type, state) => {
    switch (type) {
        case WALLETS:
            return state.brokerWallets.get('walletList').toIndexedSeq()
        case FUNDS:
            return state.brokerWallets.get('fundsList')
        case PLANS:
            return state.brokerWallets.get('plansList')
        case CARD_ECARD:
        case CARD_DEBIT:
        case CARD_CREDIT: {
            const product = state.cards
            const immCardsList = product.get('byOrder');
            const immCardsById = product.get('byId');

            return immCardsList
                .map((card) => immCardsById.get(card))
                .filter(immCard => immCard.get('type') === type)
        }
        case MY_PROFILE_ACCOUNT: {
            const product = state.accounts
            const immAccountsList = product.get('byOrder');
            const immAccountsById = product.get('byId');

            return immAccountsList
                .map((account) => immAccountsById.get(account))
        }
        case MORTGAGES:
        case LOANS:
            return state.loans.get('byId').toIndexedSeq().filter(lm =>
                lm.get('type') == type && lm.getIn(['balance', 'amount']) !== 0)
    }
};

const buildProduct = (type, product) => ({
    family: getFamily(type),
    movements: true,
    numContract: getNumContact(type, product),
    consentStatus: '00'
});

const buildAllProducts =  (state) => {
    return (
        [WALLETS, FUNDS, PLANS, CARD_CREDIT, CARD_ECARD, CARD_DEBIT, MY_PROFILE_ACCOUNT, LOANS, MORTGAGES]
            .map(itm => getProductList(itm, state).map(product => buildProduct(itm, product)).toJS())
            .reduce((lv, cv) => lv.concat(cv), [])
            .concat([{
                family: '00',
                movements: true,
                numContract: '0000000000',
                consentStatus: '00'
            }])
    );
};

const postRights = (type, thirdEmail) => (dispatch, getState) => {
    const callback = (dispatch, getState) => {
        const state = getState()
        const immProfile = getState().profile;
        const email = trim(PersonalDataHelper.getEmail(immProfile));
        const clientAlias = trim(immProfile.get('nombreCliente'));
        const productContracts = buildAllProducts(state);
        let requestData;
        dispatch(postConsentsInProgress());

        if (type === ACCESS) {
            if (EMAIL_REGEX.test(email)) {
                const newProductContracts = productContracts.concat([{
                    family: '07',
                    movements:true,
                    numContract: '0000000000',
                    consentStatus: '00'
                }])

                requestData = {
                    query: {
                        alias: clientAlias,
                        email: email,
                        free: true,
                        products: newProductContracts,
                        type: type
                    }
                };
                APIPost(dispatch, apiURLBuilder.getURL('reports'), requestData)
                    .then( response => dispatch(postConsentsAccessSuccess(response)))
                    .catch(error => dispatch(postConsentsAccessFailure(error.error.status)));
            } else {
                // 477 is for no email in profile
                dispatch(postConsentsAccessFailure(477));
            }
        } else {
            if (EMAIL_REGEX.test(email)) {
                let productList;
                const showMovements = getState().rightsProfile.get('nextFreeAccess');
                const productsArray = getState().rightsProfile.get('cardEcardList').concat(
                    getState().rightsProfile.get('cardCreditList'),
                    getState().rightsProfile.get('cardDebitList'),
                    getState().rightsProfile.get('accountList'),
                    getState().rightsProfile.get('walletsList'),
                    getState().rightsProfile.get('typeFuturesList'),
                    getState().rightsProfile.get('fundsList'),
                    getState().rightsProfile.get('plansList'),
                    getState().rightsProfile.get('loansList'),
                    getState().rightsProfile.get('savingsList'),
                    getState().rightsProfile.get('mortgagesList'),
                    getState().rightsProfile.get('profileList')
                );
                const convertToJs = getState().rightsProfile.get('cardEcardList').size === 0
                    ?
                    true
                    :
                    false;

                productList = !convertToJs
                    ?
                    lodash.remove(productsArray, n => n.size !== 0)
                    :
                    productsArray.toJS();

                const productContracts = productList.map(product => {
                    return(Array.isArray(product)
                        ?
                        product[0]
                        :
                        product
                    );
                });

                requestData = {
                    query: {
                        alias: clientAlias,
                        email: email,
                        emailThirdParty: thirdEmail,
                        free: showMovements,
                        products: productContracts,
                        type: type
                    }
                };

                APIPost(dispatch, apiURLBuilder.getURL('reports'), requestData)
                    .then(response => dispatch(postConsentsSuccess(response)))
                    .catch(error => dispatch(postConsentsFailure(error.error.status)));
            } else {
                // 477 is for no email in profile
                dispatch(postConsentsFailure(477));
            }
        }

    }

    new Promise(() => {
        signaturePatternActions.validateAndExecute(dispatch, getState, callback);
    });
};

module.exports = {
    // getRights,
    // downloadRights,
    getRights,
    postRights,
    showNotificationEmailKeySuccess,
    showNotificationEmailKeyInProgress,
    nextStepAccess,
    lastStepAccess,
    nextStepPortable,
    lastStepPortable,
    resetRightsProfile,
    updateList,
    getMovements,
    updateRightsProfile,
    downloadFile,
    getKeyDownloadRights
};
