const apiURLBuilder = require('core/apiURLBuilder');
const { APIGet } = require('utilities/APIRequestHelper');
const { formatText } = require('core/i18n').i18n;

//@Actions
const actionTypes = require('constants/actionTypes');

//@ constants
const {
    BROKER_PRODUCT_ADVANCED_SEARCH_TERM,
    BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES,
    ALTERNATIVE_FUNDS_INPUT_VALUE,
    ALTERNATIVE_INVESTMENT,
    BROKER_ACTIVE_CLASS_PRIVATE_EQUITY
} = require('constants/index');
const { TYPE_ALTERNATIVE_FUNDS } = require('constants/flagBasedFeatures');

//@helpper
const { isPrintOfficeMode } = require('utilities/contactCenter/printerHelper');

const getFiltersRequest = () => {
    return {
        type: actionTypes.BROKER_ADVANCED_SEARCH_FILTERS_REQUEST
    };
};

const getFiltersSuccess = (response) => {
    return {
        type: actionTypes.BROKER_ADVANCED_SEARCH_FILTERS_SUCCESS,
        payload: {
            data: response
        }
    };
};

const getFiltersFailure = (response) => {
    return {
        type: actionTypes.BROKER_ADVANCED_SEARCH_FILTERS_FAILURE,
        payload: {
            data: response
        }
    };
};

const getConfigurationFiltersSuccess = (response) => {
    return {
        type: actionTypes.BROKER_ADVANCED_SEARCH_CONFIG_FILTERS_SUCCESS,
        payload: {
            data: response
        }
    };
};

const getConfigurationFiltersFailure = (response) => {
    return {
        type: actionTypes.BROKER_ADVANCED_SEARCH_CONFIG_FILTERS_FAILURE,
        payload: {
            data: response
        }
    };
};

const buildItemsForAssetsOfAlternativeFunds = (response) => {
    const productCategoryValues = response.productCategoryValues && response.productCategoryValues.filter(item => item !== ALTERNATIVE_FUNDS_INPUT_VALUE);
    const assetsValues = response.assetsValues && response.assetsValues.filter(
        item =>
        item.assetClassValue !== 'gestionAlternativa' &
        item.assetClassValue !== 'gestiónAlternativa'
    );
    const assetValuesAlternativeInvestment =
        response.assetsValues &&
        response.assetsValues.findIndex(
        item => item.assetClassValue === ALTERNATIVE_INVESTMENT
        );

    if(assetValuesAlternativeInvestment > 0) {
        const subAssetClassValues =
          response.assetsValues[assetValuesAlternativeInvestment] &&
          response.assetsValues[assetValuesAlternativeInvestment].subAssetClassValues.filter(
            item => item !== BROKER_ACTIVE_CLASS_PRIVATE_EQUITY
          );
        assetsValues[assetValuesAlternativeInvestment].subAssetClassValues = subAssetClassValues;
    }


    const data = {
        ...response,
        productCategoryValues,
        assetsValues
    }
    return data
}

const getFilters = () => (dispatch, getState) => {
    const immFlagBasedFeatures = getState().flagBasedFeatures;
    const header = __CONTACT_CENTER__ ? {
        isOfficeAgent: isPrintOfficeMode() ? 'office' : 'CC'
    } : null;

    const requestData = {
        header: header,
        query: {
            assetsGrouped: true
        }
    };

    dispatch(getFiltersRequest());

    return APIGet(dispatch, apiURLBuilder.getURL('catalogueBrokerSimpleSearchCombos'), requestData)
        .then(response => {
            if(immFlagBasedFeatures.get(TYPE_ALTERNATIVE_FUNDS)) {
                dispatch(getFiltersSuccess(response));
            } else {
                const data = buildItemsForAssetsOfAlternativeFunds(response)
                dispatch(getFiltersSuccess(data));
            }
    }, response => {
            dispatch(getFiltersFailure(response));
        }
    );
};

const applyLimitedFilters = (filterType, limitedFilters) => ({
    type: actionTypes.BROKER_ADVANCED_SEARCH_FILTERS_LIMITED,
    payload: {
        filterType,
        limitedFilters
    }
});

const retryCombosFilters = () => (dispatch) => {
    dispatch(getFilters());
};

const updateFiltersCombosConfiguration = () => (dispatch, getState) => {
    const combos = getState().brokerProductFiltersSearch.get('combos');
    const immFlagBasedFeatures = getState().flagBasedFeatures;

    let combosAdapter = {};
    combos.map((item, key) => {
        combosAdapter[`combos[${key}].name`] = item.name;
        combosAdapter[`combos[${key}].value`] = item.value;
    });

    dispatch(getFiltersRequest());

    const header = __CONTACT_CENTER__ ? {
        isOfficeAgent: isPrintOfficeMode() ? 'office' : 'CC'
    } : null;

    return APIGet(dispatch, apiURLBuilder.getURL('catalogueBrokerSimpleSearchCombosConfiguration'), { query: combosAdapter, header: header })
    .then(response => {
            if(immFlagBasedFeatures.get(TYPE_ALTERNATIVE_FUNDS)) {
                dispatch(getConfigurationFiltersSuccess(response));
            } else {
                const data = buildItemsForAssetsOfAlternativeFunds(response)
                dispatch(getConfigurationFiltersSuccess(data));
            }
        }, response => {
            dispatch(getConfigurationFiltersFailure(response));
        }
    );
};

const clearFilters = () => (dispatch) => {
    dispatch({type: actionTypes.BROKER_ADVANCED_SEARCH_FILTERS_CLEAR});
};

const clearResults = () => (dispatch) => {
    dispatch({type: actionTypes.BROKER_ADVANCED_SEARCH_FILTERS_CLEAR});
    dispatch({type: actionTypes.BROKER_ADVANCED_SEARCH_RESULTS_CLEAR});
};

const handleFieldChange = (field, value) => (dispatch) => {
    dispatch({
        type: actionTypes.BROKER_ADVANCED_SEARCH_FILTER_CHANGE,
        payload: {
            field,
            value
        }
    });
    if(field !== BROKER_PRODUCT_ADVANCED_SEARCH_TERM) {
        dispatch(updateFiltersCombosConfiguration());
    }
};

const handleFieldMultipleChange = (field, value) => (dispatch) => {
    dispatch({
        type: actionTypes.BROKER_ADVANCED_SEARCH_MULTIPLE_PRODUCT_FILTER_CHANGE,
        payload: {
            field,
            value
        }
    });
    dispatch(updateFiltersCombosConfiguration());
};

const handleFieldMultipleAssetChange = (field, value) => (dispatch) => {
    dispatch({
        type: actionTypes.BROKER_ADVANCED_SEARCH_MULTIPLE_ASSET_FILTER_CHANGE,
        payload: {
            field,
            value
        }
    });
    dispatch(updateFiltersCombosConfiguration());
};

const resetSpecificProduct = (product) => (dispatch) => {
    dispatch({type: actionTypes.BROKER_ADVANCED_SEARCH_FILTERS_CLEAR});
    dispatch({type: actionTypes.BROKER_ADVANCED_SEARCH_RESULTS_CLEAR});
    dispatch(
        handleFieldMultipleChange(
            BROKER_PRODUCT_ADVANCED_SEARCH_PRODUCT_TYPES,
            [
                {
                    value: product,
                    label: formatText(`brokerAdvancedSearch-brokerAdvancedSearch_${product}`)
                }
            ]
        )
    );
};

module.exports = {
    applyLimitedFilters,
    getFilters,
    handleFieldChange,
    handleFieldMultipleChange,
    handleFieldMultipleAssetChange,
    clearFilters,
    clearResults,
    updateFiltersCombosConfiguration,
    retryCombosFilters,
    resetSpecificProduct
};
