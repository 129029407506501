// @ vendors
const { Promise } = require('es6-promise');
const { requestConditions } = require('actions/mortgageRequest/mortgageRequestConditions');
// @ utils
const apiURLBuilder = require('core/apiURLBuilder');
const { APIGet, APIPost } = require('utilities/APIRequestHelper');
const { scrollToTop } = require('commonsUtilities/scrollUtils');

// @ constants
const MORTGAGE_PANEL = '/myprofile/mortgage/panel';
const { MORTGAGE_CANCEL_OPTION } = require('constants/flagBasedFeatures');
const {
    MORTGAGE_REQUEST_INSURANCE_REQUEST_RELATED_STATUS_DATA_REQUEST,
    MORTGAGE_REQUEST_INSURANCE_REQUEST_RELATED_STATUS_DATA_SUCCESS,
    MORTGAGE_REQUEST_INSURANCE_REQUEST_RELATED_STATUS_DATA_FAILURE,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_REQUEST,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_SUCCESS,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_FAILURE,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_IN_PROGRESS,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_SUCCESS,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_FAILURE,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_REQUEST,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_SUCCESS,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_FAILURE,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_REQUEST,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_SUCCESS,
    MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_FAILURE
} = require('constants/actionTypes');

const mortgageRequestConditionsRequest = () => ({
    type: MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_REQUEST
});

const mortgageRequestConditionsSuccess = data => ({
    type: MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_SUCCESS,
    data
});

const mortgageRequestConditionsFailure = error => ({
    type: MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_FAILURE,
    error
});

const mortgageRequestInsurancePricingRequest = () => ({
    type: MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_IN_PROGRESS
});

const mortgageRequestInsurancePricingSuccess = data => ({
    type: MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_SUCCESS,
    data
});

const mortgageRequestInsurancesPricingFailure = error => ({
    type: MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_PRICING_FAILURE,
    error
});

const mortgageRequestInsuranceRelatedStatusRequest = () => ({
    type: MORTGAGE_REQUEST_INSURANCE_REQUEST_RELATED_STATUS_DATA_REQUEST
});

const mortgageRequestInsuranceRelatedStatusSuccess = data => ({
    type: MORTGAGE_REQUEST_INSURANCE_REQUEST_RELATED_STATUS_DATA_SUCCESS,
    data
});

const mortgageRequestInsuranceRelatedStatusFailure = error => ({
    type: MORTGAGE_REQUEST_INSURANCE_REQUEST_RELATED_STATUS_DATA_FAILURE,
    error
});

const requestInsuranceRelatedConfigInProgress = () => ({
    type: MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_REQUEST
});

const requestInsuranceRelatedConfigSuccess = data => ({
    type: MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_SUCCESS,
    data
});

const requestInsuranceRelatedConfigFailure = error => ({
    type: MORTGAGE_REQUEST_MANAGER_INSURANCES_RELATED_CONFIG_FAILURE,
    data: { error }
});

const mortgageRequestInsurancesLinkRequest = () => ({
    type: MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_REQUEST
});

const mortgageRequestInsurancesLinkSuccess = data => ({
    type: MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_SUCCESS,
    data
});

const mortgageRequestInsurancesLinkFailure = error => ({
    type: MORTGAGE_REQUEST_MANAGER_INSURANCES_LINK_RELATED_FAILURE,
    data: { error }
});


const mortgageRequestInsurancesConditionsData = () => dispatch => {
    dispatch(mortgageRequestConditionsRequest());

    return APIGet({
        dispatch,
        endpoint: 'mortgageRequestConditions'
    })
        .then(response => dispatch(mortgageRequestConditionsSuccess(response.data)))
        .catch(response => {
            if (!!response.error) {
                dispatch(mortgageRequestConditionsFailure(response.error));
            } else {
                throw response;
            }
        });
};

const mortgageRequestInsurancesRelatedStatusData = insuranceId => dispatch => {
    dispatch(mortgageRequestInsuranceRelatedStatusRequest());

    return APIGet(
        dispatch,
        apiURLBuilder.getURL('insuranceRequestRelatedExpedient', { id: insuranceId })
    )
        .then(response => dispatch(mortgageRequestInsuranceRelatedStatusSuccess(response.data)))
        .catch(response => {
            if (!!response.error) {
                dispatch(mortgageRequestInsuranceRelatedStatusFailure(response.error));
            } else {
                throw response;
            }
        });
};

const mortgageRequestInsurancesPricingData = () => dispatch => {
    dispatch(mortgageRequestInsurancePricingRequest());

    return APIGet({
        dispatch,
        endpoint: 'insuranceRequestRelatedPricing'
    })
        .then(response => dispatch(mortgageRequestInsurancePricingSuccess(response.data)))
        .catch(response => {
            if (!!response.error) {
                dispatch(mortgageRequestInsurancesPricingFailure(response.error));
            } else {
                throw response;
            }
        });
};

const requestInsuranceRelatedConfig = () => (dispatch, getState) => {
    const currentState = getState().mortgageRequestManagerInsuranceRelated;

    if (!currentState.get('isFetchingConfig')) {
        dispatch(requestInsuranceRelatedConfigInProgress());

        return APIGet({
            dispatch,
            endpoint: 'insuranceRequestRelatedConfig'
        })
            .then(response => {
                dispatch(requestInsuranceRelatedConfigSuccess(response.data));
            })
            .catch(response => {
                if (!!response.error) {
                    dispatch(requestInsuranceRelatedConfigFailure(response.error));
                } else {
                    throw response;
                }
            });
    } else {
        return Promise.resolve();
    }
};

const mortgageRequestInsurancesLink = (includesHomeInsurance, redirectToPanel = true, history) => (dispatch, getState) => {
    const immFlagBasedFeatures = getState().flagBasedFeatures;
    dispatch(mortgageRequestInsurancesLinkRequest());

    const params = {
        query: {
            includesHomeInsurance
        }
    };

    return APIPost({
        dispatch,
        endpoint: 'insuranceRequestRelatedLinkToMortgage',
        params
    })
        .then(response => {
            dispatch(mortgageRequestInsurancesLinkSuccess(response.data));
            if (redirectToPanel) {
                history.push(MORTGAGE_PANEL);
            } else if (immFlagBasedFeatures.get(MORTGAGE_CANCEL_OPTION) && !includesHomeInsurance) {
                dispatch(requestConditions());
                dispatch(mortgageRequestInsuranceRelatedStatusSuccess({}))
                scrollToTop();
            }
        })
        .catch(response => {
            if (!!response.error) {
                dispatch(mortgageRequestInsurancesLinkFailure(response.error));
            } else {
                throw response;
            }
        });
};

module.exports = {
    mortgageRequestInsurancesConditionsData,
    mortgageRequestInsurancesPricingData,
    mortgageRequestInsurancesRelatedStatusData,
    mortgageRequestInsurancesLink,
    requestInsuranceRelatedConfig
};
