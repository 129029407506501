// @ vendors
const { Promise } = require('es6-promise');
const { APIPost, APIGet } = require('utilities/APIRequestHelper');
const download = require('downloadjs');
// @ commons
const apiURLBuilder = require('core/apiURLBuilder');
const signaturePatternActions = require('actions/signaturePattern');
// @ constants
import { VIEWER } from 'constants/contactCenter/pdfViewer';
const actionTypes = require('constants/actionTypes');
const {
    DEBITS_RETURN_PERSONAL_4,
    DEBITS_RETURN_CAUSE_TYPE,
} = require('constants/index');
// @ helpers
import { openPDFViewer, showPDFFailure } from 'utilities/contactCenter/pdfViewer';
const { formatText } = require('core/i18n').i18n;

const changeTab = (tab, doScroll=false) => ({
    type: actionTypes.CHANGE_TAB,
    tab,
    doScroll
})

const setScroll = (scroll) => ({
    type: actionTypes.SET_SCROLL,
    scroll
})

const requestAccountDebitsFailure = (error, accountId) => ({
        type: actionTypes.FETCH_ACCOUNT_DEBITS_FAILURE,
        accountId,
        error
});

const requestAccountDebits = (accountId, contractNumber, contractProduct) => ({
    type: actionTypes.FETCH_ACCOUNT_DEBITS_REQUEST,
    accountId,
    contractNumber,
    contractProduct
})

const requestAccountDebitsDownloadPdfSuccess = () => ({
    type: actionTypes.FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_SUCCESS
});

const requestAccountDebitsDownloadPdfInProgress = () => ({
    type: actionTypes.FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_REQUEST
});

const requestAccountDebitsDownloadPdfFailure = error => ({
    type: actionTypes.FETCH_ACCOUNT_DEBITS_DOWNLOAD_PDF_FAILURE,
    payload: {
        error
    }
});

const requestAccountDebitsPdf = (immTransaction, loadType = undefined) => (dispatch, getState) => {
    const account = getState().accounts.getIn(['byId', immTransaction.get('accountId')]);
    const isAlreadyFetching = getState().accountDebits.get('isFetching');
    const originCountry = getState().profile.get('originCountry');

    const requestData = {
        header: {
            responseType: 'arraybuffer'
        },
        query: {
            codprod: immTransaction.get('codprod'),
            pais: account.getIn(['ibanComplex', 'pais']),
            digitoControl: account.getIn(['ibanComplex', 'digitodecontrol']),
            codban: account.getIn(['ibanComplex', 'codbban']),
            numsor: immTransaction.get('numsor'),
            prod: account.get('newProduct'),
            numCont: account.get('newContractNumber'),
            titular: immTransaction.get('titular'),
            bicEmisor: immTransaction.get('idban1'),
            entidadOrdenante: immTransaction.get('nombre'),
            idEmisor: immTransaction.get('identemi'),
            centro: immTransaction.getIn(['idcent', 'centro']),
            idioma: originCountry
        }
    };

    if (!isAlreadyFetching) {
        dispatch(requestAccountDebitsDownloadPdfInProgress());
        return APIGet(dispatch, apiURLBuilder.getURL('accountDebitsPdf'), requestData)
            .then(response => {
                const fileType = 'application/pdf';
                let accountDebitsPdfName = formatText('accountDebits-pdf') + '.pdf';
                if (!loadType) {
                    download(response.xhr.response, accountDebitsPdfName, fileType);
                } else if (loadType === VIEWER) {
                    openPDFViewer(response.xhr.response);
                }
                return dispatch(requestAccountDebitsDownloadPdfSuccess(response));
            })
            .catch(response => {
                if (loadType === VIEWER) {
                    showPDFFailure();
                }
                dispatch(requestAccountDebitsDownloadPdfFailure(response.error))
            }
        );
    }
};

const debitReturnWillCancel = () => ({
    type: actionTypes.DEBIT_RETURN_CANCEL
});

const setDebitReturnInProgress = () => ({
    type: actionTypes.DEBIT_RETURN_REQUEST
});

const setDebitReturnSuccess = () => ({
    type: actionTypes.DEBIT_RETURN_SUCCESS
});

const setDebitReturnFailure = error => ({
    type: actionTypes.DEBIT_RETURN_FAILURE,
    payload: {
        error
    }
});

const postDebitReturn = (dispatch, getState, responseValidation, receiptToReturn, dataToken) => {
    const account = getState().accounts.getIn(['byId', receiptToReturn.get('accountId')]);
    const requestData = {
        query: {
            dataToken: dataToken,
            devolucionRecibo: {
                cestado: receiptToReturn.get('cestado'),
                codprod: receiptToReturn.get('codprod'),
                codsprod: receiptToReturn.get('codsprod'),
                idempr: receiptToReturn.get('idempr'),
                idemprd: receiptToReturn.get('idempr'),
                motoper4: DEBITS_RETURN_PERSONAL_4,
                numsor: receiptToReturn.get('numsor'),
                origfond: responseValidation.origfond,
                refer1: receiptToReturn.get('refer1'),
                tipmoti: DEBITS_RETURN_CAUSE_TYPE
            },
            entradaSeguridad: {
                contrato: {
                    numerodecontrato: account.get('newContractNumber'),
                    producto: account.get('newProduct'),
                }
            }
        }
    };

    dispatch(setDebitReturnInProgress());

    return APIPost(dispatch, apiURLBuilder.getURL('accountDebitReturn'), requestData)
        .then(response => dispatch(setDebitReturnSuccess(response)))
        .catch(response => dispatch(setDebitReturnFailure(response.error)));
};

const postDebitReturnValidation = (dispatch, getState, receiptToReturn, dataToken) => {
    const account = getState().accounts.getIn(['byId', receiptToReturn.get('accountId')]);
    const validationData = {
        query: {
            codprod: receiptToReturn.get('codprod'),
            entradaSeguridad: {
                contrato: {
                    numerodecontrato: account.get('newContractNumber'),
                    producto: account.get('newProduct'),
                }
            },
            numsor: receiptToReturn.get('numsor')
        }
    };
    dispatch(setDebitReturnInProgress());
    return APIPost(dispatch, apiURLBuilder.getURL('accountDebitReturnValidation'), validationData)
        .then(response => postDebitReturn(dispatch, getState, response, receiptToReturn, dataToken))
        .catch(response => dispatch(setDebitReturnFailure(response.error)));
};

const debitReturn = receiptToReturn => (dispatch, getState) => {
    const callback = (dispatch, getState, dataToken) => {
        postDebitReturnValidation(dispatch, getState, receiptToReturn, dataToken);
    };
    const isAlreadyFetching = getState().debitReturn.get('isFetching');
    const hasError = getState().debitReturn.get('errorReceived') !== '';

    if (!isAlreadyFetching && !hasError) {
        new Promise(function() {
            signaturePatternActions.validateAndExecute(dispatch, getState, callback);
        });
    }
};

module.exports = {
    changeTab,
    requestAccountDebits,
    requestAccountDebitsFailure,
    requestAccountDebitsPdf,
    debitReturnWillCancel,
    debitReturn,
    requestAccountDebitsDownloadPdfFailure,
    setScroll
};
