//@ utilities
const { APIPost } = require('utilities/APIRequestHelper');
//@ constants
const { DATE_FORMAT_API } = require('constants/index');
const actionTypes = require('constants/actionTypes');

const resetCardControl = (modalOpen = false) => ({
    type: actionTypes.CARD_CONTROL_ON_RESET,
    ...(__CONTACT_CENTER__ ? {
        payload: {
            modalOpen
        }
    } : {})
})

const successRequest = (cardId, switchedOff) => ({
    type: actionTypes.CARD_CONTROL_ON_SUCCESS,
    payload: {
        cardId,
        switchedOff
    }
})

const failureRequest = (error) => ({
    type: actionTypes.CARD_CONTROL_ON_FAILURE,
    payload: { error }
})

const requestRequest = () => ({ type: actionTypes.CARD_CONTROL_ON_REQUEST })

const requestCardControl = (cardId, switchedOff) => (dispatch, getState) => {
    const immCard = getState().cards.get('byId').get(cardId);
    const params = {
        query: {
            pan: immCard.get('number').split(' ').join(''),
            bloqueada: switchedOff,
            fechaCaducidad: immCard.get('expirationDate').format(DATE_FORMAT_API),
        }
    };

    dispatch(requestRequest());
    APIPost({
        dispatch,
        endpoint: 'cardControlOn',
        params
    })
    .then((response) => {
        if (response.status === 401) {
            dispatch(failureRequest('Unauthorized')); // Expired session handling.
        } else {
            dispatch(successRequest(cardId, switchedOff));
        }
    })
    .catch(response => {
        if (!!response.error) {
            dispatch(failureRequest(response.error))
        }
        else {
            throw response;
        }
    });
};

const switchCardControlOn = cardId => requestCardControl(cardId, false);

const switchCardControlOff = cardId => requestCardControl(cardId, true);

const channelsGetFailed = ({ message }) => ({
    type: actionTypes.CARD_CHANNELS_GET_FAILED,
    payload: { message },
});

const channelsGetRequested = ({ cardId, pan }) => ({
    type: actionTypes.CARD_CHANNELS_GET_REQUESTED,
    payload: { cardId, pan },
});

const channelsGetSucceeded = ({ cardId, channels }) => ({
    type: actionTypes.CARD_CHANNELS_GET_SUCCEEDED,
    payload: { cardId, channels },
});

const channelsSetFailed = ({ cardId, channels, message }) => ({
    type: actionTypes.CARD_CHANNELS_SET_FAILED,
    payload: { cardId, channels, message },
});

const channelsSetRequested = ({ cardId, channels, pan, lastDigitsPan }) => ({
    type: actionTypes.CARD_CHANNELS_SET_REQUESTED,
    payload: { cardId, channels, pan, lastDigitsPan },
});

const channelsSetSucceeded = ({ cardId, channels }) => ({
    type: actionTypes.CARD_CHANNELS_SET_SUCCEEDED,
    payload: { cardId, channels },
});

const countriesFilterReset = () =>  ({
    type: actionTypes.CARD_COUNTRIES_FILTER_RESET
});

const countriesFilterChanged = ({ value }) => ({
    type: actionTypes.CARD_COUNTRIES_FILTER_CHANGE,
    payload: { value },
});

const countriesGetFailed = ({ message }) => ({
    type: actionTypes.CARD_COUNTRIES_GET_FAILED,
    payload: { message },
});

const countriesGetRequested = ({ cardId, pan }) => ({
    type: actionTypes.CARD_COUNTRIES_GET_REQUESTED,
    payload: { cardId, pan },
});

const countriesGetSucceeded = ({ cardId, locations }) => ({
    type: actionTypes.CARD_COUNTRIES_GET_SUCCEEDED,
    payload: { cardId, locations },
});

const countriesOnlySetFailed = ({ cardId, locations, message }) => ({
    type: actionTypes.CARD_COUNTRIES_ONLY_SET_FAILED,
    payload: { cardId, locations, message },
});

const countriesOnlySetRequested = ({ cardId, pan, countriesIds, originCountryZone, lastDigitsPan }) => ({
    type: actionTypes.CARD_COUNTRIES_ONLY_SET_REQUESTED,
    payload: { cardId, pan, countriesIds, originCountryZone, lastDigitsPan },
});

const countriesOnlySetSucceeded = ({ cardId, countriesIds }) => ({
    type: actionTypes.CARD_COUNTRIES_ONLY_SET_SUCCEEDED,
    payload: { cardId, countriesIds },
});

const countrySetFailed = ({ active, cardId, countryId, message }) => ({
    type: actionTypes.CARD_COUNTRY_SET_FAILED,
    payload: { active, cardId, countryId, message },
});

const countrySetRequested = ({ active, cardId, countryId, pan, lastDigitsPan }) => ({
    type: actionTypes.CARD_COUNTRY_SET_REQUESTED,
    payload: { active, cardId, countryId, pan, lastDigitsPan },
});

const countrySetSucceeded = ({ cardId, locations }) => ({
    type: actionTypes.CARD_COUNTRY_SET_SUCCEEDED,
    payload: { cardId, locations },
});

const infoGetFailed = ({ message }) => ({
    type: actionTypes.CARD_INFO_GET_FAILED,
    payload: { message },
});

const infoGetRequested = ({ cardId, pan, originCountry, lastDigitsPan }) => ({
    type: actionTypes.CARD_INFO_GET_REQUESTED,
    payload: { cardId, pan, originCountry, lastDigitsPan },
});

const infoGetSucceeded = ({ cardId, channels, locations, originCountryZone }) => ({
    type: actionTypes.CARD_INFO_GET_SUCCEEDED,
    payload: { cardId, channels, locations, originCountryZone },
});

const zoneSetFailed = ({ cardId, locations, message }) => ({
    type: actionTypes.CARD_ZONE_SET_FAILED,
    payload: { cardId, locations, message },
});

const zoneSetRequested = ({ active, cardId, pan, zoneId, lastDigitsPan }) => ({
    type: actionTypes.CARD_ZONE_SET_REQUESTED,
    payload: { active, cardId, pan, zoneId, lastDigitsPan },
});

const zoneSetSucceeded = ({ active, cardId, zoneId }) => ({
    type: actionTypes.CARD_ZONE_SET_SUCCEEDED,
    payload: { active, cardId, zoneId },
});

const zoneSelected = ({ code }) => ({
    type: actionTypes.CARD_ZONE_SELECTED,
    payload: { code },
});

const originCountryZoneSucceeded = ({ cardId, originCountryZone }) => ({
    type: actionTypes.CARD_ZONE_SPAIN_SUCCEEDED,
    payload: { cardId, originCountryZone }
});

const logCardControlEnter = () => ({
    type: actionTypes.CARD_ZONE_ENTER,
});


module.exports = {
    switchCardControlOn,
    switchCardControlOff,
    resetCardControl,
    channelsGetFailed,
    channelsGetRequested,
    channelsGetSucceeded,
    channelsSetFailed,
    channelsSetRequested,
    channelsSetSucceeded,
    countriesFilterChanged,
    countriesFilterReset,
    countriesGetFailed,
    countriesGetRequested,
    countriesGetSucceeded,
    countriesOnlySetFailed,
    countriesOnlySetRequested,
    countriesOnlySetSucceeded,
    countrySetFailed,
    countrySetRequested,
    countrySetSucceeded,
    infoGetFailed,
    infoGetRequested,
    infoGetSucceeded,
    logCardControlEnter,
    zoneSetFailed,
    zoneSetRequested,
    zoneSetSucceeded,
    zoneSelected,
    originCountryZoneSucceeded
};
