// @ constants
const actionTypes = require('constants/actionTypes');
const { PRODUCT_BANNER_COMPONENT_ID } = require('constants/hiringProductInformation')
// @ utilities
const { APIPost } = require('utilities/APIRequestHelper');
const { getLanguage } = require('core/i18n').i18n;

const hiringProductBannersDataRequest = () => ({
    type: actionTypes.FETCH_HIRING_PRODUCT_BANNERS_REQUEST
});

const hiringProductBannersDataFailure = () => ({
    type: actionTypes.FETCH_HIRING_PRODUCT_BANNERS_FAILURE
});

const hiringProductBannersDataSuccess = (response) => ({
    type: actionTypes.FETCH_HIRING_PRODUCT_BANNERS_SUCCESS,
    payload: {
        response
    }
});

const hiringProductBannersDataReset = () => ({
    type: actionTypes.FETCH_HIRING_PRODUCT_BANNERS_RESET
});

const getHiringProductBanners = (productList) => (dispatch) => {
    if (productList.length === 0){ // If service is called with empty products it returns all the products
        dispatch(hiringProductBannersDataReset());
    } else {
        const requestData = {
            query : {
                channel: "private",
                lang: getLanguage().toLowerCase(),
                overrideConfiguration: false,
                requestedProducts: productList,
                uiComponentId: PRODUCT_BANNER_COMPONENT_ID
            }
        };

        dispatch(hiringProductBannersDataRequest());
        return APIPost({
            dispatch,
            endpoint: 'hiringProductInformation',
            params: requestData
        })
        .then(response => {
            dispatch(hiringProductBannersDataSuccess(response));
        })
        .catch(response => {
            if (response.error) {
                dispatch(hiringProductBannersDataFailure(response.error.description));
            } else {
                dispatch(hiringProductBannersDataFailure(''));
                throw response;
            }
        });
    }
};

const validateBlockedAccountRequest = () => ({
    type: actionTypes.VALIDATE_BLOCKED_ACCOUNT_REQUEST
});

const validateBlockedAccountSuccess = () => ({
    type: actionTypes.VALIDATE_BLOCKED_ACCOUNT_SUCCESS
});

const validateBlockedAccountFailure = (error) => ({
    type: actionTypes.VALIDATE_BLOCKED_ACCOUNT_FAILURE,
    payload: {
        error
    }
});

const validateBlockedAccount = () => (dispatch, getState) => {
    const accountsById = getState().accounts.get('byId');
    const contracto = accountsById.map(account => {
        return { numerodecontrato: account.get("newContractNumber"), producto: account.get("newProduct")}
    });

    const requestData = {
        query : {
            accounts: Object.values(contracto.toJS())
        }
    };

    dispatch(validateBlockedAccountRequest());
    return APIPost({
        dispatch,
        endpoint: 'validateBlockedAccount',
        params: requestData
    })
    .then(() => dispatch(validateBlockedAccountSuccess()))
    .catch(response => {
        if (!!response.error) {
            dispatch(validateBlockedAccountFailure(response.error));
        } else {
            throw response;
        }
    });
}

const showAccountBlockedErrorModal = (showModal) => ({
    type: actionTypes.SHOW_ACCOUNT_BLOCKED_ERROR_MODAL,
    payload: {
        showModal
    }
})

module.exports = {
    getHiringProductBanners,
    showAccountBlockedErrorModal,
    validateBlockedAccount,
}

