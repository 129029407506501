let actionTypes = require('constants/actionTypes');

function show(error, showTealium = true, history) {
    history.push('/myprofile/broker/error');
    return {
        type: actionTypes.BROKER_ERROR,
        payload: {
            error: error,
            showTealium: showTealium
        }
    }
}

module.exports = {
    show
};
