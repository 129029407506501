// @ vendors
const get = require('lodash/object/get');
const set = require('lodash/object/set');
// @ commons
const apiURLBuilder = require('core/apiURLBuilder');
const { APIGet } = require('utilities/APIRequestHelper');
const { recordError } = require('utilities/tagging');

// @ constants
const actionTypes = require('constants/actionTypes');
const { getTargetDateHolders, getTargetDateLivingPlace } = require('constants/mortgageRequestTargetDates');

const requestStateInProgress = () => ({
    type: actionTypes.MORTGAGE_REQUEST_FETCH_STATE_REQUEST
});

const requestStateSuccess = (data) => ({
    type: actionTypes.MORTGAGE_REQUEST_FETCH_STATE_SUCCESS,
    data: data
});

const requestStateFailure = (error) => ({
    type: actionTypes.MORTGAGE_REQUEST_FETCH_STATE_FAILURE,
    data: {
        error
    }
});

const requestState = (path, history) => (dispatch) => {
    dispatch(requestStateInProgress());
    return APIGet(dispatch, apiURLBuilder.getURL('mortgageRequestStateRequest'))
        .then((response) => {
            const fechaCreacion = get(response, 'data.fechaCreacion', '');
            const fechaObjetivo = get(response, 'data.fechaObjetivo', '');

            //from OPE087-1025
            const fechaObjetivoVivienda = getTargetDateLivingPlace(fechaCreacion, fechaObjetivo);
            set(response, ['data', 'fechaObjetivoVivienda'], fechaObjetivoVivienda.format('YYYY-MM-DD'));

            const fechaObjetivoTitulares = getTargetDateHolders(fechaCreacion, fechaObjetivo);
            set(response, ['data', 'fechaObjetivoTitulares'], fechaObjetivoTitulares.format('YYYY-MM-DD'));

            dispatch(requestStateSuccess(response));
            if (!!path) {
                history.push(path);
            }
        })
        .catch((response) => {
            if (!!response.error) {
                /**
                 * tracking para el route mortgage-error.
                 * se hace de esta manera y no en el index de routes porque
                 * se debe enviar el status del error y en esta parte se puede obtener
                 */
                recordError(response.error.status);
                dispatch(requestStateFailure(response.error));
                history.push('/myprofile/mortgage-error');
            } else {
                throw response;
            }
        });
};

const cargaInicial = () => (dispatch) => {
    dispatch({
        type: actionTypes.MORTGAGE_REQUEST_SET_INITIAL_STATE
    });
};

const resetMortgageRequestState = () => ({
    type: actionTypes.MORTGAGE_REQUEST_RESET_STATE,
});

module.exports = {
    requestState,
    cargaInicial,
    resetMortgageRequestState
};
