let actionTypes = require('constants/actionTypes');
const merge = require('lodash/object/merge');

function show(opts, level = 'success', extraParams = {}) {
    if (typeof opts === 'string') {
        opts = {
            message: opts
        }
    }
    return merge({type: actionTypes.SHOW_NOTIFICATION, level, opts}, extraParams);
}

function success(opts, extraParams) {
    return show(opts, 'success', extraParams);
}

function error(opts, extraParams) {
    return show(opts, 'error', extraParams);
}

function warning(opts, extraParams) {
    return show(opts, 'warning', extraParams);
}

function info(opts, extraParams) {
    return show(opts, 'alert', extraParams);
}

function hide(uid) {
    return {
        type: actionTypes.HIDE_NOTIFICATION,
        uid
    };
}

function showed(uid) {
    return {
        type: actionTypes.SHOWED_NOTIFICATION,
        uid
    };
}

module.exports = {
    success,
    error,
    warning,
    info,
    hide,
    showed
};
