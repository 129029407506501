const get = require('lodash/object/get');
const set = require('lodash/object/set');
const trim = require('lodash/string/trim');


const setIbans = (publicInformation) => {
    const accounts = get(publicInformation, 'datosSalidaCuentas.cuentas', [])
    const IBANs = get(publicInformation, 'datosSalidaCodIban.datosIban', [])

    const setIbans = accounts.map((account,index) => {
        const country = get(IBANs, `${index}.codIban.pais`);
        const controlDigit = trim(get(IBANs, `${index}.codIban.digitodecontrol`));
        const codbban = trim(get(IBANs, `${index}.codIban.codbban`));

        return Object.assign({}, publicInformation.datosSalidaCuentas.cuentas[index], {
            iban: country+controlDigit+codbban,
            ibanComplex: {
                pais: country,
                digitodecontrol: controlDigit,
                codbban: codbban
            }
        });
    })
    set(publicInformation, 'datosSalidaCuentas.cuentas', setIbans);
    return publicInformation;
}

module.exports = {
    setIbans
}
