// @vendor
const { Promise } = require("es6-promise");

// @utilities
const get = require("lodash/object/get");
const { APIPost } = require("utilities/APIRequestHelper");
const apiURLBuilder = require("core/apiURLBuilder");
const storage = require("utilities/storage");
const { cookiesDomain } = require("core/environmentConfig");
const { formatText } = require("core/i18n").i18n;
const { initBioCatch } = require("utilities/biocatch");

// @actions
const { nerudaUserCheck } = require("actions/nerudaUser");
const { aresUserCheck } = require("actions/aresUser");
const notification = require("actions/notifications");
const getGlobalVariables = require("actions/globalVariables");
import { setOriginCountry } from "actions/flagBasedFeatures";
const { keepAlive } = require("actions/logout");
const { requestTranslations } = require("actions/translations");
const { setUserNameInWelcomeMessage } = require("actions/chat");
const { setInitBiocatch } = require('actions/biocatch');

// @constants
const actionTypes = require("constants/actionTypes");
const {
    COUNTRIES_CODES: { ES }
} = require("constants/countries");
const {
    TRANSLATION_PLATFORM_PRI,
    TRANSLATION_PLATFORM_CC,
    TRANSLATION_PLATFORM_SHARED
} = require("constants/translations");
const { LOCAL_ENVIRONMENT } = require("constants/index");

const profileUpdatePictureAction = currentlyVisibleImage => ({
    type: actionTypes.PROFILE_UPDATE_PICTURE,
    currentlyVisibleImage
});

const setOfflogCookie = offlogToken => {
    if (!offlogToken) {
        storage.cookies.remove("offlogToken");
        return;
    }
    storage.cookies.set("offlogToken", offlogToken, { expires: 30 });
};

const callbackUpdateCookie = dispatch => {
    return APIPost(dispatch, apiURLBuilder.getURL("generateOfflogToken"))
        .then(response => setOfflogCookie(response.offlogToken))
        .catch(response => {
            throw response;
        });
};

const setNerudaCookie = response => {
    const nerudaCookieExists = !!storage.cookies.get("neruda");
    const isNerudaUser = get(response, "userProfile.clientProfile") === 2;
    const aresUserCookieExists = !!storage.cookies.get("ares");
    const isAresUser = get(response, 'userProfile.clientProfile') === 3;

    let attributes = {};

    if (window.__ENV__ !== LOCAL_ENVIRONMENT) {
        attributes = { domain: cookiesDomain };
    }

    if (isNerudaUser) {
        if (!nerudaCookieExists) {
            storage.cookies.set("neruda", "true", attributes);
        }
    } else if (nerudaCookieExists) {
        storage.cookies.remove("neruda", attributes);
    }

    if (isAresUser) {
        if (!aresUserCookieExists) {
            storage.cookies.set("ares", "true", attributes);
        }
    } else if (aresUserCookieExists) {
        storage.cookies.remove("ares", attributes);
    }
};

const updateProfilePicture = (image, preview, isAvatar) => dispatch => {
    dispatch(profileUpdatePictureAction(preview));

    if (isAvatar) {
        const requestData = {
            query: {
                url: image
            }
        };

        return APIPost(
            dispatch,
            apiURLBuilder.getURL("userProfileAvatar"),
            requestData
        )
            .then(() => callbackUpdateCookie(dispatch))
            .catch(response => {
                throw response;
            });
    } else {
        // if image is a string (that starts like "data:image/...") then it's a drawing}
        // if image is a File object, then it's a photo

        const isDataImage =
            typeof image === "string" && image.startsWith("data:image/");

        const formData = new FormData();
        formData.append("imageValue", isDataImage ? image : preview);
        const requestData = {
            query: formData
        };

        return APIPost(
            dispatch,
            apiURLBuilder.getURL("userProfileAvatarBase64"),
            requestData,
            false
        )
            .then(() => callbackUpdateCookie(dispatch))
            .catch(response => {
                throw response;
            });
    }
};

const requestProfileInProgress = () => ({
    type: actionTypes.FETCH_PROFILE_REQUEST
});

const requestProfileSuccess = (profile, fullname) => ({
    type: actionTypes.FETCH_PROFILE_SUCCESS,
    profile,
    fullname
});

const requestProfileFailure = error => ({
    type: actionTypes.FETCH_PROFILE_FAILURE,
    profile: {
        error
    }
});

const requestProfile = (history) => (dispatch, getState) => {
    const isAlreadyFetching = getState().profile.get("isFetching");
    if (!isAlreadyFetching) {
        dispatch(requestProfileInProgress());
        const headers = {
            dispatch,
            params: {
                header: {
                    version: 2
                },
                includeProfile: true
            },
            endpoint: "generateOfflogToken",
            url:
                apiURLBuilder.getURL("generateOfflogToken") +
                "?includeProfile=true"
        };

        return APIPost(headers)
            .then(response => {
                const { offlogToken, userProfile, fullname } = response;
                if (userProfile) {
                    setNerudaCookie(response);
                }
                nerudaUserCheck(response, history);
                aresUserCheck(response, history);
                setOfflogCookie(offlogToken);
                userProfile &&
                    userProfile.nombreCliente &&
                    dispatch(
                        setUserNameInWelcomeMessage(userProfile.nombreCliente)
                    );

                // TODO: PASAPORTE: Just in case the endpoint does not return the originCountry
                const originCountry = userProfile.originCountry || ES;
                dispatch(setOriginCountry(originCountry));
                if (
                    typeof getState().profile.get("originCountry") ===
                    "undefined"
                )
                    dispatch(getGlobalVariables(originCountry));
                dispatch(requestProfileSuccess(userProfile, fullname));

                const ccLocalLanguageKey = "cclanguage";
                const wpSessionLanguageKey = "language";

                //Profile Language Management
                if (userProfile) {
                    const { browsingLanguage, originCountry } = userProfile;
                    const profileLanguage = !__CONTACT_CENTER__
                        ? browsingLanguage || originCountry.toLowerCase()
                        : originCountry.toLowerCase();
                    const ccAgentLanguage = localStorage.getItem(
                        "ccAgentLanguage"
                    );
                    const platform = __CONTACT_CENTER__
                        ? TRANSLATION_PLATFORM_CC
                        : TRANSLATION_PLATFORM_PRI;
                    const getLanguageSavedOnCache = () =>
                        __CONTACT_CENTER__
                            ? localStorage.getItem(ccLocalLanguageKey)
                            : storage.cookies.get(wpSessionLanguageKey);
                    const profileLanguageIsDifferentFromSavedOnCache = () =>
                        !!(getLanguageSavedOnCache() !== profileLanguage);
                    const profileLanguageIsDifferentFromCCAgentLanguage = () =>
                        !!(getLanguageSavedOnCache() !== ccAgentLanguage);
                    const updateWPLanguageSavedOnCache = () =>
                        storage.cookies.set(
                            wpSessionLanguageKey,
                            profileLanguage
                        );
                    const updateCCLanguageSavedOnCache = profileLanguage =>
                        localStorage.setItem(
                            ccLocalLanguageKey,
                            ccAgentLanguage || profileLanguage
                        );
                    const getRealBrowsingLanguage = () =>
                        ccAgentLanguage
                            ? ccAgentLanguage
                            : (getLanguageSavedOnCache() &&
                                  getLanguageSavedOnCache()) ||
                              profileLanguage;
                    const dispatchTranslations = () =>
                        dispatch(
                            requestTranslations(
                                platform,
                                getRealBrowsingLanguage()
                            )
                        );
                    const dispatchTranslationsShared = () =>
                        dispatch(
                            requestTranslations(
                                TRANSLATION_PLATFORM_SHARED,
                                getRealBrowsingLanguage()
                            )
                        );
                    const dispatchKeepAlive = () => dispatch(keepAlive(true));

                    (profileLanguageIsDifferentFromSavedOnCache() ||
                        profileLanguageIsDifferentFromCCAgentLanguage()) &&
                        (__CONTACT_CENTER__
                            ? updateCCLanguageSavedOnCache(profileLanguage)
                            : updateWPLanguageSavedOnCache(profileLanguage));

                    // Initial call to Transalations
                    dispatchTranslations();
                    dispatchTranslationsShared();
                    // Initial call to Keep Alive
                    dispatchKeepAlive();
                    // Init Biocatch. Moved from app.js to avoid an early init in STG/QA environments. (CM-12785)
                    if (
                        !__CONTACT_CENTER__ &&
                        storage.cookies.get("tokenCredential")
                    ) {
                        const immBiocatch = getState().biocatch;
                        if (!immBiocatch.get('initialized')) {
                            initBioCatch();
                            dispatch(setInitBiocatch());
                        }
                    }
                }
            })
            .catch(response => {
                if (!!response.error) {
                    dispatch(requestProfileFailure(response.error));
                } else {
                    throw response;
                }
            });
    } else {
        return Promise.resolve();
    }
};

const setUserBrowsingLanguageInProgress = () => ({
    type: actionTypes.PROFILE_SET_BROWSING_LANGUAGE_IN_PROGRESS
});

const setUserBrowsingLanguageSuccess = browsingLanguage => ({
    type: actionTypes.PROFILE_SET_BROWSING_LANGUAGE_SUCCESS,
    payload: {
        browsingLanguage
    }
});

const setUserBrowsingLanguageFailure = error => ({
    type: actionTypes.PROFILE_SET_BROWSING_LANGUAGE_ERROR,
    payload: {
        error
    }
});

const requestSetBrowsingLanguage = browsingLanguage => (dispatch, getState) => {
    const isSettingBrowsingLanguage = getState().profile.get(
        "isSettingBrowsingLanguage"
    );
    if (!isSettingBrowsingLanguage) {
        dispatch(setUserBrowsingLanguageInProgress());
        const requestData = {
            query: {
                browsingLanguage
            }
        };
        return APIPost(
            dispatch,
            apiURLBuilder.getURL("setProfileBrowsingLanguage"),
            requestData
        )
            .then(() => {
                dispatch(setUserBrowsingLanguageSuccess(browsingLanguage));
                storage.cookies.set("language", browsingLanguage);
                if (storage.cookies.get("language") === browsingLanguage)
                    location.reload();
            })
            .catch(error => {
                dispatch(setUserBrowsingLanguageFailure(error));
                dispatch(
                    notification.error({
                        message: formatText("profile-errorSetLanguage")
                    })
                );
            });
    } else {
        return Promise.resolve();
    }
};

const setUserAliasInProgress = () => ({
    type: actionTypes.PROFILE_SET_ALIAS_IN_PROGRESS
});

const setUserAliasFailure = error => ({
    type: actionTypes.PROFILE_SET_ALIAS_ERROR,
    payload: {
        error
    }
});

const setUserAliasSuccess = alias => ({
    type: actionTypes.PROFILE_SET_ALIAS_SUCCESS,
    payload: {
        alias
    }
});

const setUserAlias = (alias, history) => (dispatch, getState) => {
    const immProfile = getState().profile;
    const isSettingAlias = immProfile.get("isSettingAlias");

    if (!isSettingAlias) {
        const requestData = {
            query: {
                alias: alias.trim()
            }
        };

        dispatch(setUserAliasInProgress());
        return APIPost(
            dispatch,
            apiURLBuilder.getURL("setProfileUserAlias"),
            requestData
        )
            .then(() => dispatch(requestProfile(history)))
            .then(() => {
                const message = formatText("profile-setAliasSuccess");
                dispatch(notification.success({ message }, { voice: message }));
                dispatch(setUserAliasSuccess(alias));
            })
            .catch(response => {
                const message = formatText("profile-setAliasFailure");
                dispatch(notification.error({ message }, { voice: message }));
                dispatch(setUserAliasFailure(response.error.description));
            });
    } else {
        return Promise.resolve();
    }
};

const profileAvatarEditorModal = () => ({
    type: actionTypes.PROFILE_EDIT_AVATAR_MODAL
});

const profileAvatarEditorModalShow = () => dispatch => {
    dispatch(profileAvatarEditorModal());
};

const profileAvatarEditorError = error => ({
    type: actionTypes.PROFILE_EDIT_AVATAR_ERROR,
    payload: {
        error
    }
});

const profileUpdateConsentStatus = ({ status }) => ({
    type: actionTypes.PROFILE_UPDATE_CONSENTS_STATUS,
    payload: {
        status
    }
});

const profileUpdateConsentStatusSuccess = ({ status }) => ({
    type: actionTypes.PROFILE_UPDATE_CONSENTS_STATUS_SUCCESS,
    payload: {
        status
    }
});

const profileUpdateConsentStatusError = ({ error, status }) => ({
    type: actionTypes.PROFILE_UPDATE_CONSENTS_STATUS_FAILURE,
    payload: {
        error,
        status
    }
});

const setProfileAvatarEditorError = error => dispatch => {
    dispatch(profileAvatarEditorError(error));
};

module.exports = {
    updateProfilePicture,
    callbackUpdateCookie,
    setOfflogCookie,
    profileAvatarEditorModalShow,
    profileUpdateConsentStatus,
    profileUpdateConsentStatusError,
    profileUpdateConsentStatusSuccess,
    requestProfile,
    requestProfileSuccess,
    setProfileAvatarEditorError,
    setUserAlias,
    requestSetBrowsingLanguage
};
