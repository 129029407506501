const {
    RECEIPTS_FILTER,
    RECEIPTS_FILTER_BY_NAME,
    RECEIPT_GET_PREVIEW,
    RECEIPT_GET_PREVIEW_FAILURE,
    RECEIPT_SET_PREVIEW,
    SET_RECEIPTS
} = require('constants/actionTypes');

const receiptsFilter = (accountId, immFilters) => ({
    type: RECEIPTS_FILTER,
    accountId,
    immFilters
})

const receiptsFilterByName = (accountId, name) => ({
    type: RECEIPTS_FILTER_BY_NAME,
    accountId,
    name
})

const receiptGetPreviewFailure = (accountId, receiptId, error) => ({
    type: RECEIPT_GET_PREVIEW_FAILURE,
    accountId,
    receiptId,
    error
})

const receiptGetPreview = (accountId, receiptId, codprod, numsor, prod, numCont, centro, idioma) => ({
    type: RECEIPT_GET_PREVIEW,
    accountId,
    receiptId,
    codprod,
    numsor,
    prod,
    numCont,
    centro,
    idioma
})

const receiptSetPreview = (accountId, receiptId, preview) => ({
    type: RECEIPT_SET_PREVIEW,
    accountId,
    receiptId,
    preview
})

const setReceipts = (accountId, receipts) => ({
    type: SET_RECEIPTS,
    accountId,
    receipts
})

module.exports = {
    receiptsFilter,
    receiptsFilterByName,
    receiptGetPreview,
    receiptGetPreviewFailure,
    receiptSetPreview,
    setReceipts
}