// @ vendor
const get = require('lodash/object/get');
// @ commons
const apiURLBuilder = require('core/apiURLBuilder');
const { APIGet, APIPost } = require('utilities/APIRequestHelper');
// @ constants
const actionTypes = require('constants/actionTypes');
const { API_WEB_CHANNEL,
        CALL_ME_BACK_REQUEST_HEADER_VERSION_THREE,
        CALL_ME_BACK_REQUEST_HEADER_VERSION,
        CALL_TIME_OPTIONS_STATUS_OK } = require('constants/index');

const toggleModal = () => (dispatch) => dispatch({
    type: actionTypes.BROKER_CALLMEBACK_TOGGLE_MODAL,
    payload: {}
});

const changeTelCode = (telcode) => (dispatch) => dispatch({
    type: actionTypes.BROKER_CALLMEBACK_CHANGE_TEL_CODE,
    payload: { telcode }
});

const changeTel = (phone) => (dispatch) => dispatch({
    type: actionTypes.BROKER_CALLMEBACK_CHANGE_TEL,
    payload: { phone }
});

const changeCallTime = (callTime) => (dispatch) => dispatch({
    type: actionTypes.BROKER_CALLMEBACK_CHANGE_CALL_TIME,
    payload: { callTime }
});

const requestBrokerCallTimeOptions = (country) => ({ // camiar auqi
    type: actionTypes.BROKER_CALLMEBACK_GET_CALL_TIME,
    payload: { country }
});

const requestPrivateCallTimeOptions = () => ({
    type: actionTypes.CALLMEBACK_GET_CALL_TIME
});

const changeComment = message => dispatch =>
    dispatch({
        type: actionTypes.BROKER_CALLMEBACK_CHANGE_COMMENT,
        payload: { message }
    });

const requestSpecialistsInProgress = () => ({ type: actionTypes.BROKER_GET_SPECIALISTS });

function requestSpecialistsFailure(error) {
    return {
        type: actionTypes.BROKER_GET_SPECIALISTS_FAILURE,
        payload: {
            error
        }
    };
}

function requestSpecialistsSuccess(response) {
    return {
        type: actionTypes.BROKER_GET_SPECIALISTS_SUCCESS,
        payload: {
            specialistsData: response
        }
    };
}

function requestSpecialists(dispatch, getState, type) {
    const isAlreadyFetching = getState().brokerInvestmentSpecialists.get('isFetching');

    if (!isAlreadyFetching) {
        dispatch(requestSpecialistsInProgress());

        return APIGet(dispatch, apiURLBuilder.getURL(type))
            .then(response => dispatch(requestSpecialistsSuccess(response)))
            .catch(response => dispatch(requestSpecialistsFailure(response.text)));
    }
}

const resetCallMeBack = () => ({
    type: actionTypes.CALLMEBACK_RESET
});

const requestCallMeBackInProgress = () => ({
    type: actionTypes.CALLMEBACK_IN_PROGRESS
});

function requestCallMeBackSuccess(response) {
    return {
        type: actionTypes.CALLMEBACK_SUCCESS,
        payload: {
            status: get(response, 'status', ''),
            idCallmeback: get(response, 'idCallmeback', ''),
            message: get(response, 'message', ''),
            timestamp: get(response, 'timestamp', '')
        }
    };
}

function requestCallMeBackFailure(error) {
    return {
        type: actionTypes.CALLMEBACK_FAILURE,
        payload: {
            error
        }
    };
}

const requestCallMeBack = (requestCallMeBack, resetCallMeBackCallback, isCallMeBackGenesysEnabled, pageTitle) => (dispatch, getState) => {
    const immInvestmentSpecialists = getState().brokerInvestmentSpecialists;
    const isAlreadyRequestingCall = immInvestmentSpecialists.get('requestingCall');
    const apiVersion = isCallMeBackGenesysEnabled ? CALL_ME_BACK_REQUEST_HEADER_VERSION_THREE : CALL_ME_BACK_REQUEST_HEADER_VERSION;
    const contactTime = requestCallMeBack.timeForContact.slice(0, 5);

    if (!isAlreadyRequestingCall) {
        const requestData = {
            header: {
                version: apiVersion
            },
            query: {
                isCustomer: 'Customer',
                type: requestCallMeBack.type ? requestCallMeBack.type : 'Web Privada',
                pageTitle: requestCallMeBack.pageTitle,
                message: requestCallMeBack.message,
                phone: requestCallMeBack.phone,
                pagePath: requestCallMeBack.pagePath,
                timeForContact: contactTime,
                canalorigen: API_WEB_CHANNEL,
                origin: pageTitle
            }
        };

        if (isCallMeBackGenesysEnabled) {
            requestData.query.name = requestCallMeBack.name;
            requestData.query.lastName1 = requestCallMeBack.lastName1;
            requestData.query.lastName2 = requestCallMeBack.lastName2;
        } else {
            requestData.query.fullname = requestCallMeBack.name + ' ' + requestCallMeBack.lastName1 + ' ' + requestCallMeBack.lastName2;
        }

        dispatch(requestCallMeBackInProgress());

        APIPost(dispatch, apiURLBuilder.getURL('callMeBack'), requestData)
            .then(response => dispatch(requestCallMeBackSuccess(response)))
            .then(() => {
                const status = getState().brokerInvestmentSpecialists.get('status');

                if (resetCallMeBackCallback && status === CALL_TIME_OPTIONS_STATUS_OK) {
                    resetCallMeBackCallback();
                }
            })
            .catch(response => {
                dispatch(requestCallMeBackFailure(response.error));
                resetCallMeBackCallback();
            });
    }
};

const validateCallMeBackModal = () => {
    return {
        type: actionTypes.BROKER_CALLMEBACK_VALIDATION,
        payload: {}
    };
};

const setProfilePhone = (profilePhone) => ({
    type: actionTypes.BROKER_CALLMEBACK_SET_PROFILE_PHONE,
    payload: { profilePhone }
});

const reset = () => ({
    type: actionTypes.BROKER_CALLMEBACK_RESET,
    payload: {}
});

const requestCall = (isCallMeBackGenesysEnabled) => (dispatch, getState) => {
    dispatch(validateCallMeBackModal());

    const immInvestmentSpecialists = getState().brokerInvestmentSpecialists;

    if (!immInvestmentSpecialists.get('valid')) {
        return;
    }

    const immProfile = getState().profile;
    const clientName = immProfile.get('nombreCliente', '');

    const callMeBackData = {
        name: clientName.trim(),
        lastName1: immProfile.getIn(['apellidoUno', 'apellido']).trim(),
        lastName2: immProfile.getIn(['apellidoDos', 'apellido']).trim(),
        type: immInvestmentSpecialists.get('type'),
        pageTitle: immInvestmentSpecialists.get('pageTitle'),
        message: immInvestmentSpecialists.get('message'),
        phone: immInvestmentSpecialists.get('telcode') + immInvestmentSpecialists.get('phone'),
        pagePath: immInvestmentSpecialists.get('pagePath'),
        timeForContact: immInvestmentSpecialists.get('callTime')
    };

    requestCallMeBack(callMeBackData, null, isCallMeBackGenesysEnabled)(dispatch, getState)
};

const getSpecialists = (type = 'investmentSpecialists') => (dispatch, getState) => requestSpecialists(dispatch, getState, type);

module.exports = {
    changeCallTime,
    changeComment,
    changeTel,
    changeTelCode,
    getSpecialists,
    requestBrokerCallTimeOptions,
    requestCall,
    requestCallMeBack,
    requestPrivateCallTimeOptions,
    resetCallMeBack,
    toggleModal,
    setProfilePhone,
    reset
};
