const get = require('lodash/object/get');
const aresUserConstants = require('constants/aresUser');

const redirectToAresUserPages = (nextUrl, justReturnURL = false, history = null) => {
    const loansAresUrl = aresUserConstants.LOANS_ARES_URL;
    const aresLandingUrl = aresUserConstants.LOANS_ARES_LANDING_URL;
    const globalPositionUrl = aresUserConstants.GLOBAL_POSITION_URL;
    const isForbiddenURL = aresUserConstants.forbiddenURLs.some(urlParam => nextUrl.endsWith(urlParam));
    let isAllowedURL;
    let url;

    if (nextUrl !== loansAresUrl && nextUrl.startsWith(loansAresUrl)) {
        isAllowedURL = aresUserConstants.loanURLs.some(urlParam => nextUrl.includes(urlParam));
    } else {
        isAllowedURL = aresUserConstants.allowedURLs.some(urlParam => nextUrl.includes(urlParam));
    }

    if (nextUrl === aresLandingUrl) {
        return '';
    } else if (nextUrl.endsWith(globalPositionUrl)) {
        url = loansAresUrl;
    } else if (!isAllowedURL || isForbiddenURL) {
        url = aresLandingUrl;
    }
    if (url) {
        if (justReturnURL) {
            return url;
        } else {
            history.push(url);
        }
    }
}

const aresUserCheck = (response, history) => {
    const isAresUser = get(response, 'userProfile.clientProfile') === 3;
    if (isAresUser) {
        redirectToAresUserPages(location.pathname, false, history)
    }
    return response;
}

module.exports = {
    aresUserCheck,
    redirectToAresUserPages
};
