//@ utilities
const {APIPost} = require('utilities/APIRequestHelper');

//@ constants
const {
    WRAPUP_DISASTER_SEND_REQUEST,
    WRAPUP_DISASTER_SEND_SUCCESS,
    WRAPUP_DISASTER_SEND_FAILURE,
    WRAPUP_DISASTER_SEND_RESET
} = require('constants/contactCenter/actionTypes');

const wrapUpDisasterRequest = () => ({
    type: WRAPUP_DISASTER_SEND_REQUEST,
});

const wrapUpDisasterSuccess = () => ({
    type: WRAPUP_DISASTER_SEND_SUCCESS
});

const wrapUpDisasterFailure = () => ({
    type: WRAPUP_DISASTER_SEND_FAILURE
});

const reset = () => ({
    type: WRAPUP_DISASTER_SEND_RESET
});

const wrapUpDisaster = (query) => (dispatch) => {
    dispatch(wrapUpDisasterRequest());

    const params = {
        query
    };

    return APIPost({
        dispatch,
        endpoint: 'wrapUpDisaster',
        params
    }).then(() => {
        dispatch(wrapUpDisasterSuccess())
    }).catch(()=> {
        dispatch(wrapUpDisasterFailure())
    });
};

module.exports = {
    wrapUpDisaster,
    reset
};
