const actionTypes = require('constants/actionTypes');

function requestGetAssociatedAccountsDetails(accounts) {
    return {
        type: actionTypes.BROKER_CONTRACT_POSITIONS_GET_ACCOUNTS_DETAILS,
        payload: {
            accounts
        }
    }
}

const populateContractDescriptions = () => (dispatch, getState) => {
    const immAccounts = getState().accounts;
    dispatch(requestGetAssociatedAccountsDetails(immAccounts.get('byId')));
}

module.exports = {
    populateContractDescriptions
}

